import { Tooltip } from "@mui/material";
import React from "react";

const CustomToolTip = ({ title, data }) => {
  return (
    <div>
      <Tooltip title={title} className="xfs">
        <div className="overflow-text">{data}</div>
      </Tooltip>
    </div>
  );
};

export default CustomToolTip;
