import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import { Collapse, Pagination } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { testList } from "../../common/Tables/testList";
import { CustomSearch } from "../../common/UI/CustomSearch";
import CustomTable from "../../common/UI/CustomTable";
import FilterBlock from "../../common/UI/FilterBlock";
import { ThreatHuntingContext } from "../../context/ThreatHuntingContext";
import { spinnerStartStop } from "../../redux/action/action";
import "./ThreatHunting.css";
import { toast } from "react-toastify";

const ConfigureVulnerability = () => {
  const { Panel } = Collapse;
  const navigateLink = useNavigate();
  const location = useLocation();
  const {
    scheduleOption,
    configureTestDeviceData,
    setConfigureTestDeviceData,
    addEndPoints,
    newBatchName,
    createBatch,
    editBatch,
    setEditBatch,
    updateBatch,
    getMsfTestList,
    testListGlobal,
    setTestListGlobal,
  } = useContext(ThreatHuntingContext);
  const [deviceList, setDeviceList] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [receivedData, setReceivedData] = useState([]);
  const [sortType, setSortType] = useState("DESC");
  const [selectedColumn, setSelectedColumn] = useState("deviceName");
  const [statusFilter, setStatusFilter] = useState(undefined);
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    if (!configureTestDeviceData.length) {
      navigateLink("/threat_hunting/");
    } else {
      let list = [];
      setReceivedData(configureTestDeviceData);
      configureTestDeviceData.map(async (device) => {
        let updatedEndpoints = [];
        await device?.connectedDevices.map(async (endpoint, key) => {
          if (endpoint.checked) {
            // handle already added test for edit case start
            let editableTests = [];
            editBatch?.batchConnectedDevice?.map((rel, index) => {
              if (rel.connectedDevice.id === endpoint.id) {
                rel.assessmentTests.map((test) => {
                  editableTests.push({
                    testId: test.msfTestId,
                    test_name: test.msfTest.name,
                    payload: test.payload,
                  });
                });
              }
            });
            // handle already added test for edit case end
            endpoint = {
              selectedTests: [...editableTests],
              ...endpoint,
            };
            // console.log(endpoint.selectedTests, 'end');
            updatedEndpoints.push(endpoint);
            list.push({ deviceId: device.id, device: device.name, endpoint });
          }
        });
        device.connectedDevices = updatedEndpoints;
      });

      setConfigureTestDeviceData(configureTestDeviceData);
      if (!list.length) navigateLink("/threat_hunting/");
      setDefaultData(list);
      setDeviceList(list);
    }
  }, [configureTestDeviceData]);

  //getting data from api and store
  const getRecords = async () => {
    dispatch(spinnerStartStop(true));
    await getMsfTestList().then((result) => {
      setTestListGlobal(result?.data?.data);
    });
    dispatch(spinnerStartStop(false));

    return receivedData;
  };

  const { records, param, setParam, dispatch } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => receivedData, //this is getting data from redux  store  and return into records
  });

  const onChangeSortOption = (e) => {
    e.stopPropagation();
    setSelectedColumn(e.target.value);
  };
  const onChangeSortOrder = (e) => {
    e.stopPropagation();
    setSortType(e.target.value);
  };
  const submitSortingAndFiltering = async () => {
    let filteredData = defaultData;
    if (statusFilter) {
      filteredData = defaultData.filter((rec) => {
        if (
          rec.endpoint?.selectedTests.find(
            (test) => test.testId == statusFilter
          )
        ) {
          return true;
        } else {
          return false;
        }
      });
    }

    let sortedData = filteredData.sort(function (a, b) {
      let x =
        selectedColumn === "endpointName"
          ? a.endpoint?.name.toLowerCase()
          : selectedColumn === "deviceName"
          ? a.device.toLowerCase()
          : a.endpoint?.selectedTests.length;
      let y =
        selectedColumn === "endpointName"
          ? b.endpoint?.name.toLowerCase()
          : selectedColumn === "deviceName"
          ? b.device.toLowerCase()
          : b.endpoint?.selectedTests.length;
      if (sortType === "DESC") {
        if (x < y) {
          return 1;
        }
        if (x > y) {
          return -1;
        }
      } else {
        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
      }
      return 0;
    });
    console.log(sortedData, ":::");
    setDeviceList([...sortedData]);
  };
  const SortOptions = () => (
    <div className="row ">
      <div className="col-sm-12">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Select Column</span>
          </legend>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="deviceName"
              control={<Radio checked={selectedColumn === "deviceName"} />}
              label="Device Name"
              onChange={onChangeSortOption}
              className="text-white"
            />
            <FormControlLabel
              value="endpointName"
              onChange={onChangeSortOption}
              control={<Radio checked={selectedColumn === "endpointName"} />}
              label="Endpoint Name"
              className="text-white"
            />
            <FormControlLabel
              value="testCount"
              control={<Radio checked={selectedColumn === "testCount"} />}
              label="Test Count"
              onChange={onChangeSortOption}
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 mr-t20">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Sort Type</span>
          </legend>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="DESC"
              control={<Radio checked={sortType === "DESC"} />}
              label="DESC"
              onChange={onChangeSortOrder}
              className="text-white"
            />
            <FormControlLabel
              value="ASC"
              onChange={onChangeSortOrder}
              control={<Radio checked={sortType === "ASC"} />}
              label="ASC"
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 r-btn-block">
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 mr-t20 font-size14 Inter-Medium"
          onClick={submitSortingAndFiltering}
        >
          Apply
        </button>
      </div>
    </div>
  );
  useEffect(() => {
    if (statusFilter === null) {
      submitSortingAndFiltering();
    }
  }, [statusFilter]);
  const onChangeFilterStatus = (e) => {
    e.stopPropagation();
    setStatusFilter(e.target.value);
  };
  const Filters = () => (
    <div className="row ">
      <div className="col-sm-12">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Select Filter</span>
          </legend>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            {testListGlobal?.records?.map((testRec) => (
              <FormControlLabel
                value={testRec.id}
                control={<Radio checked={statusFilter == testRec.id} />}
                label={testRec.name}
                onChange={onChangeFilterStatus}
                className="text-white"
              />
            ))}
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 r-btn-block mr-t20">
        <button
          type="button"
          className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
          onClick={() => setStatusFilter(null)}
        >
          Clear
        </button>
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 mr-l20 font-size14 Inter-Medium"
          onClick={submitSortingAndFiltering}
        >
          Apply
        </button>
      </div>
    </div>
  );
  useEffect(() => {
    if (defaultData.length > 0) {
      if (param?.search) {
        let filtered_data = defaultData.filter(
          (device) =>
            device?.device
              ?.toUpperCase()
              .includes(param.search.toUpperCase()) ||
            device?.endpoint?.name
              ?.toUpperCase()
              .includes(param.search.toUpperCase())
        );

        setDeviceList(filtered_data);
      } else {
        setDeviceList(defaultData);
      }
    }
  }, [param.search]);
  const returnListOfTest = (tests) => {
    if (tests.length) {
      let arrayList = [];
      tests.map((test) => {
        let name = testListGlobal?.records?.find((tes) => {
          return tes.id == test.testId;
        })?.name;
        arrayList.push(name);
      });
      return arrayList.toString();
    } else {
      return "N/A";
    }
  };
  const AccordianHeader = ({ data }) => (
    <span className="header_configure_accordian">
      <p className="wd-100">{data.device}</p>
      <p className="wd-100">{data.endpoint.name}</p>
      <p className="wd-100 pd-l5">
        {returnListOfTest(data.endpoint.selectedTests)}
      </p>
      <p className="action_right mr-r80">
        <Tooltip title="Delete">
          <IconButton>
            <AiOutlineDelete
              className="icon"
              onClick={(e) => {
                e.stopPropagation();
                // onDelete(data.deviceId, data.endpoint.id);
                onDelete(data.deviceId, data.endpoint.key);
              }}
            />
          </IconButton>
        </Tooltip>
      </p>
    </span>
  );

  const onDelete = (armia_id, endpointId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "red-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10",
        cancelButton: "white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium",
        actions: "threat-hunting-alert-actions",
        title: "threat-hunting-alert-title",
        htmlContainer: "threat-hunting-alert-content",
        closeButton: "threat-hunting-alert-close-btn",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Confirm Delete",
        html: '<p>Are you sure you want to delete this ARMIA device?</p></br><p style="color: red">This action is permanent and cannot be undone!</p>',
        width: "450px",
        height: "221px",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const updatedData = configureTestDeviceData.map((device) => {
            if (device.id === armia_id) {
              device.connectedDevices = device.connectedDevices.map(
                (endpoint) => {
                  device.selectedRowKeys = device.selectedRowKeys.filter(
                    (val) => val != endpoint.key
                  );
                  // if (endpoint.id === endpointId) {
                  if (endpoint.key === endpointId) {
                    return { ...endpoint, checked: false, selectedTests: [] };
                  }
                  return endpoint;
                }
              );
            }
            return device;
          });
          setConfigureTestDeviceData(updatedData);
        }
      });
  };
  const onTestDelete = (testId, deviceId, endpointId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "red-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10",
        cancelButton: "white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium",
        actions: "threat-hunting-alert-actions",
        title: "threat-hunting-alert-title",
        htmlContainer: "threat-hunting-alert-content",
        closeButton: "threat-hunting-alert-close-btn",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "Confirm Delete",
        html: '<p>Are you sure you want to delete this Test?</p></br><p style="color: red">This action is permanent and cannot be undone!</p>',
        width: "450px",
        height: "221px",
        showCancelButton: true,
        showCloseButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const updatedData = configureTestDeviceData.map((device) => {
            if (device.id === deviceId) {
              device.connectedDevices = device.connectedDevices.map(
                (endpoint) => {
                  // if (endpoint.id === endpointId) {
                  if (endpoint.key === endpointId) {
                    endpoint.selectedTests = endpoint.selectedTests.filter(
                      (test) => test.testId !== testId
                    );
                  }
                  return endpoint;
                }
              );
            }
            return device;
          });
          setConfigureTestDeviceData(updatedData);
        }
      });
  };

  const onRunNow = async () => {
    let valid = false;
    let creation = { assessmentTests: [] };
    let endpointsToDelete = [];
    creation.name = newBatchName;
    creation.scheduleId = scheduleOption;
    if (configureTestDeviceData[0].id === "External-Armia") {
      let externalEndpointsWithTests =
        configureTestDeviceData[0].connectedDevices.filter(
          (endpoint) => endpoint.selectedTests.length
        );
      if (externalEndpointsWithTests.length > 0) {
        externalEndpointsWithTests.map((endpoint) => {
          if (endpoint.id) {
            endpointsToDelete.push(endpoint.id);
          }
        });
        dispatch(spinnerStartStop(true));
        await addEndPoints({
          endpoints: configureTestDeviceData[0].connectedDevices,
        }).then((res) => {
          configureTestDeviceData[0].connectedDevices = res.data.data; // replace connected devices with endpointIds
        });
      }
    }
    configureTestDeviceData.map((device) => {
      let endpointsWithTests = device.connectedDevices.filter(
        (endpoint) => endpoint.selectedTests.length
      );
      endpointsWithTests.map((endpoint) => {
        endpoint.selectedTests.map((test) => {
          creation.assessmentTests.push({
            payload: test.payload,
            msfTestId: test.testId,
            connectedDeviceId: endpoint.id,
          });
          valid = true;
        });
      });
    });

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton:
          "blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10",
        title: "font-size18",
        htmlContainer: "threat-hunting-alert-content center-align",
      },
      buttonsStyling: false,
    });
    const swalWithBootstrapButtonsDel = Swal.mixin({
      customClass: {
        confirmButton:
          "red-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10",
        cancelButton: "white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium",
        actions: "threat-hunting-alert-actions",
        title: "threat-hunting-alert-title",
        htmlContainer: "threat-hunting-alert-content",
        closeButton: "threat-hunting-alert-close-btn",
      },
      buttonsStyling: false,
    });
    if (valid) {
      dispatch(spinnerStartStop(true));
      if (editBatch) {
        // updation of batch
        await updateBatch({
          ...creation,
          editBatch,
          batchId: editBatch?.id,
          endpointsToDelete,
        })
          .then((data) => {
            console.log("result of edit batch", data);
          })
          .catch((e) => {
            swalWithBootstrapButtonsDel
              .fire({
                title: "Update Error",
                html: `<p>${
                  e?.response?.data?.error?.message ??
                  "There is something wrong with create process"
                }</p>`,
                width: "450px",
                height: "221px",
                showCloseButton: true,
                confirmButtonText: "Ok",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  navigateLink("/threat_hunting");
                }
              });
          })
          .finally(() => {
            setConfigureTestDeviceData([]);
            setEditBatch(null);
          });
      } else {
        // creation of batch
        await createBatch(creation)
          .then((data) => {
            console.log("result of create batch", data);
            swalWithBootstrapButtons
              .fire({
                title: "Success",
                html: "You have successfully configured vulnerabilities to scan",
                icon: "success",
                confirmButtonColor: "#D8EAFF",
                confirmButtonText: "Okay",
                confirmButtonTextColor: "#ffffff",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  navigateLink("/threat_hunting");
                }
              });
          })
          .catch((e) => {
            swalWithBootstrapButtonsDel
              .fire({
                title: "Create Error",
                html: `<p>${
                  e?.response?.data?.error?.message ??
                  "There is something wrong with create process"
                }</p>`,
                width: "450px",
                height: "221px",
                showCloseButton: true,
                confirmButtonText: "Ok",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  navigateLink("/threat_hunting");
                }
              });
          })
          .finally(() => {
            setConfigureTestDeviceData([]);
          });
      }
      dispatch(spinnerStartStop(false));
    } else {
      toast.error("please add test for endpoints");
    }
  };
  const navigBack = () => {
    location?.state?.editableId
      ? navigateLink(
          "/threat_hunting/edit_batch/" + location?.state?.editableId,
          {
            state: {
              externalEndpoint:
                deviceList[0].deviceId === "External-Armia" ? true : false,
            },
          }
        )
      : navigateLink("/threat_hunting/add_batch", {
          state: {
            externalEndpoint:
              deviceList[0].deviceId === "External-Armia" ? true : false,
          },
        });
  };
  return (
    <div className="add-user-wrap">
      <div className="dark-head-card">
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack className="mr-r10 cursor" onClick={navigBack} />
            Configure Vulnerabilities to Scan
          </h3>
        </div>
        <div className="d-c-body">
          <div className="add-u-form-card">
            <div className="table-head d-flex align-items-center justify-content-between mr-t20 table-head-white">
              <CustomSearch param={param} setParam={setParam} />
              {/* <FilterBlock sorting={<SortOptions />} filters={<Filters />} /> */}
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-floating cus-floating-inputs mr-b30 table-colaps">
                  <div className="mr-l60 mr-t20 mr-b20 addBatch-LabelColor configure_header">
                    <span className="wd-100 white-heading-tread-hunting">
                      Device Name
                    </span>
                    <span className="wd-100 white-heading-tread-hunting">
                      Endpoint Name
                    </span>
                    <span className="wd-100 white-heading-tread-hunting">
                      Tests
                    </span>
                    <span className="action_right white-heading-tread-hunting">
                      Action
                    </span>
                  </div>
                  <Collapse bordered={false}>
                    {deviceList
                      ?.slice(
                        (page.pageNumber - 1) * page.pageSize,
                        page.pageNumber * page.pageSize
                      )
                      ?.map((device, index) => (
                        <>
                          <Panel
                            header={<AccordianHeader data={device} />}
                            key={index}
                          >
                            <div className="col-sm-12 configure-inner-table-border">
                              <div className="configure-inner-table-header">
                                <p className="font-size16 text-white">
                                  Selected Tests
                                </p>
                                <button
                                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                                  onClick={() =>
                                    navigateLink(
                                      "/threat_hunting/add_edit_test",
                                      {
                                        state: {
                                          endpointToAddTest: device.endpoint,
                                          deviceName: device.device,
                                          allDevices: deviceList,
                                        },
                                      }
                                    )
                                  }
                                >
                                  Add New
                                </button>
                              </div>
                              <div className="cus-dark-table">
                                <CustomTable
                                  records={{
                                    records: device.endpoint.selectedTests,
                                  }}
                                  columns={testList({
                                    navigateLink,
                                    onDelete: (id) =>
                                      onTestDelete(
                                        id,
                                        device.deviceId,
                                        // device.endpoint.id,
                                        device.endpoint.key
                                      ),
                                  })}
                                  param={param}
                                  setParam={setParam}
                                />
                              </div>
                            </div>
                          </Panel>
                        </>
                      ))}
                  </Collapse>
                </div>
              </div>{" "}
              <div className="col-sm-12 text-end mb-2">
                <Pagination
                  defaultCurrent={1}
                  current={page?.pageNumber}
                  pageSize={page?.pageSize}
                  hideOnSinglePage={true}
                  total={deviceList?.length}
                  onChange={(page, pageSize) =>
                    setPage({ pageNumber: page, pageSize: pageSize })
                  }
                />
              </div>
              <div className="col-sm-12">
                <div className="f-btm-btn text-end">
                  <button
                    type="button"
                    className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                    onClick={navigBack}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    onClick={onRunNow}
                  >
                    {scheduleOption ? "Set Schedule" : "Run Now"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureVulnerability;
