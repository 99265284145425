import QueryString from "qs";
import React from "react";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { vulnerabilityColumns } from "../../../common/Tables/ReportsColumn";
import CustomTable from "../../../common/UI/CustomTable";
import {
  getAnalyticsDetailsAction,
  getAnalyticsReportCsvAction,
} from "../../../redux/action/action";

const VulnerabilityTable = () => {
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getAnalyticsDetailsAction(QueryString.stringify(payload)));
  };
  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.analyticsDetails, //this is getting data from redux  store  and return into records
  });
  return (
    <div className="dark-table-wrap">
      <div className="table-head d-flex align-items-center justify-content-end mr-t20 table-head-custom">
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
          onClick={() => dispatch(getAnalyticsReportCsvAction())}
        >
          Report
        </button>
      </div>
      <div className="">
        {" "}
        <CustomTable
          records={records}
          columns={vulnerabilityColumns({})} //we  are passing this to column to antd column which in separate file
          param={param}
          className="custom-table-1"
          setParam={setParam}
          scroll={2000}
        />
      </div>
    </div>
  );
};

export default VulnerabilityTable;
