import { yupResolver } from "@hookform/resolvers/yup";
import { FormControlLabel, RadioGroup } from "@mui/material";
import { Checkbox, Collapse, Pagination, Radio } from "antd";
import dayjs from "dayjs";
import QueryString from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomDropdownThreatHunting from "../../common/CustomDropdownThreatHunting";
import CustomInput from "../../common/CustomInput";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { endPointList } from "../../common/Tables/endPointList";
import { CustomSearch } from "../../common/UI/CustomSearch";
import CustomTable from "../../common/UI/CustomTable";
import FilterBlockThreadHunting from "../../common/UI/FilterBlockThreadHunting";
import { ThreatHuntingContext } from "../../context/ThreatHuntingContext";
import { spinnerStartStop } from "../../redux/action/action";
import { addBatchSchema } from "../../utils/validations/batchValidaton";
import { externalEndpointValidation } from "../../utils/validations/externalEndpointValidation";
import "./ThreatHunting.css";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
const AddBatch = () => {
  const { Panel } = Collapse;
  const {
    getDeviceAndEndpointList,
    configureTestDeviceData,
    getScheduleList,
    setConfigureTestDeviceData,
    newBatchName,
    setNewBatchName,
    scheduleOption,
    setScheduleOption,
    getSingleBatch,
    editBatch,
    setEditBatch,
  } = useContext(ThreatHuntingContext);
  const [data, setData] = useState({});
  const location = useLocation();
  const [deviceList, setDeviceList] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [isConfigureDisabled, setIsConfigureDisabled] = useState(true);
  const [scanType, setScanType] = useState("quick");
  const [sortType, setSortType] = useState("DESC");
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [flagExternal, setFlagExternal] = useState(false);
  const [selectAllCheckBox, setSelectAllCheckBox] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);

  const navigateLink = useNavigate();
  const [page, setPage] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const { id } = useParams();
  const osOptions = [
    { value: "linux", label: "Linux" },
    { value: "windows", label: "Windows" },
  ];
  const {
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addBatchSchema),
    mode: "onChange",
    // defaultValues: { batch_name: newBatchName },
  });
  const {
    handleSubmit: handleSubmitHost,
    control: controlHost,
    formState: { errors: errorsHost },
  } = useForm({
    resolver: yupResolver(externalEndpointValidation),
    // mode: 'onChange',
    // defaultValues: { batch_name: newBatchName },
  });

  useEffect(() => {
    let dateTime = new Date().getTime() / 1000;
    setNewBatchName(`Batch_${Math.floor(dateTime)}`);
    if (location && location?.state && location?.state?.externalEndpoint) {
      setFlagExternal(location?.state?.externalEndpoint);
    }
    if (!id) {
      setEditBatch(null);
    }
  }, []);

  useEffect(() => {
    // data setter useEffect
    let filteredData = [];
    if (flagExternal) {
      filteredData = data?.records?.filter((rec) => {
        // rec.connectedDevices =[]
        let temp = [];
        editBatch?.batchConnectedDevice?.map((rel) => {
          temp.push(rel.connectedDevice);
        });
        rec.connectedDevices = temp;
        return rec.id === "External-Armia";
      });
    } else {
      filteredData = data?.records?.filter((rec) => {
        return rec.id !== "External-Armia";
      });
    }
    filteredData?.map((device) => {
      device.selectedRowKeys = [];
      device.checked = false;
      device.connectedDevices.map((endpoint, index) => {
        endpoint.checked = false;
        endpoint.key = index;
        let found = editBatch?.batchConnectedDevice?.find(
          (rel, index) => rel.connectedDevice.id === endpoint.id
        );
        if (found) {
          device.selectedRowKeys.push(index);
          device.checked =
            device.selectedRowKeys.length === device.connectedDevices.length;
          endpoint.checked = true;
        }
      });
    });
    setDeviceList(filteredData);
  }, [data]);

  useEffect(() => {
    // validation useEffect
    setValue("batch_name", newBatchName);
    if (
      errors?.batch_name ||
      newBatchName === "" ||
      !deviceList?.find((item) => item?.selectedRowKeys?.length > 0)
    ) {
      setIsConfigureDisabled(true);
    } else {
      setIsConfigureDisabled(false);
    }
    if (scanType === "schedule") {
      if (errors?.schedule_scan || scheduleOption === null) {
        setIsConfigureDisabled(true);
      } else {
        setIsConfigureDisabled(false);
      }
    }
  }, [editBatch, newBatchName, errors, deviceList, scheduleOption, scanType]);

  const getInitialData = async () => {
    // dispatch(spinnerStartStop(true));
    try {
      await getScheduleList().then(async (result) => {
        let list = [];
        let filteredResults = await result?.data?.data?.records?.filter(
          (val) => {
            if (val.category === "once") {
              return dayjs?.utc() < dayjs?.utc(val.onceData);
            } else {
              //condition that manage validation of scheduler 'The StartDate you specify cannot be earlier than 5 minutes ago'.
              // let startCondition =
              //   5 > dayjs?.utc().diff(dayjs?.utc(val.startDate), 'minute'); Managed by backend currently
              dayjs?.utc(val.startDate);
              console.log(val.endDate);
              return dayjs?.utc() < dayjs?.utc(val.endDate);
            }
          }
        );
        await filteredResults?.map((val, index) => {
          list.push({
            value: val.id,
            label: val.name,
            subString:
              val.category !== "once"
                ? val.startDate &&
                  val.endDate &&
                  `(${dayjs
                    ?.utc(val.startDate)
                    .format("YYYY-MM-DD HH:mm")})-(${dayjs
                    ?.utc(val.endDate)
                    .format("YYYY-MM-DD HH:mm")})`
                : `Once on ${dayjs
                    ?.utc(val.oncedata)
                    .format("YYYY-MM-DD HH:mm")}`,
          });
        });
        console.log("list", list);
        setSchedules(list);
      });
    } catch (error) {
      console.log("error", error);
    }
    if (id) {
      await getSingleBatch(id).then(async (result) => {
        let editableBatch = result?.data?.data;
        await setEditBatch(editableBatch);
        setFlagExternal(
          editableBatch?.batchConnectedDevice[0]?.connectedDevice?.device
            ?.id === "External-Armia"
        );
        setScanType(editableBatch?.scheduleId ? "schedule" : "quick");
        await setScheduleOption(editableBatch?.scheduleId);
        setValue("schedule_scan", editableBatch?.scheduleId);
        await setNewBatchName(editableBatch?.name ?? "ssssss");
        setValue("batch_name", newBatchName);
      });
    }
    // dispatch(spinnerStartStop(false));
  };
  //getting data from api and store
  const getRecords = async () => {
    // data getter useEffect
    dispatch(spinnerStartStop(true));
    await getInitialData();
    const payload = {
      search: param?.search?.length ? param?.search : undefined,
      sortOrder: sortType,
      sortColumn: selectedColumn,
      statusFilter: statusFilter === "clear" ? null : statusFilter,
    };
    await getDeviceAndEndpointList(QueryString.stringify(payload)).then(
      (result) => {
        setData(result?.data?.data);
      }
    );

    dispatch(spinnerStartStop(false));
  };
  const { records, param, setParam, dispatch } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => data, //this is getting data from redux  store  and return into records
  });

  const AccordianHeader = ({ children, device }) => (
    <span className="headerAddBatch ">
      <Checkbox
        checked={device?.checked}
        onClick={async (e) => {
          e.stopPropagation();
          device.checked = e.target.checked;
          await device.connectedDevices.map((endpoint) => {
            if (e.target.checked) {
              endpoint.checked = true;
              device.selectedRowKeys = [
                endpoint.key,
                ...device.selectedRowKeys,
              ];
            } else {
              endpoint.checked = false;
              device.selectedRowKeys = [];
              setSelectAllCheckBox(false);
            }
          });
          setData(data);
          setDeviceList([...deviceList]);
        }}
      />
      <p>{children}</p>
    </span>
  );
  const AccordianHeaderSelectAll = ({ children }) => (
    <span className="headerAddBatch">
      <Checkbox
        checked={selectAllCheckBox}
        onClick={async (e) => {
          setSelectAllCheckBox(e.target.checked);
          e.stopPropagation();
          deviceList?.map(async (device, index) => {
            device.checked = e.target.checked;
            await device.connectedDevices.map((endpoint) => {
              endpoint.checked = e.target.checked;
              device.selectedRowKeys = [
                endpoint.key,
                ...device.selectedRowKeys,
              ];
            });
            setData(data);
            setDeviceList([...deviceList]);
          });
        }}
      />
      <p className="white-heading-tread-hunting">{children}</p>
    </span>
  );
  const onselectionchange = async (
    selectedRowKeys,
    selectedRows,
    info,
    device
  ) => {
    device.selectedRowKeys = selectedRowKeys;
    device.checked = selectedRowKeys.length === device.connectedDevices.length;
    await device.connectedDevices.map((endpoint) => {
      if (selectedRowKeys.findIndex((e) => e === endpoint.key) > -1) {
        endpoint.checked = true;
      } else {
        endpoint.checked = false;
      }
    });
    setData(data);
    setDeviceList([...deviceList]);
  };
  const handleOptionChange = (event) => {
    if (event.target.value === "schedule") {
      setScanType(event.target.value);
    } else {
      setScanType(event.target.value);
      setValue("schedule_scan", null);
      setScheduleOption(null);
    }
  };
  const configureTests = () => {
    let selectedDevices = [];
    deviceList?.map((item, key) => {
      if (item?.selectedRowKeys?.length > 0) {
        selectedDevices.push(item);
      }
    });
    setConfigureTestDeviceData(selectedDevices);
    if (id) {
      navigateLink("/threat_hunting/configure_vulnerability", {
        state: {
          editableId: id,
        },
      });
    } else {
      navigateLink("/threat_hunting/configure_vulnerability");
    }
  };

  // functions for ExternalEndpoints

  const onSubmitHost = async (host) => {
    let hostToEndpoint = {
      id: null,
      name: host.endpoint_name,
      mac_address: null,
      device_type: "external",
      isConnected: true,
      rx_bytes: null,
      tx_bytes: null,
      ip_address: host.endpoint_public_ip,
      ip6_address: null,
      ip_addressess: null,
      is_blocked: false,
      hotspot_station_blocked: false,
      openvas_selected: false,
      key: deviceList[0].connectedDevices.length,
      device: deviceList[0].id,
    };
    // add endpoint in only armia that is filtered out for external
    deviceList[0].connectedDevices = [
      ...deviceList[0].connectedDevices,
      hostToEndpoint,
    ];
    setData(data);
    setDeviceList([...deviceList]);
  };
  // functions for ExternalEndpoints end

  //filtering and sorting
  const onChangeSortOption = (e) => {
    e.stopPropagation();
    setSelectedColumn(e.target.value);
  };
  const onChangeSortOrder = (e) => {
    e.stopPropagation();
    setSortType(e.target.value);
  };
  const SortOptions = () => (
    <div className="row ">
      <div className="col-sm-12">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Select Column</span>
          </legend>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="name"
              onChange={onChangeSortOption}
              control={<Radio checked={selectedColumn === "name"} />}
              label="Name"
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 mr-t20">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Sort Type</span>
          </legend>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="DESC"
              control={<Radio checked={sortType === "DESC"} />}
              label="DESC"
              onChange={onChangeSortOrder}
              className="text-white"
            />
            <FormControlLabel
              value="ASC"
              onChange={onChangeSortOrder}
              control={<Radio checked={sortType === "ASC"} />}
              label="ASC"
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 r-btn-block">
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 mr-t20 font-size14 Inter-Medium"
          onClick={submitSortingAndFiltering}
        >
          Apply
        </button>
      </div>
    </div>
  );
  const submitSortingAndFiltering = async () => {
    setFilterOpen(false);
    await getRecords();
  };
  const onChangeFilterStatus = (e) => {
    e.stopPropagation();
    setStatusFilter(e.target.value);
  };
  useEffect(() => {
    if (statusFilter === "clear") {
      getRecords();
    }
  }, [statusFilter]);

  const Filters = () => (
    <div className="row ">
      <div className="col-sm-12">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Select Filter</span>
          </legend>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value={"withEndpoints"}
              control={<Radio checked={statusFilter === "withEndpoints"} />}
              label="With Endpoints"
              onChange={onChangeFilterStatus}
              className="text-white"
            />
            <FormControlLabel
              value={"withoutEndpoints"}
              control={<Radio checked={statusFilter === "withoutEndpoints"} />}
              label="Without Endpoints"
              onChange={onChangeFilterStatus}
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 r-btn-block mr-t20">
        <button
          type="button"
          className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
          onClick={() => setStatusFilter("clear")}
        >
          Clear
        </button>
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 mr-l20 font-size14 Inter-Medium"
          onClick={submitSortingAndFiltering}
        >
          Apply
        </button>
      </div>
    </div>
  );
  //end
  return (
    <div className="add-user-wrap">
      <div className="dark-head-card">
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigateLink("/threat_hunting")}
            />
            {id ? "Edit Batch" : "Add New Batch"}
          </h3>
        </div>
        <div className="d-c-head align-items-center gap-20">
          <div className="row">
            <div className="col-sm-4">
              <CustomInput
                label={"Batch Name"}
                // value={newBatchName}
                varient="outline"
                onKeyUp={(e) => setNewBatchName(e)}
                control={control}
                error={errors?.batch_name}
                name="batch_name"
                defaultValue={""}
                fullWidth
                disabled={true} // make it disabled because duplicate name of batch would be create problems on aws scheduler
              />
            </div>
            <div className="col-sm-4">
              <fieldset className="threat-hunting-fieldset">
                <legend className="threat-hunting-legend">
                  <span>Scan Type</span>
                </legend>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  className="mx-2"
                >
                  <FormControlLabel
                    value="quick"
                    control={<Radio checked={scanType === "quick"} />}
                    label="Quick Scan"
                    onChange={handleOptionChange}
                    className="text-white"
                  />
                  <FormControlLabel
                    value="schedule"
                    onChange={handleOptionChange}
                    control={<Radio checked={scanType === "schedule"} />}
                    label="Schedule scan"
                    className="text-white"
                  />
                </RadioGroup>
              </fieldset>
            </div>
            {scanType === "schedule" && (
              <div className="col-sm-4 ">
                <CustomDropdownThreatHunting
                  label={"Schedule Scan"}
                  control={control}
                  varient="outline"
                  defaultValue=""
                  name="schedule_scan"
                  onChange={(e) => setScheduleOption(e.target.value)}
                  options={[
                    {
                      value: null,
                      label: (
                        <p
                          className="link-color"
                          onClick={() =>
                            navigateLink("/threat_hunting/add_schedule", {
                              state: {
                                editableId: id,
                                externalEndpoint:
                                  location?.state?.externalEndpoint,
                              },
                            })
                          }
                        >
                          Add New Schedule +
                        </p>
                      ),
                    },
                  ].concat(schedules)}
                  error={errors?.schedule_scan}
                  fullWidth
                />
              </div>
            )}
          </div>
        </div>

        {flagExternal && (
          <div className="d-c-head  align-items-center gap-20">
            <form onSubmit={handleSubmitHost(onSubmitHost)} autoComplete="off">
              <div className="row">
                <div className="col-sm-4">
                  <CustomInput
                    label={"Endpoint Name"}
                    varient="outline"
                    control={controlHost}
                    error={errorsHost?.endpoint_name}
                    name="endpoint_name"
                    defaultValue={""}
                    fullWidth
                  />
                </div>
                <div className="col-sm-4">
                  <CustomDropdownThreatHunting
                    control={controlHost}
                    varient="outline"
                    name="endpoint_os"
                    label={"Endpoint OS"}
                    defaultValue="linux"
                    options={osOptions}
                    error={errorsHost?.endpoint_os}
                    fullWidth
                  />
                </div>
                <div className="col-sm-4">
                  <CustomInput
                    label={"Endpoint Public Ip Address"}
                    varient="outline"
                    control={controlHost}
                    error={errorsHost?.endpoint_public_ip}
                    name="endpoint_public_ip"
                    defaultValue={""}
                    fullWidth
                  />
                </div>
              </div>
              <div className="row pd-t20">
                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Add Host
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="d-c-body">
          {flagExternal === false ? (
            <div className="add-u-form-card">
              <div className="table-head d-flex align-items-center justify-content-between mr-t20 table-head-white">
                <CustomSearch param={param} setParam={setParam} />
                <FilterBlockThreadHunting
                  sorting={<SortOptions />}
                  filters={<Filters />}
                  setFilterOpen={setFilterOpen}
                  filterOpen={filterOpen}
                />
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-floating cus-floating-inputs mr-b30 table-colaps ">
                    <div className="mr-l40 mr-t20 mr-b20 addBatch-LabelColor">
                      <AccordianHeaderSelectAll>
                        Device Name
                      </AccordianHeaderSelectAll>
                    </div>
                    <Collapse bordered={false}>
                      {deviceList
                        ?.slice(
                          (page.pageNumber - 1) * page.pageSize,
                          page.pageNumber * page.pageSize
                        )
                        ?.map((device, index) => (
                          <Panel
                            header={
                              <AccordianHeader device={device}>
                                {device?.name}
                              </AccordianHeader>
                            }
                            key={index}
                          >
                            <div className="cus-dark-table">
                              <CustomTable
                                records={{ records: device.connectedDevices }}
                                columns={endPointList({ navigateLink })}
                                param={param}
                                setParam={setParam}
                                rowSelection={{
                                  onChange: (
                                    selectedRowKeys,
                                    selectedRows,
                                    info
                                  ) =>
                                    onselectionchange(
                                      selectedRowKeys,
                                      selectedRows,
                                      info,
                                      device
                                    ),
                                  selectedRowKeys: device.selectedRowKeys,
                                }}
                                selectionType={{ type: "checkbox" }}
                                className="custom-table-1"
                              />
                            </div>
                          </Panel>
                        ))}
                    </Collapse>
                  </div>
                </div>
                <div className="col-sm-12 text-end mb-2">
                  <Pagination
                    defaultCurrent={1}
                    current={page?.pageNumber}
                    pageSize={page?.pageSize}
                    // hideOnSinglePage={true}
                    total={deviceList?.length}
                    onChange={(page, pageSize) =>
                      setPage({ pageNumber: page, pageSize: pageSize })
                    }
                  />
                </div>
                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigateLink("/threat_hunting")}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={isConfigureDisabled}
                      type="button"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                      onClick={configureTests}
                    >
                      Configure
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="add-u-form-card">
                <div className="table-head d-flex align-items-center justify-content-between mr-t20">
                  <CustomSearch param={param} setParam={setParam} />
                  {/* <FilterBlockThreadHunting /> */}
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-floating cus-floating-inputs mr-b30">
                      {deviceList?.map((device, index) => (
                        <div className="cus-dark-table">
                          <CustomTable
                            records={{ records: device.connectedDevices }}
                            columns={endPointList({ navigateLink })}
                            param={param}
                            setParam={setParam}
                            rowSelection={{
                              onChange: (selectedRowKeys, selectedRows, info) =>
                                onselectionchange(
                                  selectedRowKeys,
                                  selectedRows,
                                  info,
                                  device
                                ),
                              selectedRowKeys: device.selectedRowKeys,
                            }}
                            selectionType={{ type: "checkbox" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="f-btm-btn text-end">
                      <button
                        disabled={isConfigureDisabled}
                        type="button"
                        className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                        onClick={configureTests}
                      >
                        Configure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddBatch;
