import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import { IoCloseCircle } from "react-icons/io5";
import { MdEditNote } from "react-icons/md";

const EditFormIcon = ({ param, setParam }) => {
  const handleEdit = (action) => {
    setParam({ ...param, disable: action });
  };
  return (
    <>
      {!param?.disable ? (
        <Tooltip title={"Edit "}>
          <IconButton onClick={() => handleEdit(true)}>
            <MdEditNote color="#d8eaff" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={"Cancel Editing "}>
          <IconButton onClick={() => handleEdit(false)}>
            <IoCloseCircle color="#d8eaff" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default EditFormIcon;
