import { ThemeProvider } from "@mui/material/styles";
import { ConfigProvider, Spin } from "antd";
import "antd/dist/reset.css";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/index.scss";
import "./assets/scss/styles.scss";
import Index from "./components/Index";
import ErrorBoundary from "./utils/ErrorBoundary";
import {
  blackThemeAntd,
  blueThemeAntd,
  whiteThemeAntd,
} from "./utils/themes/antdTheme";
import { blueTheme, darkTheme, whiteTheme } from "./utils/themes/theme";
import("./assets/css/common.css");

function App() {
  const spinner = useSelector((state) => state.cylenium.spinner);
  const themes = useSelector((state) => state.cylenium.themes);

  useEffect(() => {
    if (themes == 1) {
      document.body.style.backgroundColor = "#212528";
    } else if (themes == 2) {
      document.body.style.backgroundColor = "#151a2c";
    } else {
      document.body.style.backgroundColor = "#f1f1f1";
    }
  }, [themes]);
  return (
    <div
      className={
        themes == 1 ? "dark-theme" : themes == 2 ? "blue-theme" : "white-theme"
      }
    >
      <ToastContainer
        position="bottom-right"
        autoClose={1000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <ConfigProvider
        theme={
          themes == 3
            ? whiteThemeAntd
            : themes == 2
            ? blueThemeAntd
            : blackThemeAntd
        }
      >
        <ThemeProvider
          theme={themes == 3 ? whiteTheme : themes == 2 ? blueTheme : darkTheme}
        >
          <Spin size="large" spinning={spinner?.loading}>
            <ErrorBoundary>
              {/* <VoiceAssistantProvider> */}
              <Index />
              {/* </VoiceAssistantProvider> */},
            </ErrorBoundary>
          </Spin>
        </ThemeProvider>
      </ConfigProvider>
    </div>
  );
}

export default App;
