import { Button, Modal } from "antd";
import React from "react";

function DeleteModal({ deleteDetails, setDeleteDetails, onDelete }) {
  return (
    <div>
      {" "}
      <Modal
        title="Confirm Delete"
        open={deleteDetails?.open}
        onCancel={() => setDeleteDetails({ ...deleteDetails, open: false })}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={() => setDeleteDetails({ ...deleteDetails, open: false })}
          >
            Cancel
          </Button>,
          <Button
            className="custom-confirm-button"
            onClick={() => {
              onDelete();
              setDeleteDetails({ ...deleteDetails, open: false });
            }}
          >
            Delete
          </Button>,
        ]}
      >
        <p className="delete-message  my-3 mb-1">{deleteDetails?.message}</p>
        <p className="delete-message text-red">
          This action is permanent and cannot be undone!
        </p>
      </Modal>
    </div>
  );
}

export default DeleteModal;
