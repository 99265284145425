import QueryString from "qs";
import React from "react";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { threatTypeColumns } from "../../../common/Tables/ReportsColumn";
import { CustomSearch } from "../../../common/UI/CustomSearch";
import CustomTable from "../../../common/UI/CustomTable";
import {
  getAlertListReportAction,
  getAlertReportCsvAction,
} from "../../../redux/action/action";

const ThreatTypeTable = () => {
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getAlertListReportAction(QueryString.stringify(payload)));
  };
  const downloadCsv = () => {
    dispatch(getAlertReportCsvAction());
  };
  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.alertList, //this is getting data from redux  store  and return into records
  });
  return (
    <div className="dark-table-wrap">
      <div className="table-head d-flex align-items-center justify-content-between mr-t20 table-head-custom">
        <div className="t-filter-search">
          <CustomSearch param={param} setParam={setParam} />
        </div>
        <div>
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
            onClick={downloadCsv}
          >
            Report
          </button>
        </div>
      </div>
      <div className="">
        {" "}
        <CustomTable
          records={records}
          columns={threatTypeColumns({})} //we  are passing this to column to antd column which in separate file
          param={param}
          className="custom-table-1"
          setParam={setParam}
        />
      </div>
    </div>
  );
};

export default ThreatTypeTable;
