import { Autocomplete, Checkbox, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomMultiSelectDevice({
  options,
  label,
  name,
  multiple,
  watch,
  disabled,
  setValue,
  ...rest
}) {
  const [selectedValue, setSelectedValue] = useState([]);
  
  // Add a "Select All" option
  const selectAllOption = { device_id: 'selectAll', device_name: 'Select All' };
  const enhancedOptions = [selectAllOption, ...options];

  const onChange = (newValue) => {
    if (multiple) {
      // Check if "Select All" is selected
      const isSelectAll = newValue.some((item) => item.device_id === 'selectAll');

      if (isSelectAll) {
        // Select or deselect all options
        const allSelected = newValue.length - 1 === options.length;
        const updatedValue = allSelected ? [] : options;
        setSelectedValue(updatedValue);
        setValue(name, allSelected ? [] : options.map(({ device_id }) => device_id));
      } else {
        setSelectedValue(newValue);
        setValue(name, newValue.map(({ device_id }) => device_id));
      }
    } else {
      setSelectedValue(newValue);
      setValue(name, newValue.device_id);
    }
  };

  useEffect(() => {
    const values = watch(name);
    if (values) {
      if (multiple) {
        const tempArray = options?.filter((obj) =>
          values?.includes(obj.device_id)
        );
        setSelectedValue(tempArray);
      }
    }
  }, [watch(name), options]);

  return (
    <Autocomplete
      sx={{ m: 0, width: "100%" }}
      limitTags={1}
      multiple={multiple ? multiple : false}
      disabled={disabled ? disabled : false}
      options={enhancedOptions}
      defaultValue={selectedValue[0]}
      value={multiple ? selectedValue : {}}
      getOptionLabel={(option) => option.device_name}
      onChange={(event, newValue) => onChange(newValue)}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label ? label : ""} />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.device_id}
          value={option.device_name}
          sx={{ justifyContent: "space-between" }}
        >
          <Checkbox checked={selected || (option.device_id === 'selectAll' && selectedValue.length === options.length)} />
          {option.device_name}
        </MenuItem>
      )}
    />
  );
}
