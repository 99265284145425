import { FormControlLabel, RadioGroup } from "@mui/material";
import { Radio } from "antd";
import QueryString from "qs";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { batchList } from "../../common/Tables/batchList";
import { CustomSearch } from "../../common/UI/CustomSearch";
import CustomTable from "../../common/UI/CustomTable";
import { ThreatHuntingContext } from "../../context/ThreatHuntingContext";
import { spinnerStartStop } from "../../redux/action/action";
import "./ThreatHunting.css";
const ThreatHunting = () => {
  const { getBatchList, deleteBatch } = useContext(ThreatHuntingContext);
  const [data, setData] = useState({});
  const [sortType, setSortType] = useState("DESC");
  const [selectedColumn, setSelectedColumn] = useState("id");
  const [statusFilter, setStatusFilter] = useState(null);

  const authData = useSelector((state) => state.cylenium.auth);
  //getting data from api and store

  const getRecords = async () => {
    if (!authData?.x_auth_token) {
      navigate("/");
    } else {
      dispatch(spinnerStartStop(true));
      const payload = {
        page: param?.page,
        page_size: param?.page_size,
        search: param?.search?.length ? param?.search : undefined,
        // sortOrder: sortType,
        // sortColumn: selectedColumn,
        sortColumn: param.sorter?.field,
        sortOrder: param.sorter?.order === "ascend" ? "ASC" : "DESC",
        // statusFilter: statusFilter === "clear" ? null : statusFilter,
        statusFilter: param?.filters?.status?.length
          ? param?.filters?.status[0]
          : undefined,
      };
      await getBatchList(QueryString.stringify(payload))
        .then((result) => {
          setData(result?.data?.data);
        })
        .catch((e) => {
          // console.log(e);
          // if (e?.response?.data?.message === 'User Session Expired') {
          //   console.log('ssssss');
          //   navigate('/');
          // }
        });
      dispatch(spinnerStartStop(false));
    }
  };

  //delete the record
  const onDelete = async (batch_id) => {
    dispatch(spinnerStartStop(true));
    await deleteBatch(batch_id).then(async () => {
      await getRecords();
    });
    dispatch(spinnerStartStop(false));
  };
  const onChangeFilterStatus = (e) => {
    e.stopPropagation();
    setStatusFilter(e.target.value);
  };
  const Filters = () => (
    <div className="row ">
      <div className="col-sm-12">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Select Status</span>
          </legend>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="completed"
              control={<Radio checked={statusFilter === "completed"} />}
              label="Completed"
              onChange={onChangeFilterStatus}
              className="text-white"
            />
            <FormControlLabel
              value="failed"
              onChange={onChangeFilterStatus}
              control={<Radio checked={statusFilter === "failed"} />}
              label="Failed"
              className="text-white"
            />
            <FormControlLabel
              value="inProgress"
              control={<Radio checked={statusFilter === "inProgress"} />}
              label="In Progress"
              onChange={onChangeFilterStatus}
              className="text-white"
            />
            <FormControlLabel
              value="pending"
              control={<Radio checked={statusFilter === "pending"} />}
              label="Pending/Schedule"
              onChange={onChangeFilterStatus}
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>

      <div className="col-sm-12 r-btn-block mr-t20">
        <button
          type="button"
          className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
          onClick={() => setStatusFilter("clear")}
        >
          Clear
        </button>
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 mr-l20 font-size14 Inter-Medium"
          onClick={submitSortingAndFiltering}
        >
          Apply
        </button>
      </div>
    </div>
  );
  const onChangeSortOption = (e) => {
    e.stopPropagation();
    setSelectedColumn(e.target.value);
  };
  const onChangeSortOrder = (e) => {
    e.stopPropagation();
    setSortType(e.target.value);
  };
  const SortOptions = () => (
    <div className="row ">
      <div className="col-sm-12">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Select Column</span>
          </legend>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="id"
              control={<Radio checked={selectedColumn === "id"} />}
              label="ID"
              onChange={onChangeSortOption}
              className="text-white"
            />
            <FormControlLabel
              value="name"
              onChange={onChangeSortOption}
              control={<Radio checked={selectedColumn === "name"} />}
              label="Name"
              className="text-white"
            />
            <FormControlLabel
              value="status"
              control={<Radio checked={selectedColumn === "status"} />}
              label="Status"
              onChange={onChangeSortOption}
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 mr-t20">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Sort Type</span>
          </legend>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="DESC"
              control={<Radio checked={sortType === "DESC"} />}
              label="DESC"
              onChange={onChangeSortOrder}
              className="text-white"
            />
            <FormControlLabel
              value="ASC"
              onChange={onChangeSortOrder}
              control={<Radio checked={sortType === "ASC"} />}
              label="ASC"
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 r-btn-block">
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 mr-t20 font-size14 Inter-Medium"
          onClick={submitSortingAndFiltering}
        >
          Apply
        </button>
      </div>
    </div>
  );
  // Custom hook which handle all dependency on Table data
  const { records, navigate, param, setParam, dispatch } = ApiHelper({
    getRecords, //this function is calling the api
    getData: () => data, //this is getting data from redux  store  and return into records
  });
  const submitSortingAndFiltering = async () => {
    await getRecords();
  };
  useEffect(() => {
    if (statusFilter === "clear") {
      getRecords();
    }
  }, [statusFilter]);
  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Threat Hunting</h3>
        </div>
        <div className="r-btn-block">
          <button
            type="button"
            className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-r10"
            onClick={() =>
              navigate(`/threat_hunting/add_batch`, {
                state: {
                  externalEndpoint: true,
                },
              })
            }
          >
            Add External Endpoint
          </button>
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate("/threat_hunting/add_batch")}
          >
            Add New Batch
          </button>
        </div>
      </div>

      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
          {/* <FilterBlockThreadHunting
            filters={<Filters />}
            sorting={<SortOptions />}
          /> */}
        </div>
        <div className="mt-3">
          <div className="cus-dark-table12">
            <CustomTable
              className="custom-table-1"
              records={records}
              columns={batchList({ navigate, onDelete })} //we  are passing this to column to antd column which in separate file
              param={param}
              setParam={setParam}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreatHunting;
