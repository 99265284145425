import { FloatButton, notification } from "antd";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { MdKeyboardVoice, MdOutlineRecordVoiceOver } from "react-icons/md";
import { TbHandStop } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import Siriwave from "react-siriwave";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import PropagateLoader from "react-spinners/PropagateLoader";
import { useVoiceAssistant } from "../../../common/Hooks/VoiceAssistantContext";
import { generateVoiceAssistant } from "../../../services/api";
import { FaMicrophone } from "react-icons/fa6";
import { toast } from "react-toastify";
import "./VoiceAssistant.css";

function VoiceAssistant() {
  const [api, contextHolder] = notification.useNotification();
  const [spinning, setSpinning] = useState(false);

  const {
    isOpen,
    setIsOpen,
    audioUrl,
    setAudioUrl,
    description,
    setDescription,
    lastTranscript,
    setLastTranscript,
    audioRef,
    setAutoClosed,
    text,
    setText,
    listen,
  } = useVoiceAssistant();

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
    isMicrophoneAvailable,
  } = useSpeechRecognition();
  const navigate = useNavigate();

  const handleAiAssistant = async () => {
    setSpinning(true);
    try {
      const res = await generateVoiceAssistant(
        QueryString.stringify({ query: lastTranscript })
      );
      if (res?.data?.action === "navigation_command") {
        const categoryToPath = {
          "Reports & Alert": "/reports/alerts",
          dashboard: "/dashboard",
          "Device & Domain": "/device",
          "Risk Assessment": "/reports/risk",
          "trust group": "/trusted_group",
          Analytics: "/analytics",
          "threat hunting": "/threat_hunting",
          "device scanning": "/device_scanning",
          "chat or AI Assistance": "/chat",
          notification: "/notification",
          "device settings": "/device_settings",
          "threat policies": "/threat_policies",
          "OT Management and configuration": "/ot",
          "Digital Twinning": "/digital_twin",
          organization: "/organization",
        };

        const path = categoryToPath[res?.data?.category];
        if (path) {
          audioRef.current.src = res.data.speech;
          setDescription(res.data.response);
          navigate(path);
          resetTranscript();
          setAutoClosed(true);
        }
      } else if (res?.data?.action === "query") {
        setAudioUrl();
        setDescription(res.data.response);
        setLastTranscript(res.data.query);
        resetTranscript();
      }
    } catch (error) {
      console.error("Error handling AI assistant:", error);
      toast.error(
        "An error occurred while processing the AI assistant request."
      );
    } finally {
      setSpinning(false);
    }
  };

  useEffect(() => {
    if (listening) {
      const checkForSilence = setInterval(() => {
        if (transcript === lastTranscript && transcript !== "" && text) {
          SpeechRecognition.stopListening();
          setText(false);
          handleAiAssistant();
        } else {
          setLastTranscript(transcript);
        }
      }, 1000);

      return () => clearInterval(checkForSilence);
    }
  }, [listening, transcript, lastTranscript, text]);

  useEffect(() => {
    if (listening && transcript) {
      const triggerWords = ["sailo", "xylo", "assistant"];
      if (
        triggerWords.some((word) => transcript.toLowerCase().includes(word))
      ) {
        setText(true);
        resetTranscript();
        setIsOpen(true);
      }
    }
  }, [listening, transcript]);

  const requestMicrophonePermission = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsOpen(true);
    } catch (err) {
      openNotificationWithIcon("error");
    }
  };

  const handleMicroPhone = async (open) => {
    if (open) {
      if (isMicrophoneAvailable) {
        setIsOpen(true);
      } else {
        await requestMicrophonePermission();
      }
    } else {
      setIsOpen(false);
    }
  };

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "Error",
      description: "Error accessing the microphone.",
    });
  };

  useEffect(() => {
    listen();
  }, []);
  
  if (!browserSupportsSpeechRecognition) {
    return <span>Your browser doesn't support speech recognition.</span>;
  }
  return (
    <div>
      {contextHolder}
      {isOpen && (
        <div className="overlay">
          <section className="main">
            <div className="image-container">
              <div className="image"></div>
              <h1 className="heading-cylo">CYLO</h1>
              {text ? (
                <Siriwave theme="ios9" />
              ) : (
                <p className="subline-text">
                  I'm the Virtual Assistant CYLO, How may I help you?
                  {true && (
                    <audio
                      controls
                      // src={audioUrl}
                      ref={audioRef}
                      // style={{ display: "none" }}
                    />
                  )}
                </p>
              )}
            </div>
            <div className="input">
              <button className="talk">
                <i className="fas fa-microphone-alt"></i>
              </button>
              {text && <h1 className="content">{transcript}</h1>}
              <h1 className="content1">{description}</h1>
            </div>
            {spinning ? (
              <PropagateLoader color="white" className="mt-3" />
            ) : (
              <button className="btn-gradient-border mt-3">
                {text ? (
                  <TbHandStop
                    fontSize={50}
                    onClick={() => {
                      resetTranscript();
                      setText(false);
                    }}
                    className="p-1"
                  />
                ) : (
                  <MdKeyboardVoice
                    fontSize={50}
                    className="p-1"
                    onClick={() => {
                      listen();
                      setText(true);
                    }}
                  />
                )}
              </button>
            )}
          </section>
        </div>
      )}
      <div
        className={`floating-button ${isMicrophoneAvailable ? "wave1" : ""}`}
      >
        <div
          className={`microphone-icon ${
            isMicrophoneAvailable ? "listening" : ""
          }`}
          onClick={() => handleMicroPhone(!isOpen)}
        >
          <FaMicrophone />
        </div>
      </div>
    </div>
  );
}

export default VoiceAssistant;
