import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { IoArrowBackOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import {
  addNodeTrustGroupAction,
  getTrustGroupUnlistDeviceListAction,
} from "../../../redux/action/action";
import { addNode } from "../../../utils/validations/trustGroupSchema";
import AddNodeTable from "./AddNodeTable";
import { toast } from "react-toastify";
import { Tag } from "antd";
import QueryString from "qs";
const AddTrustedGroupNode = () => {
  const [list, setList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(addNode),
    defaultValues: { devices: [], modules: [] },
  });
  const { id } = useParams();

  const onSubmit = (data) => {
    const deviceIds = [];
    for (let device of list) {
      // if (device.isChecked) {
      for (let enpoint of device?.connectedDevices) {
        if (enpoint.isChecked) {
          deviceIds.push(enpoint.id);
        }
      }
      // }
    }
    if (!deviceIds?.length) toast.error("Please select device");
    else
      dispatch(
        addNodeTrustGroupAction({
          id: id,
          devices: deviceIds,
        })
      );
  };

  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(
      getTrustGroupUnlistDeviceListAction(id, QueryString.stringify(payload))
    );
  };

  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.unlistedDevices, //this is getting data from redux  store  and return into records
  });

  useEffect(() => {
    if (records?.records?.length) {
      const tempSelectedKeys = [];
      for (let device of records?.records) {
        for (let connectedDevice of device?.connectedDevices) {
          if (connectedDevice.isChecked) {
            tempSelectedKeys.push(device.id);
          }
        }
      }
      setList(
        records?.records
        // records?.map((element) => ({
        //   ...element,
        //   connectedDevices: element.connectedDevices.map((connectedDevice) => ({
        //     ...connectedDevice,
        //     isChecked: connectedDevice.isSelected,
        //   })),
        // }))
      );
      setSelectedRowKeys(tempSelectedKeys);
    }
  }, [records]);
  const colums = [
    {
      title: "Device Name",
      dataIndex: "device_name",
    },
    {
      title: "Classification",
      dataIndex: "classification",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data, record) => {
        return (
          <div>
            {data == "Active" ? (
              <Tag color="green">{data}</Tag>
            ) : (
              <Tag color="red">{data}</Tag>
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Last Scannned",
    //   dataIndex: "last_modified",
    //   render: (data, record) => {
    //     return (
    //       <div>
    //         {record?.last_modified !== "-" ? (
    //           <span className="last_modified">{record?.last_modified} </span>
    //         ) : (
    //           "-"
    //         )}{" "}
    //       </div>
    //     );
    //   },
    // },
  ];
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <div className="page-title d-flex">
              <IoArrowBackOutline
                color="white"
                style={{ fontSize: 20 }}
                onClick={() => navigate(-1)}
              />
              <h3 className="font-size18 text-white Inter-Bold mx-2">
                Add New Node{" "}
              </h3>
            </div>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    {/* <CustomMultiSelectDevice
                      label={"Devices"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="devices"
                      multiple={true}
                      error={errors?.devicess}
                      options={records ? records : []}
                      setValue={setValue}
                      watch={watch}
                      fullWidth
                    />{" "} */}

                    <AddNodeTable
                      list={list}
                      setList={setList}
                      param={param}
                      setParam={setParam}
                      selectedRowKeys={selectedRowKeys}
                      setSelectedRowKeys={setSelectedRowKeys}
                      totalRecord={records?.total_records}
                      colums1={colums}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                      onClick={() => onSubmit()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTrustedGroupNode;
