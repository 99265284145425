import Search from "antd/es/transfer/search";
import React, { useState } from "react";

import {
  deleteDomainAction,
  getDomainListAction,
} from "../../../../redux/action/action";
import QueryString from "qs";
import ApiHelper from "../../../../common/Hooks/ApiHelper";
import { Pagination } from "antd";
import { BsEye } from "react-icons/bs";
import Swal from "sweetalert2";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import { HiOutlineDotsVertical } from "react-icons/hi";

const Index = () => {
  const [open, setOpen] = useState(false);
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getDomainListAction(QueryString.stringify(payload)));
  };
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.domains, //this is getting data from redux  store  and return into records
  });
  const onShowSizeChange = (page, page_size) => {
    console.log(page, page_size);
    setParam({ ...param, page: page, page_size: page_size });
  };
  const onDelete = (id) => {
    Swal.fire({
      title: "Confirm Delete",
      text: "Are you sure you want to delete this domain?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D8EAFF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      confirmButtonTextColor: "#0177FB",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          page: param?.page,
          page_size: param?.page_size,
          search: param?.search?.length ? param?.search : undefined,
        };
        dispatch(deleteDomainAction(QueryString.stringify(payload), id));
      }
    });
  };
  const onChange = (page) => {
    setParam({ ...param, page: page });
  };
  return (
    <div>
      {" "}
      <div className="dd-wrap">
        <div className="page-head d-flex align-items-center justify-content-between">
          <div className="page-title">
            <div className="cus-tabs-head">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="devices-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#devices"
                    type="button"
                    role="tab"
                    aria-controls="devices"
                    aria-selected="false"
                    onClick={() => navigate("/device")}
                  >
                    Devices
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="domains-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#domains"
                    type="button"
                    role="tab"
                    aria-controls="domains"
                    aria-selected="true"
                  >
                    Domains
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="r-btn-block">
            <button
              type="button"
              className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={() => navigate("/domain/add")}
            >
              Create New Domain
            </button>
          </div>
        </div>
      </div>
      <div className="domains-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <div className="t-filter-search">
            <Search
              onChange={(event) =>
                setParam({ ...param, search: event.target.value })
              }
              placeholder="Search"
              // className="search"
            />{" "}
          </div>
        </div>
        <div className="domains-card-body pd20">
          {records?.records?.map((record) => (
            <div className="domains-card" key={record.domainId}>
              <div className="domains-c-head">
                <h5>{record.domainName}</h5>
                <div className="btn-group">
                  <button
                    type="button"
                    className="trans-btn dropdown-toggle"
                    onClick={() => setOpen(record.domainId)}
                  >
                    <HiOutlineDotsVertical className="icon" />
                  </button>
                  {open == record.domainId ? (
                    <ul className="dropdown-menu dropdown-menu-end dom-drop-box">
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() =>
                            navigate(`/domain/edit/${record.domainId}`)
                          }
                        >
                          <div className="da-icon">
                            <BsEye className="icon" />
                          </div>
                          View
                        </button>
                      </li>
                      <li>
                        <button
                          className="dropdown-item"
                          type="button"
                          onClick={() => onDelete(record.domainId)}
                        >
                          <div className="da-icon">
                            <AiOutlineDelete className="icon" />
                          </div>
                          Delete
                        </button>
                      </li>
                    </ul>
                  ) : null}
                </div>
              </div>
              <div className="domains-card-body">
                <h4>
                  {record.mappedDevices} <span>Mapped Devices</span>
                </h4>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          // showSizeChanger
          className="text-end"
          onShowSizeChange={onShowSizeChange}
          defaultCurrent={1}
          current={param.page}
          total={records?.total_records}
          // hideOnSinglePage={true}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default Index;
