import { FormControlLabel, Radio, RadioGroup, Tooltip } from "@mui/material";
import { Collapse } from "antd";
import QueryString from "qs";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineReload } from "react-icons/ai";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { CustomSearch } from "../../common/UI/CustomSearch";
import FilterBlock from "../../common/UI/FilterBlock";
import { ThreatHuntingContext } from "../../context/ThreatHuntingContext";
import { spinnerStartStop } from "../../redux/action/action";
import "./ThreatHunting.css";

const TestProgress = () => {
  const { Panel } = Collapse;
  const { getBatchResults } = useContext(ThreatHuntingContext);
  const [data, setData] = useState({});
  const [deviceList, setDeviceList] = useState([]);
  const [batchName, setBatchName] = useState("");
  const [headerData, setHeaderData] = useState({});
  const [sortType, setSortType] = useState("DESC");
  const [selectedColumn, setSelectedColumn] = useState("device_name");
  const [statusFilter, setStatusFilter] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);

  const { id } = useParams();
  const location = useLocation();

  //getting data from api and store
  const getRecords = async () => {
    dispatch(spinnerStartStop(true));
    const payload = {
      // page: param?.page,
      // page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      sortOrder: sortType,
      sortColumn: selectedColumn,
      statusFilter: statusFilter === "clear" ? null : statusFilter,
    };
    await getBatchResults(id, QueryString.stringify(payload)).then((result) => {
      setData(result?.data?.data);
    });

    dispatch(spinnerStartStop(false));
  };

  useEffect(() => {
    let endpointIds = [];
    data?.records?.map((data) => {
      if (!endpointIds.find((val) => val == data.connectedDeviceId)) {
        endpointIds.push(data.connectedDeviceId);
      }
    });
    let finalData = [];
    endpointIds.map((id) => {
      let endData = { assessmentTests: [] };
      let endpoints = data?.records?.filter(
        (record) => record.connectedDeviceId == id
      );
      endpoints?.map((rec) => {
        endData.connectedDevice = rec.connectedDevice;
        endData.assessmentTests = [
          ...endData.assessmentTests,
          ...rec.assessmentTests,
        ];
      });
      finalData.push(endData);
    });
    setDeviceList(finalData);
    headerData.totalEndpoints = data?.records?.length;
    headerData.totalTests = 0;
    headerData.success = 0;
    headerData.failed = 0;
    headerData.inprogress = 0;
    data?.records?.map((device) => {
      headerData.totalTests = headerData?.totalTests
        ? headerData.totalTests + device.assessmentTests.length
        : device.assessmentTests.length;
      headerData.success = headerData?.success
        ? headerData.success +
          device.assessmentTests.filter((test) => test.status === "completed")
            .length
        : device.assessmentTests.filter((test) => test.status === "completed")
            .length;
      headerData.failed = headerData?.failed
        ? headerData.failed +
          device.assessmentTests.filter((test) => test.status === "failed")
            .length
        : device.assessmentTests.filter((test) => test.status === "failed")
            .length;
      headerData.inprogress = headerData?.inprogress
        ? headerData.inprogress +
          device.assessmentTests.filter((test) => test.status === "inProgress")
            .length
        : device.assessmentTests.filter((test) => test.status === "inProgress")
            .length;
    });
    setHeaderData(headerData);
  }, [data]);

  useEffect(() => {
    if (location && location?.state && location?.state?.batchName) {
      setBatchName(location?.state?.batchName);
    }
  }, []);
  const navigateLink = useNavigate();
  const { records, param, setParam, dispatch } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => data, //this is getting data from redux  store  and return into records
  });

  const AccordianHeader = ({ data }) => {
    let status = "completed";
    if (data.assessmentTests.find((test, key) => test.status === "pending")) {
      status = "pending";
    }
    if (
      data.assessmentTests.find((test, key) => test.status === "inProgress")
    ) {
      status = "inProgress";
    }
    if (data.assessmentTests.find((test, key) => test.status === "failed")) {
      status = "failed";
    }
    return (
      <div className=" row">
        <div className="col-4 text-wrap">
          {data.connectedDevice.device.name}
        </div>
        <div className="col-3 text-wrap">{data.connectedDevice.name}</div>
        <div className="col-2 text-wrap">{data.assessmentTests.length}</div>
        <p className="col-2 text-wrap">
          {status === "completed" ? (
            <li>
              <span className="dot bg-green"></span>
              <span className="text-success">Completed</span>
            </li>
          ) : status === "failed" ? (
            <li>
              <span className="dot bg-red"></span>
              <span className="text-danger">Failed</span>
            </li>
          ) : status === "pending" ? (
            <li>
              <span className="dot bg-blue"></span>
              <span className="blue-text">Pending</span>
            </li>
          ) : (
            <li>
              <span className="dot bg-orange"></span>
              <span className="text-warning">In Progress</span>
            </li>
          )}
        </p>
      </div>
    );
  };
  const TestAccordianHeader = ({ data }) => (
    <span className="header_configure_accordian">
      <p className="wd-100">{data?.msfTest?.name}</p>
      <p className="wd-100">
        {data?.status === "completed" ? (
          <li>
            <span className="dot bg-green"></span>
            <span className="text-success">Completed</span>
          </li>
        ) : data?.status === "failed" ? (
          <li>
            <span className="dot bg-red"></span>
            <span className="text-danger">Failed</span>
          </li>
        ) : data?.status === "pending" ? (
          <li>
            <span className="dot bg-blue"></span>
            <span className="blue-text">Pending</span>
          </li>
        ) : (
          <li>
            <span className="dot bg-orange"></span>
            <span className="text-warning">In Progress</span>
          </li>
        )}
      </p>
      <p className="wd-100 pd-l5">
        <AiOutlineReload
          className="icon"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            getRecords();
          }}
        />
      </p>
    </span>
  );
  const simpleResult = (test) => {
    const exploitMessage = /Meterpreter session (\d+) opened|vulnerable/;
    const vulnerableMessage = /Host is likely VULNERABLE/;
    const BFsuccessMessage = / Success:|completed /;
    const portScanningMessage = / open /;
    switch (test?.msfTest?.name) {
      case "Port Scanning":
        let result;
        if (test.status === "completed") {
          if (portScanningMessage.test(test?.msfConsole?.output)) {
            result = "port scaning completed and there are some ports open";
          } else {
            result = "port scaning completed no port open";
          }
        }
        if (test.status === "failed") {
          if (!test?.msfConsole?.output) {
            result = "Test not completed Somthing wrong while executing test";
          }
        }
        if (!test?.msfConsole?.output) {
          result = "Test is in progress";
        }
        return result;
      case "Eternal Blue":
        let EBresult;
        if (test.status === "completed") {
          if (exploitMessage.test(test?.msfConsole?.output)) {
            EBresult = "Host is exploit successfully";
          }
          if (vulnerableMessage.test(test?.msfConsole?.output)) {
            EBresult = "Host is vulnerable and " + EBresult;
          }
          if (!vulnerableMessage.test(test?.msfConsole?.output)) {
            EBresult = "Host is not vulnerable";
          }
        }
        if (test.status === "failed") {
          if (exploitMessage.test(test?.msfConsole?.output)) {
            EBresult = "Test completed but Host is not vulnerable";
          } else {
            EBresult = "Test not completed Somthing wrong while executing test";
          }
        }
        if (!test?.msfConsole?.output) {
          EBresult = "Test is in progress";
        }

        return EBresult;
      case "Brute Force SSH":
        let BFresult;
        if (test.status === "completed") {
          if (BFsuccessMessage.test(test?.msfConsole?.output)) {
            BFresult = "User Name and Password successfully matched";
          } else {
            BFresult = "User Name and Password not matched";
          }
        }
        if (test.status === "failed") {
          // if (!test?.msfConsole?.output) {
          if (!BFsuccessMessage.test(test?.msfConsole?.output)) {
            BFresult = "Test completed but no user name or passwrod matched";
          } else {
            BFresult = "Test not completed Somthing wrong while executing test";
          }
        }
        if (!test?.msfConsole?.output) {
          BFresult = "Test is in progress";
        }
        return BFresult;
    }
  };
  const onChangeFilterStatus = (e) => {
    e.stopPropagation();
    setStatusFilter(e.target.value);
  };
  const Filters = () => (
    <div className="row ">
      <div className="col-sm-12">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Select Status</span>
          </legend>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="completed"
              control={<Radio checked={statusFilter === "completed"} />}
              label="Completed"
              onChange={onChangeFilterStatus}
              className="text-white"
            />
            <FormControlLabel
              value="failed"
              onChange={onChangeFilterStatus}
              control={<Radio checked={statusFilter === "failed"} />}
              label="Failed"
              className="text-white"
            />
            <FormControlLabel
              value="inProgress"
              control={<Radio checked={statusFilter === "inProgress"} />}
              label="In Progress"
              onChange={onChangeFilterStatus}
              className="text-white"
            />
            <FormControlLabel
              value="pending"
              control={<Radio checked={statusFilter === "pending"} />}
              label="Pending/Schedule"
              onChange={onChangeFilterStatus}
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>

      <div className="col-sm-12 r-btn-block mr-t20">
        <button
          type="button"
          className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
          onClick={() => setStatusFilter("clear")}
        >
          Clear
        </button>
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 mr-l20 font-size14 Inter-Medium"
          onClick={submitSortingAndFiltering}
        >
          Apply
        </button>
      </div>
    </div>
  );
  const onChangeSortOption = (e) => {
    e.stopPropagation();
    setSelectedColumn(e.target.value);
  };
  const onChangeSortOrder = (e) => {
    e.stopPropagation();
    setSortType(e.target.value);
  };
  const SortOptions = () => (
    <div className="row ">
      <div className="col-sm-12">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Select Column</span>
          </legend>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="device_name"
              control={<Radio checked={selectedColumn === "device_name"} />}
              label="Device Name"
              onChange={onChangeSortOption}
              className="text-white"
            />
            <FormControlLabel
              value="endpoint_name"
              onChange={onChangeSortOption}
              control={<Radio checked={selectedColumn === "endpoint_name"} />}
              label="Endpoint Name"
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 mr-t20">
        <fieldset className="threat-hunting-fieldset">
          <legend className="threat-hunting-legend">
            <span>Sort Type</span>
          </legend>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="DESC"
              control={<Radio checked={sortType === "DESC"} />}
              label="DESC"
              onChange={onChangeSortOrder}
              className="text-white"
            />
            <FormControlLabel
              value="ASC"
              onChange={onChangeSortOrder}
              control={<Radio checked={sortType === "ASC"} />}
              label="ASC"
              className="text-white"
            />
          </RadioGroup>
        </fieldset>
      </div>
      <div className="col-sm-12 r-btn-block">
        <button
          type="button"
          className="blue-btn h32 pd-l10 pd-r10 mr-t20 font-size14 Inter-Medium"
          onClick={submitSortingAndFiltering}
        >
          Apply
        </button>
      </div>
    </div>
  );
  const submitSortingAndFiltering = async () => {
    await getRecords();
  };
  useEffect(() => {
    if (statusFilter === "clear") {
      getRecords();
    }
  }, [statusFilter]);
  return (
    <div className="add-user-wrap">
      <div className="dark-head-card">
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigateLink("/threat_hunting")}
            />
            Logs: {batchName}
          </h3>
        </div>
        <div className="d-c-head d-flex align-items-center row gap10">
          <div className="col-sm-3 pd-b30">
            <span className="addEditTestLabel">Total End Points</span>
            <p className="addEditTestDesc">{headerData.totalEndpoints}</p>
          </div>
          <div className="col-sm-3 pd-b30">
            <span className="addEditTestLabel">Total Tests</span>
            <p className="addEditTestDesc">{headerData.totalTests}</p>
          </div>
          <div className="col-sm-3 pd-b30">
            <span className="addEditTestLabel">Success</span>
            <p className="addEditTestDesc success-text">{headerData.success}</p>
          </div>
          <div className="col-sm-3 pd-b30">
            <span className="addEditTestLabel">Failed</span>
            <p className="addEditTestDesc text-danger">{headerData.failed}</p>
          </div>
          <div className="col-sm-3 pd-b30">
            <span className="addEditTestLabel">In Progress</span>
            <p className="addEditTestDesc blue-text">{headerData.inprogress}</p>
          </div>
        </div>
        <div className="d-c-body">
          <div className="add-u-form-card">
            <div className="table-head table-head1 d-flex align-items-center justify-content-between mr-t20">
              <CustomSearch param={param} setParam={setParam} />
              {/* <FilterBlock
                filters={<Filters />}
                sorting={<SortOptions />}
                setFilterOpen={setFilterOpen}
                filterOpen={filterOpen}
              /> */}
            </div>
            <div className="row table-colaps">
              <div className=" mr-t20 mr-b20 addBatch-LabelColor  thread-hunting-table row">
                <div className="col-4">Device Name</div>
                <div className="col-3">Endpoint Name</div>
                <div className="col-2">Tests Count</div>
                <div className="col-2">Status</div>
              </div>
              <Collapse bordered={false}>
                {deviceList?.map((device, index) => (
                  <Panel header={<AccordianHeader data={device} />} key={index}>
                    <div className="col-sm-12 configure-inner-table-border test_details_inner_collapse">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <div className="mr-l60 mr-t20 mr-b20 addBatch-LabelColor configure_header">
                          <span className="wd-100">Test Name</span>
                          <span className="wd-100">Status</span>
                          <span className="wd-100">Action</span>
                        </div>
                        <Collapse bordered={false}>
                          {device?.assessmentTests?.map((test, index) => (
                            <Panel
                              header={<TestAccordianHeader data={test} />}
                              key={index}
                            >
                              <div className="row ">
                                <div className="col-sm-12 mr-l60 mr-t20 mr-b20 addBatch-LabelColor configure_header test_details overflow-auto">
                                  <span className="test_details_table_width">
                                    Input Parameters
                                    <p className="console_detail">
                                      {test?.msfConsole?.executedCommand}
                                    </p>
                                  </span>

                                  <span className="test_details_table_width">
                                    Log Details
                                    <Tooltip title={test?.msfConsole?.output}>
                                      <p className="console_detail">
                                        {test?.msfConsole?.output}
                                      </p>
                                    </Tooltip>
                                  </span>
                                  <span className="test_details_table_width">
                                    Test Result
                                    <p className="console_detail simple_result">
                                      {simpleResult(test)}
                                    </p>
                                  </span>
                                  <span className="test_details_table_width">
                                    Mitigation Details
                                    <p className="console_detail">N/A</p>
                                  </span>
                                </div>
                              </div>
                            </Panel>
                          ))}
                        </Collapse>
                      </div>
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestProgress;
