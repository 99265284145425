import * as Yup from 'yup';

export const addScheduleSchema = Yup.object({
  schedule_name: Yup.string().required('Schedule name is required'),
});
export const repeatScheduleSchema = Yup.object().shape({
  repeat_category: Yup.string(),
  repeat_number: Yup.number().test(
    'repeat_number',
    'invalid input',
    (value, validationContext) => {
      const {
        createError,
        parent: { repeat_category },
      } = validationContext;

      if (repeat_category === 'daily') {
        if (value >= 1 && value < 6) {
          return true;
        } else {
          return createError({
            message: 'please enter between 1 to 5',
          });
        }
      }
      if (repeat_category === 'weekly') {
        if (value >= 1 && value < 5) {
          return true;
        } else {
          return createError({
            message: 'please enter between 1 to 4',
          });
        }
      }

      if (repeat_category === 'monthly') {
        if (value >= 1 && value < 13) {
          return true;
        } else {
          return createError({
            message: 'please enter between 1 to 12',
          });
        }
      }

      if (repeat_category === 'yearly') {
        if (value == 1) {
          return true;
        } else {
          return createError({
            message: 'please enter 1',
          });
        }
      }
    },
  ),
  repeat_onday_nmber: Yup.number().min(1).max(30),
  // .when('repeat_category', {
  //   is: (repeat_category) => repeat_category === 'weekly',
  //   then: () => Yup.number().min(1).max(7),
  // }),
});
