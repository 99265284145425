import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import CustomDropdown from "../../../common/CustomDropdown";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { alertColumns } from "../../../common/Tables/ReportsColumn";
import CustomDatePickerWithoutValue from "../../../common/UI/CustomDatePickerWithoutValue";
import { CustomSearch } from "../../../common/UI/CustomSearch";
import CustomTable from "../../../common/UI/CustomTable";
import {
  getAlertListReportAction,
  getAlertReportCsvAction,
  getDeviceTypesAction,
} from "../../../redux/action/action";
import { alertReportSchema } from "../../../utils/validations/deviceScanningSchema";
import { Table } from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";

const threatImpact = [
  { label: "High", value: "1" },
  { label: "Medium", value: "2" },
  { label: "Low", value: "3" },
];
const threatAction = [
  { label: "Mitigated", value: "block" },
  { label: "Allowed", value: "allow" },
];
const alertTypes = [
  { label: "Admin", value: "admin" },
  { label: "Malware", value: "malware" },
  { label: "System", value: "system" },
  { label: "Auth", value: "auth" },
  { label: "Intrusion", value: "intrusion" },
  { label: "Trust", value: "trust" },
  { label: "AI", value: "ai" },
  { label: "Others", value: "others" },
];

const Index = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // You can access the query parameters using the useLocation hook
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Get specific query parameters
  const threatImpactParam = params.get("threatImpact");
  const alertTypeParam = params.get("alertType");
  const threatActionParam = params.get("threatAction");
  const startDateParam = params.get("start_date");
  const endDateParam = params.get("end_date");
  const searchParam = params.get("search");
  const reportsDeviceTypes = useSelector(
    (state) => state.cylenium.reportsDeviceTypes
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(alertReportSchema),
  });
  const onSubmit = (data) => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      alert_type: data?.alertTypes ? data?.alertTypes : undefined,
      priority: data?.threatImpact ? data?.threatImpact : undefined,
      threat_impact: data?.threatAction ? data?.threatAction : undefined,
      deviceType: data?.deviceType ? data?.deviceType : undefined,
      start_date: data.startDate
        ? dayjs(data.startDate).unix()
          ? dayjs(data.startDate).format("YYYY-MM-DD")
          : undefined
        : undefined,
      end_date: data.endDate
        ? dayjs(data.endDate).unix()
          ? dayjs(data.endDate).format("YYYY-MM-DD")
          : undefined
        : undefined,
    };
    setSelectedRowKeys([]);
    dispatch(getAlertListReportAction(QueryString.stringify(payload)));
  };
  useEffect(() => {
    let countChange = 0;
    if (threatImpactParam) {
      for (let record of threatImpact) {
        if (record.label == threatImpactParam) {
          setValue("threatImpact", record.value);
          countChange++;
        }
      }
    }
    if (alertTypeParam) {
      setValue("alertTypes", alertTypeParam);
      countChange++;
    }
    if (threatActionParam) {
      for (let record of threatAction) {
        if (record.label == threatActionParam) {
          setValue("threatAction", record.value);
          countChange++;
        }
      }
    }
    if (startDateParam) {
      setValue("startDate", dayjs(startDateParam));
      countChange++;
    }
    if (endDateParam) {
      setValue("endDate", dayjs(endDateParam));
      countChange++;
    }
    if (searchParam) setParam({ ...param, search: searchParam });
    else {
      if (countChange) {
        getRecords();
      }
    }
  }, [
    threatImpactParam,
    alertTypeParam,
    threatActionParam,
    startDateParam,
    endDateParam,
    searchParam,
  ]);

  const downloadCsv = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,

      values: selectedRowKeys?.join(","),
    };
    dispatch(getAlertReportCsvAction(QueryString.stringify(payload)));
  };
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      priority: threatImpactParam ? watch("threatImpact") : undefined,
      alert_type: alertTypeParam ? watch("alertTypes") : undefined,
      threat_impact: threatActionParam ? watch("threatAction") : undefined,
      start_date: startDateParam
        ? dayjs(startDateParam).format("YYYY-MM-DD")
        : undefined,
      end_date: endDateParam
        ? dayjs(endDateParam).format("YYYY-MM-DD")
        : undefined,
    };
    dispatch(getAlertListReportAction(QueryString.stringify(payload)));
    dispatch(getDeviceTypesAction(QueryString.stringify(payload)));
  };
  const onSelectChange = (newSelectedRowKeys, selectedRow) => {
    console.log("newSelectedRowKeys", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleChange = (pagination, filters, sorter) => {
    console.log(pagination);
    setParam({
      ...param,
      page: pagination.current,
      page_size: pagination?.pageSize,
      filters,
      sorter,
    });
  };
  // Custom hook which handle all dependency on Table data
  const { records, dispatch, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.alertList, //this is getting data from redux  store  and return into records
  });

  // useEffect(() => {
  //   if (searchParam) setParam({ ...param, search: searchParam });
  // }, [searchParam]);
  // console.log("logs", records);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">Alerts </h3>
          </div>

          <div className="d-c-body">
            <div className="add-u-form-card"></div>
            <div className="row">
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomDropdown
                    label={"Alert Type"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="alertTypes"
                    error={errors?.alert_type}
                    options={alertTypes?.length ? alertTypes : []}
                    fullWidth
                  />
                </div>
              </div>

              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomDropdown
                    label={"Threat Impact"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="threatImpact"
                    error={errors?.threatImpact}
                    options={threatImpact?.length ? threatImpact : []}
                    fullWidth
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomDropdown
                    label={"Threat Action"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="threatAction"
                    error={errors?.threatAction}
                    options={threatAction?.length ? threatAction : []}
                    fullWidth
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomDropdown
                    label={"Device Type"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="deviceType"
                    error={errors?.deviceType}
                    options={
                      reportsDeviceTypes?.records
                        ? reportsDeviceTypes?.records
                        : []
                    }
                    fullWidth
                  />
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomDatePickerWithoutValue
                    label={"Start Date"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="startDate"
                    error={errors?.startDate}
                    disableFuture={true}
                    fullWidth
                  />{" "}
                </div>
              </div>
              <div className="col-sm-4">
                <div className="form-floating cus-floating-inputs mr-b30">
                  <CustomDatePickerWithoutValue
                    label={"End Date"}
                    varient="outline"
                    control={control}
                    name="endDate"
                    error={errors?.endDate}
                    disableFuture={true}
                    fullWidth
                  />{" "}
                </div>
              </div>

              <div className="col-sm-12 mt-2">
                <div className="f-btm-btn text-end">
                  <button
                    type="button"
                    className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                    onClick={() => reset()}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div className="border-bottom-2 d-flex align-items-center justify-content-between mt-3 p-3">
                <h3 className="font-size18 text-white"> Reports </h3>
              </div>
            </div>
            <div className="dark-table-wrap">
              <div className="table-head d-flex align-items-center justify-content-between mr-t20 table-head-custom">
                <div className="t-filter-search">
                  <CustomSearch param={param} setParam={setParam} />
                </div>
                <div>
                  <button
                    type="button"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    onClick={downloadCsv}
                  >
                    Report
                  </button>
                </div>
              </div>
              <div className="">
                <Table
                  dataSource={records?.records}
                  columns={alertColumns({})}
                  className="custom-table-1"
                  onChange={handleChange}
                  rowSelection={rowSelection}
                  pagination={{
                    showSizeChanger: true,
                    current: param.page,
                    pageSize: param.page_size,
                    total: records?.total_records,
                  }}
                  scroll={{ x: 1200 }}
                  rowKey={"id"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Index;
