import React, { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { IoArrowBackOutline } from "react-icons/io5";
import { useParams } from "react-router-dom";
import CustomDropdown from "../../../common/CustomDropdown";
import CustomInput from "../../../common/CustomInput";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import {
  getTrustGroupRuleSetAction,
  updateTrustGroupRuleAction,
} from "../../../redux/action/action";
import { newRuleSchema } from "../../../utils/validations/trustGroupSchema";
const options = [
  { value: "TCP", label: "TCP" },
  { value: "UDP", label: "UDP" },
  { value: "ICMP", label: "ICMP" },
];
const EditTrustedGroupRule = () => {
  const params = useParams();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(newRuleSchema),
  });
  const getRecords = () => {
    dispatch(getTrustGroupRuleSetAction(params?.rule_id));
  };

  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.cotRule, //this is getting data from redux  store  and return into records
  });
  const onSubmit = (data) => {
    dispatch(updateTrustGroupRuleAction(data));
  };
  useEffect(() => {
    if (records?.length) {
      reset(records?.at(0));
    }
  }, [records]);

  console.log("Rule", records);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <div className="page-title d-flex">
              <IoArrowBackOutline
                color="white"
                style={{ fontSize: 20 }}
                onClick={() => navigate(-1)}
              />
              <h3 className="font-size18 text-white Inter-Bold mx-2">
                Edit Rule{" "}
              </h3>
            </div>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Port"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="port"
                      error={errors?.port}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Protocol"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="protocol"
                      error={errors?.protocol}
                      options={options}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Host"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="host"
                      error={errors?.host}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Description"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="description"
                      error={errors?.description}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditTrustedGroupRule;
