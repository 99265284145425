import React from "react";
import Chart from "react-apexcharts";

const ChartComponent = () => {
  const lineChartData = {
    series: [
      {
        name: "Series 1",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
      {
        name: "Series 2",
        data: [23, 34, 45, 32, 27, 41, 52, 66, 88],
      },
    ],
    options: {
      chart: {
        id: "line-chart",
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
      },
    },
  };

  const barChartData = {
    series: [
      {
        name: "Series 1",
        data: [44, 55, 41, 64, 22, 43, 21],
      },
      {
        name: "Series 2",
        data: [53, 32, 33, 52, 13, 44, 32],
      },
    ],
    options: {
      chart: {
        id: "bar-chart",
      },
    //   xaxis: {
    //     categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    //   },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
        labels: {
          style: {
            colors: '#ffffff', // Set label color to white
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: '#ffffff', // Set label color to white
          },
        },
      },
    },
  };

  return (
    <div>
      {/* <Chart
        options={lineChartData.options}
        series={lineChartData.series}
        type="line"
        width="500"
      /> */}
      <Chart
        options={barChartData.options}
        series={barChartData.series}
        type="bar"
        width="500"
      />
    </div>
  );
};

export default ChartComponent;
