import { Table } from "antd";
import React from "react";

const CustomTable = ({ records, columns, param, setParam, ...rest }) => {
  const handleChange = (pagination, filters, sorter) => {
    console.log(pagination);
    setParam({
      ...param,
      page: pagination.current,
      page_size: pagination?.pageSize,
      filters,
      sorter,
    });
  };
  return (
    <div>
      {" "}
      <Table
        {...rest}
        dataSource={records?.records}
        columns={columns}
        onChange={handleChange}
        scroll={{
          x: rest?.scroll ? rest?.scroll : 768,
        }}
        pagination={{
          showSizeChanger: true,
          current: param.page,
          pageSize: param.page_size,
          total: records?.total_records,
        }}
      />
    </div>
  );
};

export default CustomTable;
