import React from "react";
import { useNavigate } from "react-router-dom";
import TrustedGroupTable from "./TrustedGroupTable";

const TrustedGroup = () => {
  const navigate = useNavigate();
  return (
    <div className="dd-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">
            Circles of Trust
          </h3>
        </div>
        <div className="r-btn-block">
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate("/trusted_group/add")}
          >
            {" "}
            Create CoT Group
          </button>
        </div>
      </div>
      <TrustedGroupTable />
    </div>
  );
};

export default TrustedGroup;
