import React, { useEffect, useState } from "react";
import handLogo from "./../../assets/images/Waving_Hand.svg";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Background } from "../../common/Background";
import CustomInput from "../../common/CustomInput";
import CustomInputPassword from "../../common/CustomInputPassword";
import { signInAction } from "../../redux/action/action";
import Footer from "../Navigation/Footer";

const validationSchema = Yup.object({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),
});
const Login = () => {
  const [rememberMe, setRemeberMe] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authData = useSelector((state) => state.cylenium.auth);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data) => {
    dispatch(signInAction(data, rememberMe ? true : false));
  };

  useEffect(() => {
    if (authData)
      if (authData?.x_auth_token) {
        navigate(authData?.landing_page);
        // dispatch(getProfileAction(authData.user_id));
      }
  }, [authData]);
  return (
    <>
      <Background>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-box">
            <div className="l-inn-content">
              <h5 className="mr-b20">
                Welcome back
                <img src={handLogo} alt="" title="" />
              </h5>
              <h3 className="text-center"> Sign in to your account </h3>
              <div className="l-form mx-auto mr-t40">
                <div className="mb-3">
                  <CustomInput
                    label={"Username"}
                    varient="outline"
                    control={control}
                    defaultValue=""
                    name="email_address"
                    error={errors?.email_address}
                    fullWidth
                  />
                </div>
                <div className="group-form cus-f-group ">
                  <div className="mb-3">
                    <CustomInputPassword
                      label={"Password"}
                      name="password"
                      varient="outline"
                      control={control}
                      error={errors?.password}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="f-r-block">
                  <label htmlFor="l1" className="r-cus-check">
                    <input
                      type="checkbox"
                      name=""
                      id="l1"
                      onChange={(event) => setRemeberMe(event.target.checked)}
                    />
                    <span className="mr-r10"></span>
                    Remember me
                  </label>
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
                <div className="l-btm-btn mr-t20">
                  <button
                    type="Submit"
                    className="blue-btn  h46 w-100"
                    onClick="location.href=''"
                  >
                    Sign in
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Background>
      {/* <Footer /> */}
    </>
  );
};

export default Login;
