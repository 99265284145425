export const whiteThemeAntd = {
  token: {
    // colorText: "#1F1F1F66",
    colorBgElevated: "#ffffff",
    fontFamily: "Inter-Regular",
    // colorBgContainer: "#2A2E31",
  },
};
export const blackThemeAntd = {
  token: {
    colorText: "#fff",
    colorBgElevated: "#2C3033",
    fontFamily: "Inter-Regular",
    colorBgContainer: "#2A2E31",
  },
};
export const blueThemeAntd = {
  token: {
    colorText: "#fff",
    colorBgElevated: "#262c3f",
    // colorBgElevated: "#262c3f",
    fontFamily: "Inter-Regular",
    colorBgContainer: "#151A2C",
    filterDropdownMenuBg: "#262c3f",
  },
};
