import { Empty, Tabs } from "antd";
import Search from "antd/es/input/Search";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { BsListTask } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllDeviceListAction,
  getThreatAlertListActions,
} from "../../../redux/action/action";
import AccessRules from "./AccessRules/Index";
import Alerts from "./Alerts";
import Policies from "./Policies";
import RuleSets from "./RuleSets/Index";
import { useLocation } from "react-router-dom";

const Index = () => {
  const [selectedDevice, setSelectedDevice] = useState("");
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(1);

  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
    selectedDevice: "",
  });

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const records = useSelector((state) => state.cylenium.allDeviceList);
  const getDeviceList = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getAllDeviceListAction(QueryString.stringify(payload)));
  };
  const getDeviceInfoById = (device_id) => {
    setSelectedDevice(device_id);
    const payload = {
      device_id: device_id ? device_id : undefined,
      key: queryParams.get("key") ? queryParams.get("key") : 1,
    };
    navigate(`/threat_policies?${QueryString.stringify(payload)}`);
    dispatch(getThreatAlertListActions(QueryString.stringify({ device_id })));
    setOpen(false);
  };
  useEffect(() => {
    getDeviceList();
  }, [param]);

  const onChange = (key) => {
    setTab(key);
    const payload = {
      device_id: queryParams.get("device_id")
        ? queryParams.get("device_id")
        : undefined,
      key: key,
    };
    navigate(`/threat_policies?${QueryString.stringify(payload)}`);
  };
  const items = [
    {
      key: "1",
      label: "Alerts",
      children: <Alerts />,
    },
    {
      key: "2",
      label: " Access Rules",
      children: <AccessRules />,
    },
    {
      key: "3",
      label: "Rulesets",
      children: <RuleSets device_id={queryParams.get("device_id")} />,
    },
    {
      key: "4",
      label: "Policies",
      children: <Policies device_id={queryParams.get("device_id")} />,
    },
  ];
  return (
    <div>
      <div className="body-r-container">
        <div className="page-wrap">
          <div className="device-wrap">
            <div className="page-head d-flex align-items-center justify-content-between mr-b20">
              <div className="page-title">
                <h3 className="font-size18 text-white Inter-Bold">
                  Threat Policies
                </h3>
              </div>
            </div>
            <BsListTask
              onClick={() => setOpen(!open)}
              className="list-device"
            />
            <div
              className={`ds-board ${open ? "show-device" : "hide-device"}  `}
            >
              <div className="ds-search-col">
                <div className="ds-list">
                  <div className="ds-serach-block">
                    <Search
                      onChange={(event) =>
                        setParam({ ...param, search: event.target.value })
                      }
                      placeholder="Search"
                    />
                  </div>
                  <div className="list-block">
                    <ul>
                      {records?.length &&
                        records.map((record) => (
                          <li
                            key={record.device_id}
                            className={
                              record?.device_id == selectedDevice
                                ? "selected_device item-active"
                                : "item-active"
                            }
                            onClick={() => getDeviceInfoById(record.device_id)}
                          >
                            {record.device_name}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="ds-resuld-col">
                <div className="ds-r-container">
                  {queryParams.get("device_id") ? (
                    <div className="ds-r-block">
                      <div className="over-wrap">
                        <div className="ds-r-head justify-content-between">
                          <div
                            className="cus-tabs-head"
                            // style={{ width: "88%" }}
                          >
                            <Tabs
                              defaultActiveKey={queryParams.get("key")}
                              items={items}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "100%" }}
                    >
                      <Empty description="Device is not  selected" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
