import { Empty } from "antd";
import React from "react";

const CountCard = ({ name, count, color }) => {
  return (
    <div className="count-card ">
      <div className="dashboard-graph" style={{ height: 160 }}>
        <div className=" row graph-title mb-3  justify-content-center align-item-center">
          {true ? (
            <div className="mx-auto text-center">
              <h5
                className="count-heading"
                style={{ color: color, fontSize: 72 }}
              >
                {count ? count : 0}
              </h5>
              <div className="mb-2">{name ? name : ""}</div>
            </div>
          ) : (
            <Empty />
          )}
        </div>
      </div>
    </div>
  );
};

export default CountCard;
