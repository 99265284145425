import * as Yup from 'yup';

export const externalEndpointValidation = Yup.object({
  endpoint_name: Yup.string().required('This field is required'),
  endpoint_os: Yup.string().required('This field is required'),
  endpoint_public_ip: Yup.string()
    .test('endpoint_public_ip', 'invalid IP', (value, validationContext) => {
      const { createError } = validationContext;
      let regexIP =
        /^(([0-1]?[0-9]?[0-9]{1})|(2?[0-4]?[0-9]{1})|(25[0-5]))\.(([0-1]?[0-9]?[0-9]{1})|(2?[0-4]?[0-9]{1})|(25[0-5]))\.(([0-1]?[0-9]?[0-9]{1})|(2?[0-4]?[0-9]{1})|(25[0-5]))\.(([0-1]?[0-9]?[0-9]{1})|(2?[0-4]?[0-9]{1})|(25[0-5]))$/;
      console.log(regexIP.test(value));
      if (regexIP.test(value)) {
        return true;
      } else {
        return createError({
          message: 'please enter valid IP',
        });
      }
    })
    .required('This field is required'),
});
