import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomInput from "../../common/CustomInput";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  emptyPasswordAndResetPassword,
  forgotPasswordAction,
} from "../../redux/action/action";
import Swal from "sweetalert2";
import { Background } from "../../common/Background";

const validationSchema = Yup.object({
  email_address: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const EmailVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgotPassword = useSelector((state) => state.cylenium.forgotPassword);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = (data) => {
    dispatch(forgotPasswordAction(data));
  };

  useEffect(() => {
    if (forgotPassword)
      if (forgotPassword?.statusCode === 200)
        Swal.fire({
          title: "Mail has been sent",
          text: forgotPassword.message,
          icon: "success",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Okay",
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(emptyPasswordAndResetPassword());
            navigate("/");
          }
        });
  }, [dispatch, forgotPassword, navigate]);
  return (
    <Background>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-box">
          <div className="l-inn-content">
            <h3 className="text-center"> Forgot password? </h3>
            <p className="text-center mr-t10 l-s-text">
              Please enter the email address you’d like <br /> your password
              reset information sent to
            </p>
            <div className="l-form mx-auto mr-t40">
              <div className="mb-3">
                <CustomInput
                  label={"Username"}
                  varient="outline"
                  control={control}
                  defaultValue=""
                  name="email_address"
                  error={errors?.email_address}
                  fullWidth
                />{" "}
              </div>
              <p className="text-center mr-t10 l-b-text">
                A new <b>Password creation link</b> will be sent to <br /> your{" "}
                <b>registered email address</b>. Please verify <br /> and create
                new password
              </p>
              <div className="l-btm-btn  mr-t30">
                <button
                  type="submit"
                  className="blue-btn  h46 w-100"
                  onClick="location.href='forgot-password.html'"
                >
                  Send Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Background>
  );
};

export default EmailVerification;
