import { Empty } from "antd";
import React from "react";

const BasicInfo = ({ record }) => {
  return (
    <div className="d-c-head-black row">
      <div className="col-12 mb-2">
        <span className="heading text-white"> Basic Information</span>
      </div>
      {!record?.id ? (
        <>
          <div className="col-3">
            <div>
              <span className="heading-label">Scan Time</span>
            </div>
            <div>
              <span className="text-white">{record?.scanTime}</span>
            </div>
          </div>
          <div className="col-3">
            <div>
              <span className="heading-label">Scan Duration</span>
            </div>
            <div>
              <span className="text-white">
                {record?.scanDuration ? record?.scanDuration : "-"}{" "}
              </span>
            </div>
          </div>
          <div className="col-3">
            <div>
              <span className="heading-label">Scan Status</span>
            </div>
            <div>
              <span className="text-white">
                {" "}
                {record?.scanStatus ? record?.scanStatus : "-"}{" "}
              </span>
            </div>
          </div>
          <div className="col-3">
            <div>
              <span className="heading-label">Endpoints Scanned</span>
            </div>
            <div>
              <span className="text-white">
                {" "}
                {record?.endpointsScanned ? record?.endpointsScanned : "-"}{" "}
              </span>
            </div>
          </div>
        </>
      ) : (
        <Empty />
      )}
    </div>
  );
};

export default BasicInfo;
