/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  addRulesetAction,
  getAllDeviceListAction,
  getRuleByIdAction,
} from "../../../../redux/action/action";
import RuleSetForm from "./RuleSetForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { addRuleset } from "./RulsetValidation";

const EditRuleset = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [isContent, setContent] = useState(false);
  const [isRegex, setRegex] = useState(false);
  const [isRegexClear, setisRegexClear] = useState(false);
  const [isContentClear, setContentClear] = useState(false);
  const [disable, setDisable] = useState(true);
  const { id, rule_id } = useParams();
  const [oldRuleset, setOldruleset] = useState();
  const rule = useSelector((state) => state.cylenium.ruleById);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(addRuleset),
    defaultValues: { users: [], ruleset: [] },
  });

  const onSubmit = (data) => {
    // validateForm();
    const lastId =
      oldRuleset.length > 0 ? oldRuleset[oldRuleset.length - 1].id : 0;
    const newRule = {
      id: lastId + 1,
      rule: watch("ruleset"),
    };
    // console.log(typeof(watch('rev_num')))
    // if(watch('rev_num') > 254){
    //   return
    // }
    setOldruleset([...oldRuleset, newRule]);
    console.log(oldRuleset);
    setValue("ruleset", "");
    setValue("action", "");
    setValue("protocol", "");
    setValue("source_ip", "");
    setValue("source_port", "");
    setValue("dest_ip", "");
    setValue("dest_port", "");
    setValue("sid", "");
    setValue("rev_num", "");
    setValue("rule_Message", "");
    setValue("class_type", "");
    setValue("priority", "");
    setValue("gid", "");
    setValue("data_size", "");
    setValue("data_size_input", "");
    setValue("reference", "");
    setValue("reference_input", "");
    setValue("threshold_track_type", "");
    setValue("trk_by", "");
    setValue("count_hash", "");
    setValue("seconds", "");
    setValue("offset", "");
    setValue("content_match", "");
    setValue("depth", "");
    setValue("nocase", "");
    setValue("uri", "");
    setValue("not", "");
    setValue("dotal", "");
    setValue("regex_nocase", "");
    setValue("greedy", "");
    setValue("newline_m", "");
    setValue("whitespace", "");
    setValue("http_request", "");
    setValue("http_request_method", "");
    setValue("http_reponse", "");
    setValue("regex_input", "");
    setValue("direction", "");
    setValue("tcp_state", "");
    setValue("plus", "");
    setValue("star", "");
    setValue("ACK", "");
    setValue("SYN", "");
    setValue("PSH", "");
    setValue("RST", "");
    setValue("FIN", "");
    setValue("URG", "");
    setValue("icmp_type", "");
    setValue("icmp_input", "");
    setValue("icmp_code", "");
    setValue("icmp_code_input", "");
    setValue("udp_direction", "");
    setValue("ip_ttl", "");
    setValue("ip_ttl_input", "");
    setValue("ip_ip_protocol", "");
    setValue("ip_ip_protocol_input", "");
    setValue("content", "");
    setValue("sgx_devices", "");
  };

  const onSubmitCustom = (data) => {
    console.log(data);
    const onlyDeviceIds = [id];
    const all_ruleset = [];
    oldRuleset.map((old) => {
      all_ruleset.push(old);
    });
    selectedDevices?.forEach((record) => {
      if (record.checked && !onlyDeviceIds.includes(record.device_id)) {
        onlyDeviceIds.push(record.device_id);
      }
    });
    dispatch(
      addRulesetAction({
        devices: onlyDeviceIds,
        rules: all_ruleset,
        // devices: sgsx
      })
    );
  };

  const getDeviceList = () => {
    const payload = {
      page: 1,
      page_size: 1000,
    };
    dispatch(getAllDeviceListAction(QueryString.stringify(payload)));
  };
  const getRuleById = () => {
    dispatch(getRuleByIdAction(rule_id));
  };
  useEffect(() => {
    getDeviceList();
    getRuleById();
  }, []);
  useEffect(() => {
    // reset({ ruleset: rule?.rules });
    setOldruleset(rule?.rules);
  }, [rule_id, rule]);

  const action = watch("action");
  const protocol = watch("protocol");
  const source_ip = watch("source_ip");
  const source_port = watch("source_port");
  const dest_ip = watch("dest_ip");
  const dest_port = watch("dest_port");
  const sid = watch("sid");
  const rev_num = watch("rev_num");
  const rule_Message = watch("rule_Message");
  const class_type = watch("class_type");
  const priority = watch("priority");
  const gid = watch("gid");
  const data_size = watch("data_size");
  const data_size_input = watch("data_size_input");
  const reference = watch("reference");
  const reference_input = watch("reference_input");
  const threshold_track_type = watch("threshold_track_type");
  const trk_by = watch("trk_by");
  const count_hash = watch("count_hash");
  const seconds = watch("seconds");
  const offset = watch("offset");
  const content_match = watch("content_match");
  const depth = watch("depth");
  const nocase = watch("nocase");
  const uri = watch("uri");
  const not = watch("not");
  const dotal = watch("dotal");
  const regex_nocase = watch("regex_nocase");
  const greedy = watch("greedy");
  const newline_m = watch("newline_m");
  const whitespace = watch("whitespace");
  const http_request = watch("http_request");
  const http_request_method = watch("http_request_method");
  const http_reponse = watch("http_reponse");
  const regex_input = watch("regex_input");
  const direction = watch("direction");
  const tcp_state = watch("tcp_state");
  const plus = watch("plus");
  const star = watch("star");
  const ACK = watch("ACK");
  const SYN = watch("SYN");
  const PSH = watch("PSH");
  const RST = watch("RST");
  const FIN = watch("FIN");
  const URG = watch("URG");
  const icmp_type = watch("icmp_type");
  const icmp_input = watch("icmp_input");
  const icmp_code = watch("icmp_code");
  const icmp_code_input = watch("icmp_code_input");
  const udp_direction = watch("udp_direction");
  const ip_ttl = watch("ip_ttl");
  const ip_ttl_input = watch("ip_ttl_input");
  const ip_ip_protocol = watch("ip_ip_protocol");
  const ip_ip_protocol_input = watch("ip_ip_protocol_input");
  const content_match_check = watch("content-match-check");
  const sgx_devices = watch("sgx_devices");

  useEffect(() => {
    const tcp = `${
      http_request_method
        ? " content:" + http_request_method + "; http_method;"
        : ""
    }${http_reponse ? " content:" + http_reponse + "; http_method;" : ""}${
      star || plus || ACK || SYN || PSH || RST || FIN || URG
        ? "flags:" +
          (star ? "*" : "") +
          (plus ? "+" : "") +
          (ACK ? "A" : "") +
          (SYN ? "S" : "") +
          (PSH ? "P" : "") +
          (RST ? "R" : "") +
          (FIN ? "F" : "") +
          (URG ? "U" : "") +
          "; "
        : ""
    }${direction ? "flow:" + direction + "," : ""}${
      tcp_state ? tcp_state + ";" : ""
    }`;
    const impc = `${icmp_type ? "itype:" + icmp_type + icmp_input + ";" : ""}${
      icmp_code ? "icode:" + icmp_code + icmp_code_input + ";" : ""
    }`;
    const udp_s = `${udp_direction ? "flow:" + udp_direction + ";" : ""}`;
    const ip_s = `${ip_ttl ? "ttl:" + ip_ttl + ip_ttl_input + ";" : ""}${
      ip_ip_protocol
        ? "ip_proto:" + ip_ip_protocol + ip_ip_protocol_input + ";"
        : ""
    }`;
    let content_match_string = isContent
      ? `${content_match ? " content:" + content_match : ""}${
          offset ? "; offset: " + offset : ""
        }${depth ? "; depth: " + depth : ""}${nocase ? "; nocase" : ""}${
          uri ? "http_uri;" : ""
        }`
      : "";
    let regex_statement = isRegex
      ? `pcre:"/${regex_input}/${dotal ? "i" : ""}${regex_nocase ? "s" : ""}${
          greedy ? "m" : ""
        }${newline_m ? "x" : ""}${whitespace ? "G" : ""}";`
      : "";
    regex_statement = isRegexClear ? "" : regex_statement;
    content_match_string = isContentClear ? "" : content_match_string;
    const proto_statement =
      watch("protocol") == "tcp"
        ? tcp
        : watch("protocol") == "icmp"
        ? impc
        : watch("protocol") == "udp"
        ? udp_s
        : watch("protocol") == "ip"
        ? ip_s
        : "";
    setValue(
      "ruleset",
      `${action} ${protocol} ${source_ip} ${source_port} -> ${
        dest_ip ? dest_ip : " "
      } ${dest_port} ${protocol ? "(" : ""}${
        rule_Message ? "msg: " + '"' + rule_Message + '";' : ""
      }${proto_statement ? proto_statement : ""}${
        content_match_string ? content_match_string : ""
      } ${regex_statement ? regex_statement : ""} ${
        data_size ? " dsize:" + data_size + data_size_input : ""
      } ${reference ? ";reference:" + reference + "," + reference_input : ""} ${
        threshold_track_type ? "threshold:type " + threshold_track_type : ""
      } ${trk_by ? "track " + trk_by : ""} ${
        count_hash ? "count " + count_hash : ""
      } ${seconds ? ", seconds " + seconds + ";" : ""}${
        class_type ? " classtype:" + class_type + ";" : ""
      }${priority ? " priority:" + priority + ";" : ""}${
        gid ? " gid:" + gid + ";" : ""
      }${sid ? " sid:" + sid + ";" : ""}${
        rev_num ? " rev:" + rev_num + ";" : ""
      }${protocol ? ")" : ""}`
    );
  }, [
    action,
    protocol,
    source_ip,
    source_port,
    dest_port,
    dest_ip,
    sid,
    rev_num,
    rule_Message,
    class_type,
    priority,
    gid,
    data_size,
    data_size_input,
    reference,
    reference_input,
    threshold_track_type,
    trk_by,
    count_hash,
    seconds,
    offset,
    content_match,
    depth,
    nocase,
    uri,
    not,
    regex_input,
    http_request,
    http_request_method,
    star,
    plus,
    ACK,
    SYN,
    PSH,
    RST,
    FIN,
    URG,
    tcp_state,
    direction,
    icmp_type,
    icmp_input,
    icmp_code,
    icmp_code_input,
    udp_direction,
    ip_ttl,
    ip_ttl_input,
    ip_ip_protocol,
    ip_ip_protocol_input,
    dotal,
    regex_nocase,
    greedy,
    newline_m,
    whitespace,
    content_match_check,
    isContent,
    isRegex,
    isRegexClear,
    isContentClear,
    sgx_devices,
  ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate(-1)}
              />
              Ruleset
            </h3>
            {/* <div>
              <div>
                <button
                  type="button"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  onClick={() => setDisable(!disable)}
                >
                  {disable ? "Edit" : "Cancel"}{" "}
                </button>
              </div>
            </div> */}
          </div>
          <RuleSetForm
            control={control}
            disable={false}
            selectedDevices={selectedDevices}
            setSelectedDevices={setSelectedDevices}
            // onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            watch={watch}
            ruleData={oldRuleset}
            setRuleData={setOldruleset}
            onSubmitCustom={onSubmitCustom}
            setValue={setValue}
            reset={reset}
            errors={errors}
            // Other props...
          />
        </div>
      </div>
    </form>
  );
};

export default EditRuleset;
