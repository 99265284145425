import Search from "antd/es/transfer/search";
import QueryString from "qs";
import React from "react";
import { IoArrowBack } from "react-icons/io5";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { invoiceHistoryColumns } from "../../common/Tables/subscriptionTableColumn";
import CustomTable from "../../common/UI/CustomTable";
import { getSubscriptionInvoiceHistoryListAction } from "../../redux/action/action";

const InvoiceHistory = () => {
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      first_name: param.sorter?.order ? param?.sorter?.order : undefined,
      filters: param?.filters?.role ? param?.filters?.role : undefined,
    };
    dispatch(
      getSubscriptionInvoiceHistoryListAction(QueryString.stringify(payload))
    );
  };

  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.invoiceHistoryList, //this is getting data from redux  store  and return into records
  });
  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">
            {" "}
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigate(-1)}
            />
            Invoice History
          </h3>
        </div>
      </div>

      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <div className="t-filter-search">
            <Search
              onChange={(event) =>
                setParam({ ...param, search: event.target.value })
              }
              className="search"
            />
          </div>
        </div>
        <div className="mt-3">
          {" "}
          <CustomTable
            records={records}
            columns={invoiceHistoryColumns({ navigate })} //we  are passing this to column to antd column which in separate file
            param={param}
            setParam={setParam}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceHistory;
