import * as Yup from "yup";

export const addTrustedGroup = Yup.object({
  groupName: Yup.string().required("This field is required"),
  days: Yup.number()
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? undefined
        : Number(originalValue)
    )
    .typeError("Days must be a number.")
    .min(0, "Days must be a positive number greater than zero.")
    .max(65, "Days must be in the 0-65 range.")
    .required("Days is required"),
  hours: Yup.number()
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? undefined
        : Number(originalValue)
    )
    .typeError("Hours must be a number.")
    .min(0, "Hours must be a positive number greater than zero.")
    .max(24, "Hours must be in the 0-24 range.")
    .required("Hours is required"),
  minutes: Yup.number()
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? undefined
        : Number(originalValue)
    )
    .typeError("Minutes must be a number.")
    .min(0, "Minutes must be a positive number greater than zero.")
    .max(60, "Minutes must be in the 0-60 range.")
    .required("Minutes is required"),
});
export const editTrustedGroup = Yup.object({
  // groupName: Yup.string().required("This field is required"),
  days: Yup.number()
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? undefined
        : Number(originalValue)
    )
    .typeError("Days must be a number.")
    .min(0, "Days must be a positive number greater than zero.")
    .max(65, "Days must be in the 0-65 range.")
    .required("Days is required"),
  hours: Yup.number()
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? undefined
        : Number(originalValue)
    )
    .typeError("Hours must be a number.")
    .min(0, "Hours must be a positive number greater than zero.")
    .max(24, "Hours must be in the 0-24 range.")
    .required("Hours is required"),
  minutes: Yup.number()
    .transform((value, originalValue) =>
      typeof originalValue === "string" && originalValue.trim() === ""
        ? undefined
        : Number(originalValue)
    )
    .typeError("Minutes must be a number.")
    .min(0, "Minutes must be a positive number greater than zero.")
    .max(60, "Minutes must be in the 0-60 range.")
    .required("Minutes is required"),
});

export const addNode = Yup.object({
  devices: Yup.array().test({
    name: "notEmpty",
    message: "This field is required",
    test: (value) => value && value.length > 0,
  }),
});
export const newRuleSchema = Yup.object({
  port: Yup.number()
    .typeError("Port must be a number")
    .positive("Port must be a positive number")
    .integer("Port must be an number")
    .required("Port is required"),
  protocol: Yup.string().required("This field is required"),
  host: Yup.string().required("This field is required"),
});
