import { Autocomplete, Checkbox, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomMultiSelect({
  options,
  label,
  name,
  multiple,
  watch,
  disabled,
  setValue,
  ...rest
}) {
  const [selectedValue, setSelectedValue] = useState([]);

  // Adding "Select All" option
  const allOption = { value: "all", label: "Select All" };
  const extendedOptions = [allOption, ...options];

  const onChange = (event, newValue) => {
    if (multiple) {
      if (newValue.find((option) => option.value === "all")) {
        if (newValue.length === options.length + 1) {
          // Deselect "Select All"
          setSelectedValue([]);
          setValue(name, []);
        } else {
          // Select all options
          setSelectedValue(options);
          setValue(
            name,
            options.map(({ value }) => value)
          );
        }
      } else {
        setSelectedValue(newValue);
        setValue(
          name,
          newValue.map(({ value }) => value)
        );
      }
    } else {
      setSelectedValue(newValue);
      setValue(name, newValue.value);
    }
  };

  useEffect(() => {
    const values = watch(name);
    if (values) {
      if (multiple) {
        const tempArray = options?.filter((obj) => values?.includes(obj.value));
        setSelectedValue(tempArray);
      }
    }
  }, [watch(name), options]);

  return (
    <Autocomplete
      sx={{ m: 0, width: "100%" }}
      limitTags={1}
      multiple={multiple ? multiple : false}
      disabled={disabled ? disabled : false}
      options={extendedOptions}
      defaultValue={selectedValue[0]}
      value={multiple ? selectedValue : {}}
      getOptionLabel={(option) => option.label}
      onChange={onChange}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label ? label : ""} />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.value}
          value={option.label}
          sx={{ justifyContent: "space-between" }}
        >
          <Checkbox
            checked={
              selected ||
              (option.value === "all" &&
                selectedValue.length === options.length)
            }
          />
          {option.label}
        </MenuItem>
      )}
    />
  );
}
