import React, {
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
} from "react";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Table } from "antd";
import markerIcon from "../../assets/images/dot.svg";
import config from "../../config/config";

const OFFSET_AMOUNT = 0.000016; // Small offset to prevent exact overlap

const columns = [
  {
    title: "Endpoint Name",
    dataIndex: "endpointName",
  },
  {
    title: "IP Address",
    dataIndex: "IPAddress",
    render: (data) => (data === "" ? "-" : data),
  },
  {
    title: "Threat Count",
    dataIndex: "threatCount",
  },
];

const AlertGraph = ({ graphData }) => {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [devices, setDevices] = useState([]);

  const mapContainerStyle = {
    height: "280px",
    width: "100%",
    borderRadius: 10,
  };

  const onMarkerClick = (marker) => {
    setSelectedMarker(marker);
  };

  const onCloseClick = () => {
    setSelectedMarker(null);
  };

  useEffect(() => {
    if (graphData?.devices?.length) {
      setDevices(graphData.devices);
    }
  }, [graphData]);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ["visualization"],
  });

  const [map, setMap] = useState(null);

  useLayoutEffect(() => {
    if (map && devices.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      devices.forEach((device) => {
        const lat = parseFloat(device.latitude);
        const lng = parseFloat(device.longitude);
        if (!isNaN(lat) && !isNaN(lng)) {
          bounds.extend({ lat, lng });
        } else {
          console.error("Invalid coordinates for device:", device);
        }
      });
      map.fitBounds(bounds);

      // Ensure the zoom level is not too high
      const listener = window.google.maps.event.addListener(map, "idle", () => {
        if (map.getZoom() > 10) {
          map.setZoom(2); // Adjust this zoom level as necessary
        }
        window.google.maps.event.removeListener(listener);
      });
    }
  }, [map, devices]);

  const onLoad = useCallback(
    (map) => {
      if (devices.length === 0) {
        map.setCenter({ lat: 30.337844, lng: 59.955413 });
        map.setZoom(1);
      }
      setMap(map);
    },
    [devices]
  );

  const onUnmount = useCallback((map) => {
    setMap(null);
  }, []);

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          onLoad={onLoad}
          onUnmount={onUnmount}
          mapContainerStyle={mapContainerStyle}
          options={{ maxZoom: 40, minZoom: 3 }}
        >
          <MarkerClusterer>
            {(clusterer) => {
              return devices.map((marker, index) => {
                const lat = parseFloat(marker.latitude) + index * OFFSET_AMOUNT;
                const lng =
                  parseFloat(marker.longitude) + index * OFFSET_AMOUNT;
                if (!isNaN(lat) && !isNaN(lng)) {
                  return (
                    <Marker
                      key={index}
                      position={{ lat, lng }}
                      onClick={() => onMarkerClick(marker)}
                      clusterer={clusterer}
                      icon={markerIcon}
                    />
                  );
                } else {
                  console.error("Invalid coordinates for marker:", marker);
                  return null;
                }
              });
            }}
          </MarkerClusterer>

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: parseFloat(selectedMarker.latitude),
                lng: parseFloat(selectedMarker.longitude),
              }}
              onCloseClick={onCloseClick}
            >
              <div className="map-modal">
                <div style={{ paddingRight: 14 }}>
                  <div>
                    <div>
                      <span className="heading-label">Device Name</span>
                    </div>
                    <div>
                      <span className="text-white f-700 mt-1">
                        {selectedMarker.name}
                      </span>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div>
                      <span className="heading-label">Device Address</span>
                    </div>
                    <div>
                      <span className="text-white f-700 mt-1">
                        {selectedMarker.locationName}
                      </span>
                    </div>
                    <div>
                      <Table
                        dataSource={selectedMarker.deviceDetails}
                        columns={columns}
                        className="custom-table-1"
                        pagination={{
                          hideOnSinglePage: true,
                          showTotal: (total) => `Total ${total} items`,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      ) : null}
    </div>
  );
};

export default React.memo(AlertGraph);
