import QueryString from "qs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomDropdown from "../../../common/CustomDropdown";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { analyticsDetailsColumns } from "../../../common/Tables/ReportsColumn";
import CustomTable from "../../../common/UI/CustomTable";
import {
  getAllDeviceListAction,
  getAnalyticsDetailsAction,
  getAnalyticsReportCsvAction,
} from "../../../redux/action/action";
import { IoArrowBack } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MultiSelectDevices from "../../../common/MultiSelectDevices";
import CustomDatePickerWithoutValue from "../../../common/UI/CustomDatePickerWithoutValue";
import dayjs from "dayjs";
import { CustomSearch } from "../../../common/UI/CustomSearch";

const Status = [
  { label: "Open", value: "0" },
  { label: "Closed", value: "1" },
];
const days = [
  { label: "Last Week", value: "7" },
  { label: "Last 14 days", value: "14" },
  { label: "Last 30 days", value: "30" },
  { label: "Last 60 days", value: "60" },
  { label: "Last 60 days", value: "90" },
];
const Severity = [
  { label: "Unknown", value: "Unknown" },
  { label: "Log", value: "Log" },
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" },
];

const AnalyticsDetails = () => {
  // You can access the query parameters using the useLocation hook
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  // Get specific query parameters
  const statusParam = params.get("status");
  const severity = params.get("severity");
  const openedWithin = params.get("openedWithin");
  const startDateParam = params.get("start_date");
  const endDateParam = params.get("end_date");
  const searchParam = params.get("search");
  const allDeviceList = useSelector((state) => state.cylenium.allDeviceList);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({});

  const onSubmit = (data) => {
    setParam({ ...param, data });
  };
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      date: param?.data?.date?.length ? param?.data?.date : undefined,
      closed: param?.data?.closed?.length ? param?.data?.closed : undefined,
      vulnerabilityThreat: param?.data?.vulnerabilityThreat?.length
        ? param?.data?.vulnerabilityThreat
        : undefined,
      devices: param?.data?.devices?.length ? param?.data?.devices : undefined,
      start_date: param?.data?.startDate
        ? dayjs(param?.data?.startDate).format("YYYY-MM-DD")
        : startDateParam
        ? dayjs(startDateParam).format("YYYY-MM-DD")
        : undefined,
      end_date: param?.data?.endDate
        ? dayjs(param?.data?.endDate).format("YYYY-MM-DD")
        : endDateParam
        ? dayjs(endDateParam).format("YYYY-MM-DD")
        : undefined,
    };
    dispatch(getAnalyticsDetailsAction(QueryString.stringify(payload)));
  };
  // Custom hook which handle all dependency on Table data

  const downloadReport = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      date: param?.data?.date?.length ? param?.data?.date : undefined,
      closed: param?.data?.closed?.length ? param?.data?.closed : undefined,
      vulnerabilityThreat: param?.data?.vulnerabilityThreat?.length
        ? param?.data?.vulnerabilityThreat
        : undefined,
      devices: param?.data?.devices?.length ? param?.data?.devices : undefined,
      start_date: param?.data?.startDate
        ? dayjs(param?.data?.startDate).format("YYYY-MM-DD")
        : startDateParam
        ? dayjs(startDateParam).format("YYYY-MM-DD")
        : undefined,
      end_date: param?.data?.endDate
        ? dayjs(param?.data?.endDate).format("YYYY-MM-DD")
        : endDateParam
        ? dayjs(endDateParam).format("YYYY-MM-DD")
        : undefined,
    };
    dispatch(getAnalyticsReportCsvAction(QueryString.stringify(payload)));
  };
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.analyticsDetails, //this is getting data from redux  store  and return into records
  });
  // console.log("param", QueryString.stringify(param?.filters?.role));
  const resetButton = () => {
    reset();
    setValue("startDate", null);
    setValue("endDate", null);

    navigate("/reports/details");
    setParam({ ...param, data: {} });
  };

  useEffect(() => {
    let newParams = { ...param.data };

    if (["High", "Medium", "Low", "Unknown", "Log"]?.includes(severity)) {
      setValue("vulnerabilityThreat", severity);
      newParams.vulnerabilityThreat = severity;
    }
    if (["0", "1"]?.includes(statusParam)) {
      setValue("closed", statusParam);
      newParams.closed = statusParam;
    }
    if (openedWithin) {
      setValue("date", openedWithin);
      newParams.date = openedWithin;
    }
    if (startDateParam) {
      setValue("startDate", dayjs(startDateParam));
      getRecords(); // Fetch records based on new start date
    }
    if (endDateParam) {
      setValue("endDate", dayjs(endDateParam));
      getRecords(); // Fetch records based on new end date
    }
    if (searchParam) {
      newParams.search = searchParam;
      // getRecords(); // Uncomment if you need to fetch records based on searchParam
    }

    // Update the param state only once with the accumulated changes
    setParam({
      ...param,
      data: newParams,
      search: newParams.search ? newParams.search : undefined,
    });

    dispatch(
      getAllDeviceListAction(QueryString.stringify({ page_size: 1000 }))
    );
  }, [
    severity,
    statusParam,
    openedWithin,
    startDateParam,
    endDateParam,
    searchParam,
  ]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/reports/analytics")}
              />
              Analytics
            </h3>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Severity"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="vulnerabilityThreat"
                      error={errors?.vulnerabilityThreat}
                      options={Severity?.length ? Severity : []}
                      fullWidth
                    />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Status"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="closed"
                      error={errors?.closed}
                      options={Status?.length ? Status : []}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Opened Within"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="date"
                      error={errors?.date}
                      options={days?.length ? days : []}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <MultiSelectDevices
                      label={"Devices"}
                      varient="outline"
                      control={control}
                      placeholder="Devices"
                      // defaultValue=""
                      name="devices"
                      error={errors?.devicess}
                      options={allDeviceList}
                      mode={"multiple"}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDatePickerWithoutValue
                      label={"Start Date"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="startDate"
                      error={errors?.startDate}
                      disableFuture={true}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDatePickerWithoutValue
                      label={"End Date"}
                      varient="outline"
                      control={control}
                      name="endDate"
                      error={errors?.endDate}
                      disableFuture={true}
                      fullWidth
                    />{" "}
                  </div>
                </div>
              </div>

              <div className="col-sm-12 mt-2">
                <div className="f-btm-btn text-end">
                  <button
                    type="button"
                    className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                    onClick={resetButton}
                  >
                    Reset
                  </button>
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  >
                    Apply Filter
                  </button>
                </div>
              </div>
            </div>

            <div className="dark-table-wrap">
              <div className="table-head d-flex align-items-center justify-content-between mr-t20 table-head-custom">
                <div className="t-filter-search">
                  <CustomSearch param={param} setParam={setParam} />
                </div>
                <div>
                  <button
                    type="button"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    onClick={downloadReport}
                  >
                    Report
                  </button>
                </div>
              </div>
              <div className="">
                {" "}
                <CustomTable
                  records={records}
                  columns={analyticsDetailsColumns({})} //we  are passing this to column to antd column which in separate file
                  param={param}
                  setParam={setParam}
                  scroll={2000}
                  className="custom-table-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AnalyticsDetails;
