import * as Yup from "yup";

export const editSchema = Yup.object({
  rev_num: Yup.number()
    .min(0, "Number must be greater than or equal to 0")
    .max(254, "Number must be less than or equal to 254")
    .required("This field is required"),

  source_ip: Yup.string().matches(
    /^(?!0)(?!.*\.$)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
  ),

  dest_ip: Yup.string().matches(
    /^(?!0)(?!.*\.$)(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/
  ),

  sid: Yup.number().required("This field is required"),

  gid: Yup.number().required("This field is required"),

  protocol: Yup.string().required("This field is required"),

  action: Yup.string().required("This field is required"),

  class_type: Yup.string().required("This field is required"),

  priority: Yup.string().required("This field is required"),
});
const ipv4Regex =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

export const addRuleset = Yup.object({
  // rev_num: Yup.string().trim().required("Number Only"),

  // source_ip: Yup.string().required("Source ip required"),

  // dest_ip: Yup.string().required("Destination ip req"),

  protocol: Yup.string().trim().required("Protocol required"),

  action: Yup.string().required("Action required"),

  // sid: Yup.string().trim().required("Sid required"),

  // gid: Yup.string().trim().required("Gid required"),

  // class_type: Yup.string().required("Class Type required"),

  // priority:  Yup.string().required("This field is required"),
  source_ip: Yup.string()
    .test(
      "is-valid-ip-or-any",
      "Invalid IP address or not allowed value",
      (value) => value === "any" || ipv4Regex.test(value)
    )
    .required("IP address is required"),
  dest_ip: Yup.string()
    .test(
      "is-valid-ip-or-any",
      "Invalid IP address or not allowed value",
      (value) => value === "any" || ipv4Regex.test(value)
    )
    .required("IP address is required"),
  source_port: Yup.string()
    .test(
      "is-valid-port-or-any",
      "Invalid port number or not allowed value",
      (value) => {
        // Check if value is 'any'
        if (value === "any") {
          return true;
        }
        // Check if value is a valid port number
        const portNumber = parseInt(value, 10);
        return !isNaN(portNumber) && portNumber >= 0 && portNumber <= 65535;
      }
    )
    .required("Port number is required"),
  dest_port: Yup.string()
    .test(
      "is-valid-port-or-any",
      "Invalid port number or not allowed value",
      (value) => {
        // Check if value is 'any'
        if (value === "any") {
          return true;
        }
        // Check if value is a valid port number
        const portNumber = parseInt(value, 10);
        return !isNaN(portNumber) && portNumber >= 0 && portNumber <= 65535;
      }
    )
    .required("Port number is required"),
});
