import { Button, Result } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Result
        status="success"
        title="Successfully Purchased"
        subTitle={
          <div className="text-white">
            Configuring your subscription plan will take between 1 to 5 minutes.
            Please be patient.
          </div>
        }
        extra={[
          <Button type="primary" key="console" onClick={() => navigate("/")}>
            Go To Login
          </Button>,
        ]}
      />
    </div>
  );
};

export default PaymentSuccess;
