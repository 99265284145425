import QueryString from "qs";
import React from "react";

import Swal from "sweetalert2";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { notificationsTable } from "../../../common/Tables/notificationsTable";
import { CustomSearch } from "../../../common/UI/CustomSearch";
import CustomTable from "../../../common/UI/CustomTable";
import FilterBlock from "../../../common/UI/FilterBlock";
import {
  deleteNotificationAction,
  getNotificationTemplateAction,
} from "../../../redux/action/action";
const NotificationTable = () => {
  const onDelete = (notification_id) => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(
      deleteNotificationAction(QueryString.stringify(payload), notification_id)
    );
  };

  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getNotificationTemplateAction(QueryString.stringify(payload)));
  };

  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.notifications, //this is getting data from redux  store  and return into records
  });
  return (
    <div className="user-wrap">
      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
        </div>
        <div className="mt-3">
          <div className="">
            <CustomTable
              records={records}
              columns={notificationsTable({ navigate, onDelete })} //we  are passing this to column to antd column which in separate file
              param={param}
              setParam={setParam}
              className="custom-table-1"

            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationTable;
