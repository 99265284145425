import { IconButton, Tooltip } from "@mui/material";
import { FaDownload } from "react-icons/fa";

export const resultReportColumns = (props) => [
  {
    title: "Vulnerability",
    dataIndex: "vulnerability",
    className: "custom-column-padding pl-2", // Applying CSS class to this column
  },
  {
    title: "Severity",
    dataIndex: "severity",
    render: (data) => {
      return (
        <div>
          {data?.includes("Medium") ? (
            <li>
              {/* <span class="dot bg-primary"></span> */}
              <span className="font-weight-400  text-warning">{data}</span>
            </li>
          ) : data?.includes("High") ? (
            <li>
              {/* <span className="font-weight-400  dot bg-primary"></span> */}
              <span className="font-weight-400  text-danger">{data}</span>
            </li>
          ) : data?.includes("Log") ? (
            <li>
              {/* <span className="font-weight-400  dot bg-primary"></span> */}
              <span className="font-weight-400  text-primary">{data}</span>
            </li>
          ) : data?.includes("Low") ? (
            <li>
              {/* <span className="font-weight-400  dot bg-primary"></span> */}
              <span className="font-weight-400  text-success">{data}</span>
            </li>
          ) : (
            <li>
              {/* <span className="font-weight-400  dot bg-primary"></span> */}
              <span className="font-weight-400  text-muted">{data}</span>
            </li>
          )}
        </div>
      );
    },
  },
  {
    title: "QoD",
    dataIndex: "qod",
  },
  {
    title: "Endpoint Name",
    dataIndex: "endpointName",
  },
  {
    title: "QoD",
    dataIndex: "qod",
  },

  {
    title: "IP Address",
    dataIndex: "ip",
  },
  {
    title: "Location",
    dataIndex: "location",
  },
  {
    title: "Logged",
    dataIndex: "logged",
  },
];

export const hostReportColumns = (props) => [
  {
    title: "IP Address",
    dataIndex: "ip",
  },
  {
    title: "Endpoint Name",
    dataIndex: "endpointName",
  },
  {
    title: "OS",
    dataIndex: "os",
  },
  {
    title: "Ports",
    dataIndex: "ports",
  },
  {
    title: "Apps",
    dataIndex: "apps",
  },
  {
    title: "High",
    dataIndex: "high",
  },
  {
    title: "Medium",
    dataIndex: "medium",
  },
  {
    title: "Low",
    dataIndex: "low",
  },
  {
    title: "Log",
    dataIndex: "log",
  },
  {
    title: "Positive",
    dataIndex: "positive",
  },
  {
    title: "Total",
    dataIndex: "total",
  },
  {
    title: "Severity",
    dataIndex: "severity",
    render: (data) => {
      return (
        <div>
          {data?.includes("Medium") ? (
            <li>
              {/* <span class="dot bg-primary"></span> */}
              <span className="font-weight-400  text-warning">{data}</span>
            </li>
          ) : data?.includes("High") ? (
            <li>
              {/* <span className="font-weight-400  dot bg-primary"></span> */}
              <span className="font-weight-400  text-danger">{data}</span>
            </li>
          ) : data?.includes("Log") ? (
            <li>
              {/* <span className="font-weight-400  dot bg-primary"></span> */}
              <span className="font-weight-400  text-primary">{data}</span>
            </li>
          ) : data?.includes("Low") ? (
            <li>
              {/* <span className="font-weight-400  dot bg-primary"></span> */}
              <span className="font-weight-400  text-success">{data}</span>
            </li>
          ) : (
            <li>
              {/* <span className="font-weight-400  dot bg-primary"></span> */}
              <span className="font-weight-400  text-muted">{data}</span>
            </li>
          )}
        </div>
      );
    },
  },
];
export const portsReportColumns = (props) => [
  {
    title: "Ports",
    dataIndex: "port",
  },
  {
    title: "Hosts",
    dataIndex: "hosts",
  },
  {
    title: "Severity",
    dataIndex: "severity",
  },
];

export const scanReportColumns = (props) => [
  {
    title: "Device Name",
    dataIndex: "name",
  },

  // {
  //   title: "Last Scannned",
  //   dataIndex: "last_modified",
  //   render: (data, record) => {
  //     return <div>Tues, Oct 18, 2022 9:43 AM UTC</div>;
  //   },
  // },
  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="Download Report">
            <IconButton>
              <FaDownload
                className="icon me-1"
                onClick={() => props.downloadReportPdf(record.task_id)}
              />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  },
];

export const applicationReportcolumns = (props) => [
  {
    title: "Application CPE",
    dataIndex: "application",
  },
  {
    title: "Hosts",
    dataIndex: "hosts",
  },
  {
    title: "Occurrence",
    dataIndex: "occurrence",
  },
  {
    title: "Severity",
    dataIndex: "severity",
  },
];
export const operatingSystemsReportcolumns = (props) => [
  {
    title: "OS",
    dataIndex: "operatingSystem",
  },
  {
    title: "CEP",
    dataIndex: "cep",
  },
  {
    title: "host",
    dataIndex: "host",
  },
  {
    title: "Severity",
    dataIndex: "severity",
  },
];
