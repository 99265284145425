import { IconButton, Tooltip } from "@mui/material";
import { BsEye } from "react-icons/bs";

import { Popconfirm, Switch } from "antd";
import { IoEyeOutline, IoSettingsOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";

export const deviceScanningTable = (props) => [
  {
    title: "Scan Name",
    dataIndex: "scan_name",
    sorter: true,
  },
  {
    title: "Severity",
    dataIndex: "severity",
    filterMultiple: false, // Disable multiple filters for this column
    // filterResetToDefaultFilteredValue: true,

    // dataIndex: "role",
    filters: [
      {
        text: "Log",
        value: "log",
      },
      {
        text: "Low",
        value: "low",
      },
      {
        text: "Medium",
        value: "medium",
      },
      {
        text: "High",
        value: "high",
      },
    ],
    render: (data) => {
      return (
        <div>
          {data?.includes("Medium") ? (
            <li>
              {/* <span class="dot bg-primary"></span> */}
              <span className="font-weight-400 fs-12 text-warning">{data}</span>
            </li>
          ) : data?.includes("High") ? (
            <li>
              {/* <span className="font-weight-400 fs-12 dot bg-primary"></span> */}
              <span className="font-weight-400 fs-12 text-danger">{data}</span>
            </li>
          ) : data?.includes("Log") ? (
            <li>
              {/* <span className="font-weight-400 fs-12 dot bg-primary"></span> */}
              <span className="font-weight-400 fs-12 text-primary">{data}</span>
            </li>
          ) : data?.includes("Low") ? (
            <li>
              {/* <span className="font-weight-400 fs-12 dot bg-primary"></span> */}
              <span className="font-weight-400 fs-12 text-success">{data}</span>
            </li>
          ) : (
            <li>
              {/* <span className="font-weight-400 fs-12 dot bg-primary"></span> */}
              <span className="font-weight-400 fs-12 text-muted">{data}</span>
            </li>
          )}
        </div>
      );
    },
  },
  {
    title: "Reports",
    dataIndex: "reports",
    render: (data, record) => {
      return (
        <>
          {" "}
          {data > 0 ? (
            <Link
              to={`/device_scanning/report/${record.scan_name}?date=${record.last_report}`}
              className="underline"
            >
              {data}
            </Link>
          ) : (
            <Link className="not-allowed">0</Link>
          )}
        </>
      );
    },
  },
  {
    title: "Last Report",
    dataIndex: "last_report",
    render: (data, record) => {
      return (
        <div className="">
          {data !== "-" ? (
            <Link
              to={`/device_scanning/report_details/${record.scan_name}?date=${record.last_report}`}
              className="underline"
            >
              {data}
            </Link>
          ) : (
            "-"
          )}
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    filterMultiple: false, // Disable multiple filters for this column
    // filterResetToDefaultFilteredValue: true,
    filters: [
      {
        text: "Stopped",
        value: "stopped",
      },
      {
        text: "New",
        value: "new",
      },
      {
        text: "Running",
        value: "running",
      },
      {
        text: "Completed",
        value: "completed",
      },
      {
        text: "Scheduled",
        value: "scheduled",
      },
    ],
    render: (data) => {
      return (
        <>
          {data == "Completed" ? (
            <li>
              <span className="font-weight-400 fs-12 dot bg-green"></span>
              <span className="font-weight-400 fs-12 text-success">{data}</span>
            </li>
          ) : data == "New" ? (
            <li>
              <span className="font-weight-400 fs-12 dot bg-orange"></span>
              <span className="font-weight-400 fs-12 text-warning">{data}</span>
            </li>
          ) : data == "Stopped" ? (
            <li>
              <span className="font-weight-400 fs-12 dot bg-danger"></span>
              <span className="font-weight-400 fs-12 text-danger">{data}</span>
            </li>
          ) : data == "Running" ? (
            <li>
              <span className="font-weight-400 fs-12 dot bg-warning"></span>
              <span className="font-weight-400 fs-12 text-warning">{data}</span>
            </li>
          ) : "Scheduled" ? (
            <li>
              <span className="font-weight-400 fs-12 dot bg-warning"></span>
              <span className="font-weight-400 fs-12 text-warning">{data}</span>
            </li>
          ) : (
            <li>
              {/* <span className="font-weight-400 fs-12 dot bg-primary"></span> */}
              <span className="font-weight-400 fs-12 text-secondary">
                {data}
              </span>
            </li>
          )}
        </>
      );
    },
  },

  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="View">
            <IconButton>
              <BsEye
                className="icon me-1"
                onClick={() =>
                  props.navigate(`/device_scanning/edit/${record.scan_name}`)
                }
              />
            </IconButton>
          </Tooltip>
          {/* <IconButton
            onClick={() =>
              props.navigate(`/device_scanning/edit/${record.unique_id}`)
            }
          >
            <CiEdit className="icon" />
          </IconButton> */}
          <Popconfirm
            title="Confirm Delete"
            description="Are you sure you want to delete this scan?"
            onConfirm={() => props.onDelete(record?.scan_name)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <IconButton>
                <AiOutlineDelete className="icon" />
              </IconButton>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    },
  },
];
export const DeviceDetailsTable = (props) => [
  {
    title: "Device Type",
    dataIndex: "device_type",
  },
  {
    title: "Device Name",
    dataIndex: "name",
  },

  {
    title: "Mac address",
    dataIndex: "mac_address",
  },
  {
    title: "Ip Address",
    dataIndex: "ip_address",
  },
  {
    title: "OS",
    dataIndex: "OS",
  },

  {
    title: "Location",
    dataIndex: "location",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "Model",
    dataIndex: "model",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "Connected",
    dataIndex: "isConnected",
    render: (data, record) => {
      return <Switch checked={data}></Switch>;
    },
  },
  {
    title: "Configuration",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div className="d-flex">
          <Tooltip title="Configure">
            <IconButton>
              <IoSettingsOutline
                className="icon me-1"
                onClick={() =>
                  props.navigate(
                    record?.redirectUrl == "profile"
                      ? `/domain/endpoint/${record.device_id}`
                      : `/ot`
                  )
                }
              />
            </IconButton>
          </Tooltip>

          {props?.deviceType == "Cameras" ? (
            <Tooltip title="View">
              <IconButton>
                <IoEyeOutline
                  className="icon me-1"
                  onClick={() => props.navigate(`/stream/${record.device_id}`)}
                />
              </IconButton>
            </Tooltip>
          ) : null}
        </div>
      );
    },
  },
];
