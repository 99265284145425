import React from "react";

import { Checkbox, Divider, Empty, Progress, Switch } from "antd";
import { getActiveSubscriptionUserAction } from "../../redux/action/action";
import ApiHelper from "../../common/Hooks/ApiHelper";
import moment from "moment";
const Index = () => {
  const getRecords = () => {
    dispatch(getActiveSubscriptionUserAction());
  };

  const {
    records: activePlan,
    dispatch,
    navigate,
  } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.activePlan, //this is getting data from redux  store  and return into records
  });

  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Subscription</h3>
        </div>
      </div>
      <div className="gray-box p-3 mt-3">
        {activePlan?.id ? (
          <div className="b-black p-3">
            <div>
              {" "}
              <span className="label-white">Current Plan Details</span>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <span className="label-gray pb-2">Active Plan</span>

                <span className="label-white f-700 ">{activePlan?.name}</span>
              </div>
              <div className="col-4">
                <span className="label-gray pb-2">Plan Validity</span>

                <span className="label-white f-700 ">
                  <div className="row  justify-content-between">
                    <span className="col-6">
                      {moment(activePlan?.startDate).format("MMM DD YYYY")}
                    </span>
                    <span className="text-end col-6">
                      {" "}
                      {moment(activePlan?.endDate).format("MMM DD YYYY")}
                    </span>
                  </div>
                  <Progress
                    percent={(
                      (moment().diff(moment(activePlan?.startDate), "days") /
                        365) *
                      100
                    ).toFixed(1)}
                    strokeColor="#DC3545"
                    showInfo="false"
                    trailColor="#35383B"
                    size="medium"
                  />
                </span>
              </div>
              <div className="col-4">
                <span className="label-gray pb-2">Status</span>
                {moment().format("X") >=
                  moment(activePlan?.startDate).format("X") &&
                moment().format("X") <=
                  moment(activePlan?.endDate).format("X") ? (
                  <span className="label-green c-green f-700 ">Active</span>
                ) : (
                  <span className="label-green text-danger f-700 ">
                    Inactive
                  </span>
                )}
              </div>
            </div>
            <Divider></Divider>
            <div>
              <span className="label-gray pb-2">Auto Renewal Plan</span>
              <Switch size="small" checked={false} />
            </div>
            <div className="mt-3">
              <Checkbox className="span-gray" checked={true}>
                Send notification for subscription status to my Email Address
              </Checkbox>
            </div>
          </div>
        ) : (
          <Empty description="No plan found"></Empty>
        )}
        <div className="f-btm-btn text-end mt-3">
          <button
            type="button"
            className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate("/subscription/manage_subscription")}
          >
            Manage Subscription{" "}
          </button>
          <button
            type="submit"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
            onClick={() => navigate("/subscription/invoice_history")}
          >
            Invoice History{" "}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Index;
