import React from "react";
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const CustomInput = (props) => {
  return (
    <div>
      <Controller
        {...props}
        defaultValue={""}
        render={({ field }) => (
          <TextField
            {...field}
            {...props}
            variant="outlined"
            error={props?.error && props?.error}
            helperText={props?.error?.message && props?.error?.message}
          />
        )}
      />
    </div>
  );
};

export default CustomInput;
