import { Buffer } from "buffer"; // Import Buffer from the buffer package
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import CustomDropdown from "../../common/CustomDropdown";
import CustomInput from "../../common/CustomInput";
import CustomInputPassword from "../../common/CustomInputPassword";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getCameraStreamAction } from "../../redux/action/action";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const protocolsList = [
  // { label: "http", value: "http" },
  { label: "https", value: "https" },
];
const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),

  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});
export const CameraStream = () => {
  const [flag, setFlag] = useState(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: "https://192.168.58.244/axis-cgi/mjpg/video.cgi",
      username: "view",
      password: "view-123",
      protocol: "https",
    },
  });
  const camera = useSelector((state) => state.cylenium.camera);

  // const getCameraStream = async (data) => {
  //   // Example API call
  //   return axios.post(
  //     "http://3.214.93.141:6009/api/v1.0/dashboard/camera-stream",
  //     data,
  //     {
  //       responseType: "arraybuffer",
  //       headers: {
  //         "X-Auth-Token":
  //           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InVzZXJfaWQiOjE5MiwiZW1haWxfYWRkcmVzcyI6IndpbmppdC10ZXN0QHlvcG1haWwuY29tIiwicm9sZV9pZCI6MywidGlub2RlX3VzZXJuYW1lIjoiQW51cmFnMm94M2IiLCJjb21wYW55X2lkIjoxLCJjb21wYW55X25hbWUiOiJDZXJ2YWlzLCBJbmMuIiwidGhlbWVfaWQiOjEsInRoZW1lX25hbWUiOiJkYXJrIn0sImlhdCI6MTcxOTIyNDI2MiwiZXhwIjoxNzE5MzEwNjYyfQ.R1Rkf7t3JT1IDVFRowGcTsFpliDOvLV6z37y_iRFU8s",
  //       },
  //     }
  //   );
  // };
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    setFlag(true);
    dispatch(getCameraStreamAction(data)); // getCameraStream(data)
    //   .then((res) => {
    //     if (res && res.data) {
    //       const blob = new Blob([new Uint8Array(res.data)], {
    //         type: res.headers["content-type"],
    //       });
    //       const blobUrl = URL.createObjectURL(blob);
    //       setImageSrc(
    //         "http://3.214.93.141:6009/api/v1.0/dashboard/live-stream"
    //       );
    //     } else {
    //       console.error("No data received from the stream.");
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching the camera stream:", error);
    // });
  };

  const navigate = useNavigate();
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="add-user-wrap">
          <div className="dark-head-card">
            <div className="d-c-head d-flex align-items-center justify-content-between">
              <h3 className="font-size18 text-white">
                {" "}
                <IoArrowBack
                  className="mr-r10 cursor"
                  onClick={() => navigate(-1)}
                />{" "}
                Camera{" "}
              </h3>
            </div>

            <div className="d-c-body">
              <div className="add-u-form-card"></div>
              <div className="row">
                <div className="col-sm-12 col-md-3">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Protocol Type"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="protocol"
                      error={errors?.alert_type}
                      options={protocolsList?.length ? protocolsList : []}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-3">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"URL"}
                      name="url"
                      varient="outline"
                      control={control}
                      error={errors?.url}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-12 col-md-3">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Username"}
                      name="username"
                      varient="outline"
                      control={control}
                      error={errors?.username}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-3">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInputPassword
                      label={"Password"}
                      name="password"
                      varient="outline"
                      control={control}
                      error={errors?.password}
                      fullWidth
                    />{" "}
                  </div>
                </div>

                <div className="col-sm-12 mt-2">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => reset()}
                    >
                      Reset
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
              {camera?.URL && flag ? (
                <>
                  <div>
                    <div className="border-bottom-2 d-flex align-items-center justify-content-between mt-3 p-3">
                      <h3 className="font-size18 text-white">Video Stream </h3>
                    </div>
                  </div>
                  <div>
                    <div className="" style={{ height: 600 }}>
                      {" "}
                      <iframe
                        src={camera?.URL}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
