import React from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import useElementWidth from "../../../common/Hooks/useElementWidth";

const LineChart = ({ dataSource }) => {
  const themes = useSelector((state) => state.cylenium.themes);

  const lineChartData = {
    series: [
      {
        name: "Series 1",
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
      },
      {
        name: "Series 2",
        data: [23, 34, 45, 32, 27, 41, 52, 66, 88],
      },
    ],
    options: {
      chart: {
        id: "line-chart",
      },
      xaxis: {
        categories: dataSource?.categories ? dataSource?.categories : [],
        labels: {
          style: {
            colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      stroke: {
        width: [2, 2],
        curve: "straight",
      },
      colors: ["#FF5733", "#ffc107"],
    },
    
  };
  const [divWidth, divRef, updateWidth] = useElementWidth();

  return (
    <div className="row" ref={divRef}>
      <div className="p-3">
        <Chart
          options={lineChartData.options}
          series={dataSource?.series ? dataSource?.series : []}
          type="line"
          width={divWidth-40}
          height="270"
        />
      </div>
    </div>
  );
};

export default LineChart;
