import * as type from "../types/types";

const intialState = {
  spinner: { loading: false },
  dashboard: "",
  themes:
    localStorage.getItem("themes") === "1" ||
    localStorage.getItem("themes") === "2" ||
    localStorage.getItem("themes") === "3"
      ? localStorage.getItem("themes")
      : "1",
  forgotPassword: {},
  resetPassword: {},
  userList: [],
  user: [],
  domainEndpoint: [],
  unassignedDomainDevices: [],
  deviceDetailsByID: {},
  domainDetailsByID: {},
  empModule: [],
  deviceList: [],
  allDeviceList: [],
  allDeviceListModal: [],
  myAccount: JSON?.parse(sessionStorage.getItem("profile")),
  adminDivices: [],
  securityEvents: {},
  userListModuleDevice: [],
  cot: [],
  cotActivityInfo: [],
  deviceTypes: [],
  domains: [],
  cotById: {},
  chatList: [],
  aiBlockedList: {},
  auth:
    localStorage.getItem("userRemeber") == "true"
      ? typeof localStorage.getItem("user") !== undefined
        ? JSON.parse(localStorage.getItem("user"))
        : {}
      : typeof sessionStorage.getItem("user") !== undefined
      ? JSON.parse(sessionStorage.getItem("user"))
      : {},
  organazation: [],
  organazationUsers: [],
  organazationById: [],
  profileData: {},
  deviceSetting: {},
  notificationTags: {},
  notificationUsers: [],
  notifications: [],
  notification: {},
  dashboardGraphList: [],
  iotDevices: [],
  threatAlerts: {},
  accessRules: {},
  rulesSets: {},
  ruleById: [],
  livealerts: [],
  policy: {},
  deviceScanningList: {},
  createScanReport: {},
  scanReportList: [],
  scheduleList: [],
  reportList: {},
  scanDetails: {},
  deleteModal: {
    open: false,
    message: "",
    confirm: "",
  },
  otToken: {},
  alerts: {},
  invoiceHistoryList: {},
  invoiceDetails: {},
  activePlan: {},
  alertList: {},
  reportsDeviceTypes: {},
  analytics: {},
  analyticsDetails: {},
  riskAssessment: {},
  planList: [],
  unlistedDevices: [],
  dataFlow: [],
  reportOpen: false,
  handleOpen: false,
  cotRule: {},
  authenticationAttempts: [],
  notifcationCount: {},
  camera: {},
  ruleSetError: "",
};

const cylenium = (state = intialState, action) => {
  switch (action.type) {
    case type.SET_SPINNER: {
      return {
        ...state,
        spinner: { loading: action.payload.loading },
      };
    }
    case type.CHANGE_THEME: {
      localStorage.setItem("themes", action.payload);
      // window.location.reload();
      return {
        ...state,
        themes: action.payload,
      };
    }
    case type.SIGN_IN: {
      if (action.payload?.remeber)
        localStorage.setItem("user", JSON.stringify(action.payload));
      else sessionStorage.setItem("user", JSON.stringify(action.payload));
      localStorage.setItem(
        "userRemeber",
        JSON.stringify(action.payload.remeber)
      );
      return {
        ...state,
        auth: action.payload,
      };
    }
    case type.SIGN_OUT: {
      sessionStorage.clear();
      localStorage.clear();

      window.location.href = "/";

      return {
        ...state,
        auth: {},
      };
    }
    case type.GET_DASHBOARD: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }
    case type.GET_DEVICE_TYPE_ACTION: {
      return {
        ...state,
        deviceList: action.payload,
      };
    }
    case type.GET_CAMERA_STREAM: {
      return {
        ...state,
        camera: action.payload,
      };
    }
    case type.GET_DASHBOARD_GRAPH_LIST: {
      return {
        ...state,
        dashboardGraphList: action.payload,
      };
    }
    case type.GET_LIVE_ALERTS: {
      return {
        ...state,
        livealerts: action.payload,
      };
    }
    case type.SECURITY_EVENT_GRAPH: {
      return {
        ...state,
        securityEvents: action.payload,
      };
    }
    case type.DATA_FLOW_CHART: {
      return {
        ...state,
        dataFlow: action.payload,
      };
    }
    case type.FORGOT_PASSWORD: {
      return {
        ...state,
        forgotPassword: action.payload,
      };
    }

    case type.RESET_PASSWORD: {
      return {
        ...state,
        resetPassword: action.payload,
      };
    }

    // ===================USERS ================================
    case type.GET_USERS_LIST: {
      return {
        ...state,
        userList: action.payload,
      };
    }
    case type.GET_USER_BY_ID: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case type.GET_DEVICE_DETAILS_BY_ID: {
      return {
        ...state,
        deviceDetailsByID: action.payload,
      };
    }
    case type.GET_DOMAIN_DETAILS_BY_ID: {
      return {
        ...state,
        domainDetailsByID: action.payload,
      };
    }
    case type.GET_DOMAIN_ENDPOINT_BY_ID: {
      return {
        ...state,
        domainEndpoint: action.payload,
      };
    }
    case type.GET_DEVICES_DOMAIN_LIST: {
      return {
        ...state,
        adminDivices: action.payload,
      };
    }
    case type.GET_UNSSIGNED_DOMAIN_DEVICE: {
      return {
        ...state,
        unassignedDomainDevices: action.payload,
      };
    }
    case type.GET_DEVICES_LIST: {
      return {
        ...state,
        deviceList: action.payload,
      };
    }
    case type.GET_DOMAINS_LIST: {
      return {
        ...state,
        domains: action.payload,
      };
    }
    case type.GET_AI_BLOCKED_LIST: {
      return {
        ...state,
        aiBlockedList: action.payload,
      };
    }
    case type.GET_DIVICE_USERS_LIST: {
      return {
        ...state,
        deviceUserList: action.payload,
      };
    }
    case type.GET_EMP_MODULE: {
      return {
        ...state,
        empModule: action.payload,
      };
    }
    case type.GET_DEVICES_FOR_ADD_USER: {
      return {
        ...state,
        userListModuleDevice: action.payload,
      };
    }

    // ====================== TRUST GROUP ==========================

    case type.GET_TRUST_GROUP_LIST: {
      return {
        ...state,
        cot: action.payload,
      };
    }
    case type.GET_TRUST_GROUP_UNLIST: {
      return {
        ...state,
        unlistedDevices: action.payload,
      };
    }

    case type.GET_TRUST_GROUP_ACTIVITY_LIST: {
      return {
        ...state,
        cotActivityInfo: action.payload,
      };
    }
    case type.AUTHENTICATION_ATTEMPTS: {
      return {
        ...state,
        authenticationAttempts: action.payload,
      };
    }
    case type.GET_TRUST_GROUP_BY_ID: {
      return {
        ...state,
        cotById: action.payload,
      };
    }
    case type.GET_ORAGANIZATION_COMPANY: {
      return {
        ...state,
        organazation: action.payload,
      };
    }
    case type.GET_ORAGANIZATION_COMPANY_USERS: {
      return {
        ...state,
        organazationUsers: action.payload,
      };
    }
    case type.GET_ORAGANIZATION_COMPANY_BY_ID: {
      return {
        ...state,
        organazationById: action.payload,
      };
    }
    case type.GET_DEVICE_TYPES: {
      return {
        ...state,
        deviceTypes: action.payload,
      };
    }
    // ============= profile =======
    case type.GET_PROFILE_BY_ID: {
      return {
        ...state,
        profileData: action.payload,
      };
    }
    case type.GET_PROFILE: {
      sessionStorage.setItem("profile", JSON.stringify(action.payload));
      return {
        ...state,
        myAccount: action.payload,
      };
    }
    case type.UPDATE_PROFILE_BY_ID: {
      return {
        ...state,
        profileData: action.payload,
      };
    }

    // ==============DEVICE & SETTING===========================
    case type.GET_ALL_DEVICE_LIST: {
      return {
        ...state,
        allDeviceList: action.payload,
      };
    }
    case type.GET_ALL_DEVICE_LIST_MODAL: {
      return {
        ...state,
        allDeviceListModal: action.payload,
      };
    }
    case type.GET_SETTING: {
      return {
        ...state,
        deviceSetting: action.payload,
      };
    }

    // ========================NOTIFICATIONS===================================

    case type.GET_NOTIFICATION_BY_ID: {
      return {
        ...state,
        notification: action.payload,
      };
    }
    case type.GET_NOTIFCATION_COUNT: {
      return {
        ...state,
        note: action.payload,
      };
    }
    case type.GET_NOTIFICATION_TAGS: {
      return {
        ...state,
        notificationTags: action.payload,
      };
    }
    case type.GET_NOTIFICATION_USERS: {
      return {
        ...state,
        notificationUsers: action.payload,
      };
    }
    case type.GET_NOTIFICATION_TEMPLATES: {
      return {
        ...state,
        notifications: action.payload,
      };
    }

    // ========================THREAT  POLICIES===================================

    case type.GET_THREAT_ALERTS_LIST: {
      return {
        ...state,
        threatAlerts: action.payload,
      };
    }
    case type.GET_ACCESS_RULES: {
      return {
        ...state,
        accessRules: action.payload,
      };
    }
    case type.GET_RULE_SETS_LIST: {
      return {
        ...state,
        rulesSets: action.payload,
      };
    }
    case type.GET_POLICY: {
      return {
        ...state,
        policy: action.payload,
      };
    }
    case type.GET_RULE_ID: {
      return {
        ...state,
        ruleById: action.payload,
      };
    }
    case type.GET_IOT_DEVICES_BY_DEVICE_ID: {
      return {
        ...state,
        iotDevices: action.payload,
      };
    }
    case type.DELETE_MODEL: {
      return {
        ...state,
        deleteModal: action.payload,
      };
    }
    // ========================OT ===================================

    case type.GET_OT_TOKEN: {
      return {
        ...state,
        otToken: action.payload,
      };
    }
    // ========================DEVICE SCANNING ===================================

    case type.GET_DEVICE_SCANNING_LIST: {
      return {
        ...state,
        deviceScanningList: action.payload,
      };
    }
    case type.GET_SCHEDULE_LIST: {
      return {
        ...state,
        scheduleList: action.payload,
      };
    }
    case type.GET_CREATE_SCAN_DETAILS: {
      return {
        ...state,
        createScanReport: action.payload,
      };
    }
    case type.GET_DEVICE_SCAN_LIST: {
      return {
        ...state,
        scanReportList: action.payload,
      };
    }
    case type.GET_DEVICE_SCAN_REPORT_LIST: {
      return {
        ...state,
        reportList: action.payload,
      };
    }
    case type.GET_SCAN_DETAILS_BY_SCAN_NAME: {
      return {
        ...state,
        scanDetails: action.payload,
      };
    }
    case type.GET_ALERTS_LIST: {
      return {
        ...state,
        alerts: action.payload,
      };
    }

    // ========================SUBSCRIPION===================================

    case type.GET_PLAN_LIST: {
      return {
        ...state,
        planList: action.payload,
      };
    }
    case type.GET_INVOICE_HISTORY_LIST: {
      return {
        ...state,
        invoiceHistoryList: action.payload,
      };
    }
    case type.GET_INVOICE_DETAILS: {
      return {
        ...state,
        invoiceDetails: action.payload,
      };
    }
    case type.GET_ACTIVE_PLAN: {
      return {
        ...state,
        activePlan: action.payload,
      };
    }
    case type.GET_REPORT_ALERTS: {
      return {
        ...state,
        alertList: action.payload,
      };
    }
    case type.GET_DEVICE_TYPES_REPORTS: {
      return {
        ...state,
        reportsDeviceTypes: action.payload,
      };
    }
    case type.GET_ANALYTICS_DATA: {
      return {
        ...state,
        analytics: action.payload,
      };
    }
    case type.GET_ANALYTICS_DETAILS: {
      return {
        ...state,
        analyticsDetails: action.payload,
      };
    }
    case type.GET_TRUST_GROUP_RULE: {
      return {
        ...state,
        cotRule: action.payload,
      };
    }
    case type.RISK_ASSESSMENT_GRAPH: {
      return {
        ...state,
        riskAssessment: action.payload,
      };
    }
    case type.REPORT_OPEN: {
      return {
        ...state,
        reportOpen: !state.reportOpen,
      };
    }
    case type.HANDLE_SIDEBAR_OPEN_ADMIN: {
      return {
        ...state,
        handleOpen: !state.handleOpen,
      };
    }
    case type.GET_CHAT_LIST: {
      return {
        ...state,
        chatList: action.payload,
      };
    }
    case type.RULE_SET_ERROR: {
      return {
        ...state,
        ruleSetError: action.payload,
      };
    }
    case type.CONFIRM_DELETE: {
      return {
        ...state,
        deleteModal: {
          open: action.payload?.open ? true : false,
          message: action.payload?.message ? action.payload?.message : "delete",
          module: action.payload?.module ? action.payload?.module : "",
          value: action.payload.value ? action.payload.value : "",
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default cylenium;
