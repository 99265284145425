import React, { useEffect, useLayoutEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const LineCharts = ({ series, categories, subTitle }) => {
  const [options, setOptions] = useState({
    chart: {
      id: "basic-line",
    },
    yaxis: {
      title: {
        text: subTitle ? subTitle : undefined,
        style: {
          color: 1 != 3 ? "#ffffff" : "", // Set the label color to white
        },
      },
      labels: {
        style: {
          colors: 1 != 3 ? "#ffffff" : "", // Set the label color to white
        },
      },
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: 1 != 3 ? "#ffffff" : "", // Set the label color to white
        },
      },
    },
    stroke: {
      width: [2, 2],
      curve: "straight",
    },
    colors: ["#FF5733", "#ffc107"],
  });

  useLayoutEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);
  useEffect(() => {
    if (categories?.length) {
      setOptions({
        options,
        xaxis: { categories: categories },
      });
    }
  }, [series, categories]);
  console.log("timeline chart", categories);
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series ? series : []}
        type="line"
        height={280}
      />
    </div>
  );
};

export default LineCharts;
