import { AiOutlineDelete } from "react-icons/ai";
import { BsEye } from "react-icons/bs";
import { IconButton, Tooltip } from "@mui/material";

export const userList = (props) => [
  {
    title: "First Name",
    dataIndex: "first_name",
    sorter: true,
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    render: (data) => {
      return data === "" ? "-" : data;
    },
  },
  {
    title: "Email Address",
    dataIndex: "email_address",
  },
  {
    title: "Last Login",
    dataIndex: "lastLogin",
  },
  {
    title: "Created",
    dataIndex: "created_date",
  },
  {
    title: "Job Title",
    dataIndex: "job_title",
    render: (data) => {
      return data === "" ? "-" : data;
    },
  },
  {
    title: "Devices",
    dataIndex: "devices",
  },
  {
    title: "Role",
    dataIndex: "role",
    filterMultiple: false, // Disable multiple filters for this column
    filters: [
      {
        text: "Guest",
        value: "1",
      },
      {
        text: "Security Analyst",
        value: "2",
      },
      {
        text: "Customer Admin",
        value: "3",
      },
      {
        text: "IT Administrator",
        value: "4",
      },
      {
        text: "Security Operator",
        value: "5",
      },
    ],
  },
  {
    title: "Status",
    dataIndex: "is_active",
    render: (data) => {
      return (
        <div>
          {data === 1 ? (
            <li>
              <span className="dot bg-green"></span>
              <span className="text-success">Active</span>
            </li>
          ) : (
            <li>
              <span className="dot bg-red"></span>
              <span className="text-danger">Inactive</span>
            </li>
          )}
        </div>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="View">
            <IconButton>
              <BsEye
                className="icon me-1"
                onClick={() => props.navigate(`/users/edit/${record?.user_id}`)}
              />
            </IconButton>
          </Tooltip>

          <Tooltip title="Delete">
            <IconButton
              onClick={() => props.onDelete(record?.user_id)}
              className=" mx-2"
            >
              <AiOutlineDelete className="icon" />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  },
];
