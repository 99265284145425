import React from "react";
import QueryString from "qs";
import {
  getDeviceScanningListAction,
  getDeviceTypeListAction,
} from "../../redux/action/action";
import ApiHelper from "../../common/Hooks/ApiHelper";
import CustomTable from "../../common/UI/CustomTable";
import {
  DeviceDetailsTable,
  deviceScanningTable,
} from "../../common/Tables/deviceScanningTable";
import { CustomSearch } from "../../common/UI/CustomSearch";
import { useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
const DeviceDetails = () => {
  const params = useParams();
  const getRecords = () => {
    const payload = {
      deviceType: params?.id,
    };
    dispatch(getDeviceTypeListAction(QueryString.stringify(payload)));
  };

  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.deviceList, //this is getting data from redux  store  and return into records
  });

  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigate(-1)}
            />
            Device Details - {params.id} ({records?.total_records})
          </h3>
        </div>
      </div>

      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
        </div>
        <div className="mt-3">
          <CustomTable
            records={records}
            columns={DeviceDetailsTable({ navigate, deviceType: params.id })} //we  are passing this to column to antd column which in separate file
            param={param}
            setParam={setParam}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceDetails;
