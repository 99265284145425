import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { Controller } from "react-hook-form";
import { TimePicker } from "@mui/x-date-pickers";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.extend(timezone);
export default function CustomTimePicker({ name, control, ...rest }) {
  const [value, setValue] = React.useState(dayjs());

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      render={({ field }) => (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          style={{ width: "100%" }}
        >
          <TimePicker
            {...field}
            {...rest}
            // onChange={(event) => console.log(event.target.value)}
            fullWidth
            sx={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      )}
    />
  );
}
