import { yupResolver } from "@hookform/resolvers/yup";
import { IconButton } from "@mui/material";
import { Button, Modal } from "antd";
import React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoIosArrowForward } from "react-icons/io";
import * as Yup from "yup";
import CustomInputPassword from "../../../common/CustomInputPassword";

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),

  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});
const ChangePasswordModal = ({ control, errors }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="info-black-card mr-b20 mt-2">
      <div className="d-flex justify-content-between">
        <div className="mt-2">
          <h4 className="font-size12 text-white Inter-Regular">
            Change Password
          </h4>
        </div>
        <div>
          <IconButton>
            <IoIosArrowForward onClick={showModal} />
          </IconButton>
        </div>
      </div>
      <Modal
        title="Change Wi-Fi Password"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button className="submit-Button" onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        {/* <form onSubmit={handleSubmit(onSubmit)}> */}
        <div className="row">
          <div className="col-8 mt-3">
            <CustomInputPassword
              label={"Current Password"}
              name="wifi_password"
              varient="outline"
              control={control}
              error={errors?.wifi_password}
              fullWidth
              disabled={true}
            />{" "}
          </div>
          <div className="col-8 mt-3">
            <CustomInputPassword
              label={"New Password"}
              name="new_password"
              varient="outline"
              control={control}
              error={errors?.new_password}
              fullWidth
            />
          </div>
        </div>
        {/* </form> */}
      </Modal>
    </div>
  );
};

export default ChangePasswordModal;
