import { Button, Checkbox, Modal, Table } from "antd";
import QueryString from "qs";
import React, { useState } from "react";
import { FaDownload } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ApiHelper from "../../common/Hooks/ApiHelper";
import {
  applicationReportcolumns,
  hostReportColumns,
  operatingSystemsReportcolumns,
  portsReportColumns,
  resultReportColumns,
  scanReportColumns,
} from "../../common/Tables/deviceScanningColumns";
import { CustomSearch } from "../../common/UI/CustomSearch";
import {
  downloadDeviceScanReportAction,
  getDeviceScanReportListAction,
} from "../../redux/action/action";
import { expandedIcon } from "../../utils/expanded";
import BasicInfo from "./BasicInfo";
import ResultData from "./ResultData";

const ReportsDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [devices, setDevices] = useState([]);
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const date = params.get("date");

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleApply = () => {
    for (let device of devices) {
      if (device?.isSelected) {
        downloadReportPdf(device?.task_id);
      }
    }

    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const themes = useSelector((state) => state.cylenium.themes);

  const nestedRecord = (record) => {
    console.log("record", record);
    return (
      <div>
        <BasicInfo
          record={record.basicInfo?.length ? record.basicInfo[0] : { id: true }}
        />
        <ResultData
          records={record.resultData?.length ? record.resultData : []}
          columns={resultReportColumns({})}
          label={"Result"}
        />
        <ResultData
          records={record.hostData?.length ? record.hostData : []}
          columns={hostReportColumns({})}
          label={"Hosts"}
        />
        <ResultData
          records={record.portData?.length ? record.portData : []}
          columns={portsReportColumns({})}
          label={"Ports"}
        />
        <ResultData
          records={
            record.applicationReportData?.length
              ? record.applicationReportData
              : []
          }
          columns={applicationReportcolumns({})}
          label={"Application"}
        />
        <ResultData
          records={
            record.operatingSystems?.length ? record.operatingSystems : []
          }
          columns={operatingSystemsReportcolumns({})}
          label={"Operating System"}
        />
      </div>
    );
  };

  const onCheckBoxChange = (event) => {
    // console.log(event);
    setDevices((prevRec) =>
      prevRec.map((current) =>
        current.id === event.target.id
          ? { ...current, isSelected: event.target.checked }
          : current
      )
    );
  };
  const selectedAllGraphs = (checked) => {
    setDevices((prevRec) =>
      prevRec.map((current) =>
        true ? { ...current, isSelected: checked } : current
      )
    );
  };
  const { id } = useParams();
  const getRecords = () => {
    const payload = {
      search: param?.search?.length ? param?.search : undefined,
      scan_name: id,
      scan_date: date,
    };
    dispatch(getDeviceScanReportListAction(QueryString.stringify(payload)));
  };

  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.scanReportList, //this is getting data from redux  store  and return into records
  });
  const downloadReportPdf = (task_id) => {
    dispatch(downloadDeviceScanReportAction(task_id));
  };

  const downloadSelectedReport = () => {
    const tempDevicesList = records?.map((current) =>
      true ? { ...current, isSelected: true } : current
    );
    setDevices(tempDevicesList);
    setIsModalOpen(true);
  };

  return (
    <form>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate(-1)}
              />
              Reports Details{" "}
            </h3>
          </div>
          <div className="d-c-body">
            <div className="table-head d-flex align-items-center justify-content-between mr-t20 table-head-custom">
              <div>
                <CustomSearch param={param} setParam={setParam} />
              </div>{" "}
              <div>
                <button
                  type="button"
                  className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                  onClick={downloadSelectedReport}
                >
                  All Reports <FaDownload className="m-1" />
                </button>
              </div>
            </div>
            <div className="add-u-form-card">
              <Table
                dataSource={records}
                columns={scanReportColumns({ downloadReportPdf })}
                className="custom-table-1"
                expandable={{
                  expandedRowRender: nestedRecord,
                  expandIcon: expandedIcon,
                }}
                scroll={{ x: 800 }}
                pagination={true}
                rowKey={"id"}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Devices"
        open={isModalOpen}
        className={
          themes == 1
            ? "dark-theme"
            : themes == 2
            ? "blue-theme"
            : "white-theme"
        }
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button className="submit-Button" onClick={handleApply}>
            Download
          </Button>,
        ]}
      >
        <div className="h-80">
          <div>
            <Checkbox
              checked={devices.every((record) => record.isSelected)}
              // id={record.id}
              onChange={(event) => selectedAllGraphs(event.target.checked)}
            >
              <div className="item-active">{"All Devices"}</div>
            </Checkbox>
          </div>
          {devices &&
            devices.map((record) => (
              <div key={record.id} className="mt-3">
                <Checkbox
                  checked={record?.isSelected}
                  id={record.id}
                  onChange={onCheckBoxChange}
                >
                  <div className="item-active">{record.name}</div>
                </Checkbox>
              </div>
            ))}
        </div>
      </Modal>
    </form>
  );
};

export default ReportsDetails;
