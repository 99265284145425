import React, { useState } from "react";

import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useRef } from "react";
import config from "../../config/config";

const CustomMapTakeData = ({ search, setValue }) => {
  const [zoom, setZoom] = useState(2);
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleMapApiKey,
    libraries: ["visualization"],
  });

  const mapRef = useRef(null);

  const handleMapLoad = (map) => {
    mapRef.current = map;
  };

  const handleZoomChange = () => {
    if (mapRef.current) {
      const zoomLevel = mapRef.current.getZoom();
      console.log("Current Zoom Level:", zoomLevel);
      setZoom(zoomLevel);
    }
  };
  const handleSearch = () => {
    // Implement the logic to search for the location using the Geocoding API or any other method
    // Once you have the latitude and longitude, update the map center
    // For example, using the Google Maps Geocoding API:
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: search }, (results, status) => {
      if (status === "OK") {
        const { lat, lng } = results[0].geometry.location;
        setMapCenter({ lat: lat(), lng: lng() });
        setValue("latitude", lat());
        setValue("longitude", lng());
        console.log(results);
        setZoom(10);
      }
    });
  };
  const handleMapClick = (event) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: event.latLng }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const locationName = results[0].formatted_address;
          setValue("location", locationName);
        } else {
          console.log("No results found");
        }
      } else {
        console.log("Geocoder failed due to: ", status);
      }
    });
  };

  useEffect(() => {
    if (search?.length) handleSearch();
  }, [search]);

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          center={mapCenter}
          zoom={zoom}
          mapContainerStyle={{
            width: "100%",
            height: "350px",
            borderRadius: 10,
          }}
          onZoomChanged={handleZoomChange}
          onLoad={handleMapLoad}
          onClick={handleMapClick}
          options={{ maxZoom: 40, minZoom: 3 }}

        >
          {mapCenter.lat == 0 && mapCenter.lng == 0 ? null : (
            <Marker position={mapCenter} />
          )}
        </GoogleMap>
      ) : null}
    </div>
  );
};

export default CustomMapTakeData;
