import { IconButton, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { CiSearch } from "react-icons/ci";

const SearchBox = ({ param, setParam, ...rest }) => {
  const [show, setShow] = useState(false);

  return (
    <div className="mt-2">
      <TextField
        {...rest}
        type={"text"}
        value={param.search}
        variant="outlined"
        className="text-white"
        size="medium"
        onChange={(event) => setParam({ ...param, search: event.target.value })}
        // autoComplete={"false"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {param.search?.length ? (
                <IconButton
                  onClick={() => {
                    setParam({ ...param, search: "" });
                  }}
                >
                  <MdOutlineClose color="white" />
                </IconButton>
              ) : (
                <IconButton>
                  <CiSearch color="white" />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
};

export default SearchBox;
