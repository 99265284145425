import moment from "moment";
import React from "react";
import { IoMdClose } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import { TiTick } from "react-icons/ti";
import { useParams } from "react-router-dom";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { getSubscriptionInvoiceHistoryByIdAction } from "../../redux/action/action";

const InvoiceDetails = () => {
  const { id } = useParams();
  const getRecords = () => {
    dispatch(getSubscriptionInvoiceHistoryByIdAction(id));
  };

  const { records, dispatch, navigate } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.invoiceDetails, //this is getting data from redux  store  and return into records
  });
  return (
    <div className="add-user-wrap">
      <div className="dark-head-card">
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigate(-1)}
            />
            Invoice Details{" "}
          </h3>
        </div>
        <div className="d-c-head row ">
          <div className="col-sm-3 mt-3">
            <div>
              <span className="heading-label">Invoice ID</span>
            </div>
            <div>
              <span className="text-white">{records?.id}</span>
            </div>
          </div>
          <div className="col-sm-3 mt-3">
            <div>
              <span className="heading-label">Plan Name</span>
            </div>
            <div>
              <span className="text-white">{records?.name}</span>
            </div>
          </div>
          <div className="col-sm-3 mt-3">
            <div>
              <span className="heading-label mb-3">Invoice Details</span>
            </div>
            <div>
              <span className="text-white">
                {moment(records?.invoiceDate).format("MMM DD YYYY")}
              </span>
            </div>
          </div>
          <div className="col-sm-3 mt-3">
            <div>
              <span className="heading-label mb-3">Payment Gateway</span>
            </div>
            <div>
              <span className="text-white">{records?.paymentGateway}</span>
            </div>
          </div>
          <div className="col-sm-3 mt-3">
            <div>
              <span className="heading-label mb-3">Bill Amount</span>
            </div>
            <div>
              <span className="text-white">{"$ " + records?.price}</span>
            </div>
          </div>
          <div className="col-sm-3 mt-3">
            <div>
              <span className="heading-label mb-3">Status</span>
            </div>
            <div>
              <span className="c-green f-700">Paid</span>
            </div>
          </div>
          <div className="col-sm-3  mt-3">
            <div>
              <span className="heading-label">Plan Start Date</span>
            </div>
            <div>
              <span className="text-white">
                {moment(records?.startDate).format("MMM DD YYYY")}
              </span>
            </div>
          </div>
          <div className="col-sm-3  mt-3">
            <div>
              <span className="heading-label mt-3">Plan End Date</span>
            </div>
            <div>
              <span className="text-white">
                {moment(records?.endDate).format("MMM DD YYYY")}
              </span>
            </div>
          </div>
        </div>
        <hr className="mt-3 mb-3" />
        <div className="p-3 ">
          <div>
            <span className="heading-label">Feature Breakdown</span>
          </div>
          <div className="">
            <div className="row">
              {records?.featureList?.map((feature) => (
                <div
                  className="col-sm-12 col-md-4 row justify-content-between feature "
                  key={feature.name}
                >
                  <div className="col-10 p-3 border-bottom-gray">
                    <span className="text-white">{feature.name}</span>
                  </div>
                  <div className="col-2 p-3 border-bottom-gray">
                    {feature?.flag ? (
                      <TiTick color="green" fontSize={16} className="mx-2" />
                    ) : (
                      <IoMdClose color="red" fontSize={16} className="mx-2" />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
