import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import {
  HighchartsChart,
  Chart,
  PieSeries,
  Tooltip,
  withHighcharts,
  Legend,
} from "react-jsx-highcharts";
import { chartColor } from "../../../utils/colors";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import QueryString from "qs";
import moment from "moment";
import { Empty } from "antd";
const numberFormatter = (value) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "");
};

const PieChart = ({ graphData, filter, url, startDate, endDate }) => {
  const [data, setData] = useState([]);
  const themes = useSelector((state) => state.cylenium.themes);

  useEffect(() => {
    if (graphData?.labels) {
      const tempData = [];
      graphData.labels.forEach((label, index) => {
        tempData.push({
          name: label,
          y: graphData.series[index],
          color: chartColor[index],
          sliced: false, // Automatically highlight the first segment
        });
      });
      setData(tempData);
    }
  }, [graphData]);

  const handleLegendItemClick = function (e) {
    // Prevent hiding if it's the last visible one
    const visibleCount = this.series.data.reduce(
      (acc, point) => acc + (point.visible ? 1 : 0),
      0
    );
    if (visibleCount < 2 && this.visible) {
      e.preventDefault();
    }
  };
  const navigate = useNavigate();
  const handleClick = (event) => {
    const payload = QueryString.stringify({
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    });
    // Customize the path based on the clicked segment's name or other properties
    const { name } = event.point;
    if (url == "alerts")
      navigate(`/reports/alerts?${filter}=${name}&${payload}`);
    if (url == "analytics")
      navigate(`/reports/details?${filter}=${name}&${payload}`);
  };
  return (
    <div>
      {graphData?.series?.length ? (
        <HighchartsChart>
          <Chart
            backgroundColor="none"
            height="300px"
            style={{ fontSize: "14px", fontWeight: 600, color: "#7b7c87" }}
          />
          <Legend align="center" verticalAlign="bottom" layout="horizontal" />
          <PieSeries
            data={data}
            showInLegend
            fillOpacity={0.1}
            innerSize="70%"
            dataLabels={{
              enabled: true,
              format:
                "<b>{point.name}</b>: {point.y} ({point.percentage:.1f}%)",
              style: { color: themes == 3 ? "black" : "white" },
            }}
            className="cursor"
            point={{
              events: {
                legendItemClick: handleLegendItemClick,
                click: handleClick, // Add click event to points
                className: "cursor",
              },
            }}
          />
          <Tooltip
            pointFormat="Count: <b>{point.y:,.0f}</b>"
            formatter={function () {
              return `Count: <b>${numberFormatter(this.point.y)}</b>`;
            }}
          />
        </HighchartsChart>
      ) : (
        <Empty description="No data available for the selected date range." />
      )}
    </div>
  );
};

export default withHighcharts(PieChart, Highcharts);
