import { yupResolver } from "@hookform/resolvers/yup";
import { Checkbox, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CustomDropdown from "../../common/CustomDropdown";
import CustomInput from "../../common/CustomInput";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { ThreatHuntingContext } from "../../context/ThreatHuntingContext";
import { spinnerStartStop } from "../../redux/action/action";
import { addTestSchema } from "../../utils/validations/testValidation";
import "./ThreatHunting.css";

const AddEditTest = () => {
  const navigateLink = useNavigate();
  const location = useLocation();

  const {
    getMsfTestList,
    configureTestDeviceData,
    setConfigureTestDeviceData,
    setTestListGlobal,
    testListGlobal,
  } = useContext(ThreatHuntingContext);
  const [testType, setTestType] = useState("Auxiliary");

  const [data, setData] = useState({});
  const [applyToAll, setApplyToAll] = useState(false);
  const [allDevicesList, setAllDevicesList] = useState([]);
  const [testList, setTestList] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [preSelectedEndpoint, setPreSelectedEndpoint] = useState(null);

  useEffect(() => {
    if (
      !location?.state ||
      !location?.state?.endpointToAddTest ||
      // !location?.state?.endpointToAddTest?.id ||
      !configureTestDeviceData.length
    ) {
      navigateLink("/threat_hunting/configure_vulnerability");
    } else {
      let endpoint = location?.state?.endpointToAddTest;
      let deviceTitle = location?.state?.deviceName;
      let allDevices = location?.state?.allDevices;
      setAllDevicesList(allDevices);
      setDeviceName(deviceTitle);
      setPreSelectedEndpoint(endpoint);
    }
  }, [location]);
  //getting data from api and store
  const getRecords = async () => {
    dispatch(spinnerStartStop(true));
    setData(testListGlobal);
    dispatch(spinnerStartStop(false));
  };
  useEffect(() => {
    let list = [];
    let filteredData = [];
    filteredData = data?.records?.filter((val) => val.category === testType);
    filteredData?.map((test) => {
      list.push({ value: test.id, label: test.name });
    });
    setTestList(list);
  }, [data, testType]);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton:
        "blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10",
      title: "font-size18",
      htmlContainer: "threat-hunting-alert-content center-align",
    },
    buttonsStyling: false,
  });
  const onSubmit = (submitedData) => {
    const selectedTest = data?.records?.find(
      (test) => test.id == submitedData.test
    );
    // check if selected test already added in end point or not
    if (
      preSelectedEndpoint.selectedTests.find(
        (alreadyAddedTest) => alreadyAddedTest.testId == selectedTest.id
      )
    ) {
      swalWithBootstrapButtons
        .fire({
          title: "Error",
          html: "Selected Test already added in endpoint please select another test",
          icon: "error",
          confirmButtonColor: "#D8EAFF",
          confirmButtonText: "Okay",
          confirmButtonTextColor: "#ffffff",
        })
        .then((result) => {
          if (result.isConfirmed) {
            return false;
          }
        });
      return false;
    } else {
      preSelectedEndpoint.selectedTests = [
        ...preSelectedEndpoint.selectedTests,
        {
          testId: submitedData.test,
          test_name: selectedTest ? selectedTest?.name : "Sample Test",
          payload:
            submitedData.test == 2
              ? {
                  rHost: preSelectedEndpoint.ip_address,
                  rPort: submitedData.port,
                }
              : { rHost: preSelectedEndpoint.ip_address },
        },
      ];
    }
    setPreSelectedEndpoint(preSelectedEndpoint);
    configureTestDeviceData.map((device) => {
      let finalEndpoints = [];
      device.connectedDevices.map((endpoint) => {
        // if (endpoint.id === preSelectedEndpoint.id) {
        if (endpoint.key === preSelectedEndpoint.key) {
          endpoint.selectedTests = preSelectedEndpoint.selectedTests;
        }
        finalEndpoints.push(endpoint);
      });

      device.connectedDevices = finalEndpoints;
    });
    setConfigureTestDeviceData(configureTestDeviceData);
    navigateLink("/threat_hunting/configure_vulnerability");
  };

  const { records, param, setParam, dispatch } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => data, //this is getting data from redux  store  and return into records
  });
  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addTestSchema),
    defaultValues: { port: 445 },
  });
  let testChange = watch("test");

  return (
    <div className="add-user-wrap">
      <div className="dark-head-card">
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() =>
                navigateLink("/threat_hunting/configure_vulnerability")
              }
            />
            Add New Test
          </h3>
        </div>
        <div className="d-c-head row align-items-center gap10">
          <div className="col-sm-12 col-md-3 ">
            <span className="addEditTestLabel">Device</span>
            <p className="addEditTestDesc text-wrap">{deviceName}</p>
          </div>
          <div className="col-sm-12 col-md-3">
            <span className="addEditTestLabel">Endpoint</span>
            <p className="addEditTestDesc">{preSelectedEndpoint?.name}</p>
          </div>
          <div className="col-sm-12 col-md-3">
            <span className="addEditTestLabel">IP Address</span>
            <p className="addEditTestDesc">{preSelectedEndpoint?.ip_address}</p>
          </div>
          <div className="col-sm-12 col-md-3">
            <span className="addEditTestLabel">Status</span>
            {preSelectedEndpoint?.isConnected ? (
              <p className="addEditTestDesc text-success">Connected</p>
            ) : (
              <p className="addEditTestDesc text-danger">Disconnected</p>
            )}
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-4">
                      <fieldset className="threat-hunting-fieldset">
                        <legend className="threat-hunting-legend">
                          <span>Test Type</span>
                        </legend>
                        <RadioGroup
                          onChange={(e) => setTestType(e.target.value)}
                          value={testType}
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          className="mx-2"
                        >
                          <FormControlLabel
                            value="Auxiliary"
                            control={<Radio />}
                            label="Auxiliary"
                            className="text-white"
                          />
                          <FormControlLabel
                            value="Exploit"
                            control={<Radio />}
                            label="Exploit"
                            className="text-white"
                          />
                        </RadioGroup>
                      </fieldset>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomDropdown
                          // onChange={(e) => console.log(e.target.value)}
                          label={"Test Name"}
                          control={control}
                          varient="outline"
                          defaultValue=""
                          name="test"
                          options={testList}
                          error={errors?.test}
                          fullWidth
                        />
                      </div>
                    </div>
                    {/* <div className="col-sm-4">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomDropdown
                          label={"Tests"}
                          control={control}
                          varient="outline"
                          defaultValue=""
                          name="test_name"
                          options={testList}
                          fullWidth
                          searchvariant
                        />
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="col-sm-4">
                  {testChange === 2 && (
                    <CustomInput
                      type="number"
                      label={"Port"}
                      varient="outline"
                      control={control}
                      error={errors?.port}
                      name="port"
                      defaultValue={445}
                      fullWidth
                    />
                  )}
                </div>
                <div className="col-sm-12 mr-t30 ">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        className="addEditTestCheckbox"
                        checked={applyToAll}
                        onClick={(e) => setApplyToAll(!applyToAll)}
                      />
                    }
                    className="colorLabel"
                    label="Apply above configurations to all devices"
                  />

                  <div className="float-right">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() =>
                        navigateLink("/threat_hunting/configure_vulnerability")
                      }
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEditTest;
