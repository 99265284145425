/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "../../../common/CustomDropdown";
import CustomInput from "../../../common/CustomInput";
import CustomMultiSelectUsersForNotification from "../../../common/CustomMultiSelectUsersForNotification";
import SaveAndCancelButton from "../../../common/UI/SaveAndCancelButton";
import {
  addNotificationAction,
  getNotificationTagsActions,
  getNotificationUsersActions,
} from "../../../redux/action/action";
import { addNotificationSchema } from "../../../utils/validations/notificationSchema";
import NotificationForm from "./NotificationForm";
const AddNotification = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationTags = useSelector(
    (state) => state.cylenium.notificationTags
  );
  const notificationUsers = useSelector(
    (state) => state.cylenium.notificationUsers
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(addNotificationSchema),
    defaultValues: { users: [] },
  });

  const onSubmit = (data) => {
    const payload = {
      name: data.name,
      sender: data.sender,
      subject: data.sender,
      users: data.users,
      tag_id: data.notification_tag,
    };
    dispatch(addNotificationAction(payload));
  };
  useEffect(() => {
    dispatch(getNotificationTagsActions());
    dispatch(getNotificationUsersActions());
  }, []);

  useEffect(() => {
    if (Boolean(notificationTags)) {
      reset(notificationTags);
    }
  }, [notificationTags]);

  useEffect(() => {
    if (watch("notification_tag")) {
      if (notificationTags?.notificationTag?.length) {
        const temp = notificationTags?.notificationTag?.filter(
          (record) => record.value == watch("notification_tag")
        );
        setValue("subject", temp[0]?.subject);
        setValue("template", temp[0]?.body);
      }
    }
  }, [watch("notification_tag")]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/notification")}
              />
              Add New Template{" "}
            </h3>
          </div>
          <NotificationForm
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            notificationTags={notificationTags}
            notificationUsers={notificationUsers}
            navigate={navigate}
            param={{ disable: true }}
          />
        </div>
      </div>
    </form>
  );
};

export default AddNotification;
