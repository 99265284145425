import React, { useState } from 'react';
import { del, get, post } from '../services/axiosInstance';

export const ThreatHuntingContext = React.createContext();

export const ThreatHuntingContextProvider = ({ children }) => {
  const [newBatchName, setNewBatchName] = useState('');
  const [scheduleOption, setScheduleOption] = useState(null);
  const [configureTestDeviceData, setConfigureTestDeviceData] = useState([]);
  const [editBatch, setEditBatch] = useState(null);
  const [testListGlobal, setTestListGlobal] = useState([]);
  const [externalEndpointsConfigureList, setExternalEndpointsConfigureList] =
    useState([]);

  const getBatchList = (payload) => {
    return get('/threadHunting/batch/getBatchList?' + payload);
  };
  const getDeviceAndEndpointList = (payload) => {
    return get('/threadHunting/batch/getDeviceAndEndpointList?' + payload);
  };
  const getMsfTestList = (payload) => {
    return get('/threadHunting/batch/msfTestList?' + payload);
  };
  const createBatch = (payload) => {
    return post('/threadHunting/batch/createBatch', payload);
  };
  const getBatchResults = (id, payload) => {
    return get('/threadHunting/batch/batchResults/' + id + '?' + payload);
  };
  const getScheduleList = (payload) => {
    return get('/threadHunting/batch/getScheduleList?' + payload);
  };
  const addSchedule = (payload) => {
    return post('/threadHunting/batch/addSchedule', payload);
  };
  const addEndPoints = (payload) => {
    return post('/threadHunting/batch/addEndPoints', payload);
  };
  const deleteBatch = (id) => {
    return del('/threadHunting/batch/deleteBatch/' + id);
  };
  const getSingleBatch = (id) => {
    return get('/threadHunting/batch/getSingleBatch/' + id);
  };
  const updateBatch = (payload) => {
    return post('/threadHunting/batch/updateBatch', payload);
  };
  return (
    <ThreatHuntingContext.Provider
      value={{
        createBatch,
        testListGlobal,
        setTestListGlobal,
        updateBatch,
        getBatchList,
        getDeviceAndEndpointList,
        getMsfTestList,
        configureTestDeviceData,
        setConfigureTestDeviceData,
        newBatchName,
        setNewBatchName,
        getScheduleList,
        getBatchResults,
        scheduleOption,
        addSchedule,
        setScheduleOption,
        externalEndpointsConfigureList,
        setExternalEndpointsConfigureList,
        addEndPoints,
        deleteBatch,
        getSingleBatch,
        editBatch,
        setEditBatch,
      }}
    >
      {children}
    </ThreatHuntingContext.Provider>
  );
};
