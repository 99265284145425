import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import { Controller } from "react-hook-form";
import { FormHelperText, TextField } from "@mui/material"; // Import TextField for displaying error message

export default function CustomDatePickerWithoutValue({
  name,
  control,
  label,
  error, // Explicitly receiving the error prop
  ...rest // This will include disableFuture, fullWidth, and any other props
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            {...field}
            disableFuture={rest?.disableFuture ? rest.disableFuture : false}
            inputFormat="MM/DD/YYYY"
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth={rest.fullWidth}
                variant={rest.variant} // Using variant from rest props, corrected spelling
              />
            )}

            // {...rest} // Apply the rest of the props to DatePicker
          />
          <FormHelperText className="text-danger">
            {error?.message ? error?.message : ""}
          </FormHelperText>
        </LocalizationProvider>
      )}
    />
  );
}
