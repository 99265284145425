/* eslint-disable react-hooks/exhaustive-deps */
import Search from "antd/es/transfer/search";
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { trustedGroupColumn } from "../../../common/Tables/trustedGroupColumn";
import CustomTable from "../../../common/UI/CustomTable";
import {
  deleteTrustGroupAction,
  getTrustGroupListAction,
} from "../../../redux/action/action";
const TrustedGroupTable = () => {
  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cotList = useSelector((state) => state.cylenium.cot);

  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getTrustGroupListAction(QueryString.stringify(payload)));
  };

  const onDelete = (id) => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(deleteTrustGroupAction(id, QueryString.stringify(payload)));
  };
  useEffect(() => {
    getRecords();
  }, [param]);
  return (
    <div className="user-wrap">
      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <div className="t-filter-search">
            <Search
              onChange={(event) =>
                setParam({ ...param, search: event.target.value })
              }
              placeholder="Search"
            />
          </div>
          {/* <FilterBlock /> */}
        </div>
        <div className="mt-3">
          <div className="">
            <CustomTable
              records={cotList}
              columns={trustedGroupColumn({ navigate, onDelete })} //we  are passing this to column to antd column which in separate file
              param={param}
              setParam={setParam}
              className="custom-table-1"
            />
          </div>
          {/* <div class="tls-msg-card">
            <div class="cus-c-btn d-flex align-items-center">
              <label for="d10">
                <input checked type="checkbox" name="al-btn" id="d10" />
                <span></span>
              </label>
            </div>
            <div class="tls-msg">
              <h5 class="font-size12 text-white Inter-Bold mr-b10">
                Use TLS Encryption
              </h5>
              <p class="font-size12 text-white opacity04 Inter-Regular">
                If checked, another layer of encryption will be added for
                data/file sharing with a self-signed TLS certificate
              </p>
              <p class="font-size12 color-red  Inter-Regular">
                **Caution: This may reduce the data transfer speeds**
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default TrustedGroupTable;
