import Highcharts from "highcharts";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  AreaSeries,
  Chart,
  HighchartsChart,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  withHighcharts,
} from "react-jsx-highcharts";
import { useSelector } from "react-redux";
import DownloadCsvIcon from "../../../../common/DownloadCsvIcon";
import { getSecurityEventsApi } from "../../../../services/api";
import { chartColor } from "../../../../utils/colors";
import { useParams } from "react-router-dom";

const SecurityEventsChart = () => {
  const [data, setData] = useState([]);
  const themes = useSelector((state) => state.cylenium.themes);
  const arr = [{ name: "kl", valeu: 1 }];
  const params = useParams(); //group id

  useEffect(() => {
    Highcharts.setOptions({ global: { useUTC: false } });
    const fetchAndUpdateData = async () => {
      try {
        const response = await getSecurityEventsApi(params.id);
        const newData = response?.data?.map((item, index) => ({
          id: 1 * 100 + index,
          name: item.metrics?.job,
           
          data: item.values[0].map((time, idx) => [
            time * 1000,
            parseFloat(item.values[1][idx]),
            item.values[2][idx],
          ]),
        }));
        setData(newData);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchAndUpdateData();
    const intervalId = setInterval(fetchAndUpdateData, 10000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <DownloadCsvIcon data={data} name={"SecurityEventsChartReport"} />
      <HighchartsChart>
        <Chart
          backgroundColor="none"
          height="280px"
          style={{ fontSize: "14px", fontWeight: 600, color: "#7b7c87" }}
        />
        <Legend
          enabled={true}
          layout="horizontal"
          align="center"
          verticalAlign="bottom"
          alignColumns={"center"}
          maxHeight={100}
          navigation={{
            activeColor: "#3E576F",
            inactiveColor: "#CCC",
            arrowSize: 12,
            style: {
              fontWeight: "bold",
              color: "#333",
              fontSize: "12px",
            },
          }}
          style={{ height: 30 }}
        />
        <Tooltip
          backgroundColor="#2a2e31"
          style={{ color: "#cccccc", fontSize: "12px" }}
          useHTML
          formatter={function () {
            let infoHtml = this.point?.series.options?.data
              ?.at(this.point.index)
              .at(2)
              .map(
                (item, index) =>
                  `<div class="text1"><div><span style="color:${chartColor[index]}">●</span>  ${item.name} </div> <div > ${item.value} </div></div>`
              )
              .join("<br>");
            return `<b class="fs-12">${moment(this.x).format(
              "MM-DD-YYYY HH:mm:ss"
            )}</b><br>
            <div class="text1"><div>   <span style="color:${
              this.point.color
            }">●</span> ${this.point.series.name} </div> <div> ${
              this.y
            } </div> </div></br>
                    <b class="chart-details">Details</b><br>${
                      infoHtml ? infoHtml : "No additional data available."
                    }`;
          }}
        />
        <XAxis type="datetime" crosshair />
        <YAxis
          crosshair
          labels={{
            formatter: function () {
              return `${this.value}`;
            },
          }}
        >
          <YAxis.Title style={{ color: themes !== 3 ? "white" : "black" }}>
            Events
          </YAxis.Title>
          {data.map((series) => (
            <AreaSeries
              key={series.id}
              name={series.name}
              arr={arr}
              data={series.data}
              fillOpacity={0.1}
              lineWidth={2}
              marker={{ enabled: false }}
              animation={true}
            />
          ))}
        </YAxis>
      </HighchartsChart>
    </div>
  );
};

export default withHighcharts(SecurityEventsChart, Highcharts);
