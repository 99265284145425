import React, { useState, useEffect } from "react";

const TypingEffect = ({ texts, speed, pause }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [textIndex, setTextIndex] = useState(0);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (textIndex < texts.length) {
      if (!deleting && index < texts[textIndex].length) {
        const timeout = setTimeout(() => {
          setDisplayedText(displayedText + texts[textIndex][index]);
          setIndex(index + 1);
        }, speed);
        return () => clearTimeout(timeout);
      } else if (deleting && index > 0) {
        const timeout = setTimeout(() => {
          setDisplayedText(displayedText.slice(0, -1));
          setIndex(index - 1);
        }, speed);
        return () => clearTimeout(timeout);
      } else if (!deleting && index === texts[textIndex].length) {
        const timeout = setTimeout(() => setDeleting(true), pause);
        return () => clearTimeout(timeout);
      } else if (deleting && index === 0) {
        setDeleting(false);
        setTextIndex((textIndex + 1) % texts.length);
      }
    }
  }, [index, textIndex, deleting, texts, displayedText, speed, pause]);

  return (
    <div className="typing-effect text-white text-center">
      {displayedText}
      <span className="cursor1">|</span>
    </div>
  );
};

export default TypingEffect;
