/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomDropdown from "../../../common/CustomDropdown";
import CustomInput from "../../../common/CustomInput";
import MultiSelectUsers from "../../../common/UI/MultiSelectUsers";
import {
  editNotificationAction,
  getNotificationByIdActions,
  getNotificationTagsActions,
  getNotificationUsersActions,
} from "../../../redux/action/action";
import { addNotificationSchema } from "../../../utils/validations/notificationSchema";
import NotificationForm from "./NotificationForm";
const EditNotification = () => {
  const [param, setParam] = useState({
    disable: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const notification = useSelector((state) => state.cylenium.notification);
  const notificationTags = useSelector(
    (state) => state.cylenium.notificationTags
  );
  const notificationUsers = useSelector(
    (state) => state.cylenium.notificationUsers
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(addNotificationSchema),
    defaultValues: { users: [] },
  });
  const handleEdit = (action) => {
    setParam({ ...param, disable: action });
  };
  const onSubmit = (data) => {
    const payload = {
      id: notification.id,
      name: data.name,
      sender: data.sender,
      subject: data.sender,
      users: data.users,
      tag_id: data.notification_tag,
    };
    dispatch(editNotificationAction(payload));
  };
  useEffect(() => {
    dispatch(getNotificationByIdActions(id));
    dispatch(getNotificationTagsActions());
    dispatch(getNotificationUsersActions());
  }, []);

  useEffect(() => {
    if (Boolean(notificationTags)) {
      setValue("sender", notificationTags.sender);
    }
  }, [notificationTags]);

  useEffect(() => {
    if (watch("notification_tag")) {
      if (notificationTags?.notificationTag?.length) {
        const temp = notificationTags?.notificationTag?.filter(
          (record) => record.value == watch("notification_tag")
        );
        setValue("subject", temp[0]?.subject);
        setValue("template", temp[0]?.body);
      }
    }
  }, [watch("notification_tag")]);

  useEffect(() => {
    if (Boolean(notification?.id)) {
      // reset(notification);
      setValue("name", notification.name);
      setValue("notification_tag", notification.tag.id);
      setValue("subject", notification.tag.subject);
      setValue("template", notification.tag.body);
      // setValue("template", notification?.body);
      const tempUsers = [];
      notification.users.map((data) => tempUsers.push(data.id));
      setValue("users", tempUsers);
    }
  }, [notification]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/notification")}
              />
              Edit Template{" "}
            </h3>
            {!param.disable ? (
              <button
                type="button"
                className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                onClick={() => handleEdit(true)}
              >
                Edit
              </button>
            ) : (
              <button
                type="button"
                className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                onClick={() => handleEdit(false)}
              >
                Cancel
              </button>
            )}
          </div>
          <NotificationForm
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            notificationTags={notificationTags}
            notificationUsers={notificationUsers}
            navigate={navigate}
            param={param}
          />
        </div>
      </div>
    </form>
  );
};

export default EditNotification;
