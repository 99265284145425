import React from "react";
import QueryString from "qs";
import {
  deleteScanByIdAction,
  getDeviceScanningListAction,
} from "../../redux/action/action";
import ApiHelper from "../../common/Hooks/ApiHelper";
import CustomTable from "../../common/UI/CustomTable";
import { deviceScanningTable } from "../../common/Tables/deviceScanningTable";
import { CustomSearch } from "../../common/UI/CustomSearch";
const DeviceScanningTable = () => {
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      severityFilter: param?.filters?.severity?.length
        ? param?.filters?.severity[0]
        : "all",
      statusFilter: param?.filters?.status?.length
        ? param?.filters?.status[0]
        : "all",
      sort_by: "scan_name",
      order_by: param.sorter?.order === "ascend" ? "ASC" : "DESC",
      // status: param.sorter?.status ? param?.sorter?.status : undefined,
    };
    // console.log("check", param.sorter.order);
    dispatch(getDeviceScanningListAction(QueryString.stringify(payload)));
  };

  const onDelete = (scanName) => {
    debugger
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      severityFilter: param?.filters?.severity?.length
        ? param?.filters?.severity[0]
        : "all",
      statusFilter: param?.filters?.status?.length
        ? param?.filters?.status[0]
        : "all",
      sort_by: "scan_name",
      order_by: param.sorter?.order === "ascend" ? "ASC" : "DESC",
      // status: param.sorter?.status ? param?.sorter?.status : undefined,
    };

    dispatch(deleteScanByIdAction(QueryString.stringify(payload), scanName));
  };

  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.deviceScanningList, //this is getting data from redux  store  and return into records
  });

  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Device Scanning</h3>
        </div>
        <div className="r-btn-block">
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            onClick={() => navigate("/device_scanning/add")}
          >
            Scan
          </button>
        </div>
      </div>

      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
        </div>
        <div className="mt-3">
          <CustomTable
            className="custom-table-1"
            records={records}
            columns={deviceScanningTable({ navigate, onDelete })} //we  are passing this to column to antd column which in separate file
            param={param}
            setParam={setParam}
          />
        </div>
      </div>
    </div>
  );
};

export default DeviceScanningTable;
