import React from "react";
import { Select } from "antd";

import { Controller } from "react-hook-form";
const { Option } = Select;

const MultiSelectUsers = ({ mode, error, ...rest }) => {
  const [activated, setActivated] = React.useState(true);

  return (
    <>
      {" "}
      <label
        className={`${
          activated
            ? "textfield-label-activated"
            : "textfield-label-unactivated"
        } textfield-label`}
      >
        {" "}
        {rest.label}
      </label>
      <Controller
        {...rest}
        render={({ field }) => (
          <Select
            mode={mode}
            onFocus={() => {
              setActivated(true);
            }}
            onBlur={() => {
              setActivated(Boolean(field.value));
            }}
            placeholder={rest.placeholder ? rest.placeholder : ""}
            style={{
              width: "100%",
            }}
            {...field}
          >
            {rest.options.map((record, index) => (
              <Option value={record.id} key={index}>
                {record.fill_name}
              </Option>
            ))}
          </Select>
        )}
      />
      <span className="text-danger">{error?.message && error?.message}</span>
    </>
  );
};
export default MultiSelectUsers;
