import { Checkbox } from "antd";
import React from "react";
import CustomRadioGroup from "../../../../common/UI/CustomRadioGroup";
import CustomTimePicker from "../../../../common/UI/CustomTimePicker";
const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const limit_access_options = [
  {
    value: "Rule_will_be_active_at_the_schedule_time",
    label: "Rule will be active at the schedule time",
  },
  {
    value: "Rule_will_be_inactive_at_the schedule time",
    label: "Rule will be inactive at the schedule time",
  },
];
const ScheduleTime = ({ control, selectedDays, setSelectedDays }) => {
  const onDayHandle = (event, selectedDay) => {
    if (event.target.checked) setSelectedDays([...selectedDays, selectedDay]);
    else setSelectedDays(selectedDays.filter((day) => day != selectedDay));
  };
  return (
    <div className="info-gray-card mr-b20 mt-2">
      <h4 className="font-size12 text-white Inter-Regular mr-b20">
        Create schedule{" "}
      </h4>
      <div className="row">
        <div className="col-sm-12 static-info">
          <label className="font-size12 text-white opacity04 Inter-Regular mr-b8">
            Day
          </label>
        </div>

        {days.map((record, index) => (
          <div className="col-auto mt-2" key={record}>
            {/* <div className="cus-c-btn  d-flex align-items-center mr-r20"> */}
            <Checkbox
              checked={Boolean(selectedDays.includes(record))}
              onChange={(event) => onDayHandle(event, record)}
            >
              {record}
            </Checkbox>
            {/* </div> */}
          </div>
        ))}

        <div className="col-sm-12 static-info mr-t20 mr-b30">
          <div className="static-info">
            <label className="font-size12 text-white opacity04 Inter-Regular mr-b8">
              Time{" "}
            </label>
            {/* <div className="cus-r-btn-group" id="schedule-option">
              <div className="cus-r-btn d-flex align-items-center"> */}
            <CustomRadioGroup
              name="schedule_time"
              control={control}
              options={limit_access_options}
            />
            {/* </div>
            </div> */}
          </div>
        </div>
        <div className="col-sm-3">
          <div className="keyword-input-row d-flex align-items-center">
            <div className="form-floating cus-floating-inputs flex-grow-1">
              <CustomTimePicker
                label={"Start Time"}
                name="start_time"
                control={control}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="keyword-input-row d-flex align-items-center">
            <div className="form-floating cus-floating-inputs flex-grow-1">
              <CustomTimePicker
                label={"End Time"}
                name="end_time"
                control={control}
              />
            </div>
          </div>
        </div>
        {/*   <div className="col-sm-3">
          <div className="keyword-input-row d-flex align-items-center">
            <div className="form-floating cus-floating-inputs flex-grow-1">
              <CustomDatePicker name="End Time" disablePast />
            </div>
          </div>
        </div> */}
        <div className="col-sm-6 align-self-center">
          <label className="font-size12 text-white opacity04 Inter-Regular">
            Rule will be active &amp; inactive at the schedule time
          </label>
        </div>
      </div>
    </div>
  );
};

export default ScheduleTime;
