import { Empty, Tag } from "antd";
import React, { useState } from "react";

import { Collapse, Table } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { expandedIcon } from "../../utils/expanded";
import { rulesColumns } from "../Tables/trustedGroupColumn";
import {
  deleteCotRuleSetAction,
  updateTrustGroupRuleAction,
} from "../../redux/action/action";
import { useDispatch } from "react-redux";
import { Tab } from "@mui/material";
const { Panel } = Collapse;
const nestedColumns = [
  {
    title: "Endpoint Name",
    dataIndex: "name",
  },
  {
    title: "IP Address",
    dataIndex: "ip_address",
  },

  {
    title: "Status",
    dataIndex: "isConnected",
    render: (data) => {
      return (
        <div>
          {data ? (
            <Tag color="green">Connected</Tag>
          ) : (
            <Tag color="red">Disconnected</Tag>
          )}
        </div>
      );
    },
  },
];
const TrustGroupTable = ({ records, control, watch, param }) => {
  const [open, setOpen] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const onDelete = (rule_id) => {
    dispatch(deleteCotRuleSetAction(rule_id, id));
  };
  const handlePanelChange = (key) => {
    setOpen(key.join(","));
  };
  const nestedRecord = (record) => {
    return (
      <div className="nested-table-rows">
        {record?.connectedDevices?.length ? (
          <>
            {" "}
            <div className="row">
              <div className="col-1 device-heading"></div>
              <div className="col-6 device-heading">
                <span>Endpoint Name</span>
              </div>

              <div className="col-3 device-heading">
                <span>IP Address</span>
              </div>
              <div className="col-2 device-heading">
                <span>IP Addres</span>
              </div>
            </div>
            {record?.connectedDevices?.map((endpoint) => (
              <div className="row p-3 nested-colaps" key={endpoint.id}>
                <div className="col-1 "></div>
                <div className="col-6">
                  <span>{endpoint?.name}</span>
                </div>
                <div className="col-3">
                  <span>{endpoint?.ip_address}</span>
                </div>
                <div className="col-2">
                  <span>
                    {endpoint?.isConnected ? "Connected" : "Disconnected"}
                  </span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <Empty className="nested-colaps" />
        )}
      </div>
    );
  };

  const nestedEndpointTable = (record) => {
    return (
      <div className="p-3">
        <Table
          dataSource={record?.connectedDevices ? record?.connectedDevices : []}
          columns={nestedColumns}
          pagination={{
            hideOnSinglePage: true,
          }}
        />
      </div>
    );
  };

  const colums = [
    {
      title: "Device Name",
      dataIndex: "device_name",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data, record) => {
        return (
          <div>
            {data == "Active" ? (
              <Tag color="green">{data}</Tag>
            ) : (
              <Tag color="red">{data}</Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Classification",
      dataIndex: "classification",
    },
    {
      title: "Ip Address",
      dataIndex: "nebula_ip",
    },
  ];
  const onStatusChange = (data) => {
    dispatch(updateTrustGroupRuleAction(data, id));
  };
  return (
    <div>
      <Collapse accordion onChange={handlePanelChange}>
        <Panel
          header={
            <div className="d-flex justify-content-between">
              <div className="text-grey">
                <span>Node</span>
              </div>

              {open == "1" && (
                <div className="text-end">
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    onClick={() => navigate(`${location.pathname}/addnode`)}
                  >
                    Edit Node
                  </button>
                </div>
              )}
            </div>
          }
          key="1"
        >
          <Table
            dataSource={records.nodes}
            columns={colums}
            className="custom-table-1"
            expandable={{
              expandedRowRender: nestedEndpointTable,
              expandIcon: expandedIcon,
            }}
            pagination={false}
            // scroll={{ x: 1400 }}
            rowKey={"device_id"}
            size="middle"
          />
        </Panel>
        <Panel
          header={
            <div className="d-flex justify-content-between">
              <div className="text-grey">
                <span>Rules</span>
              </div>

              {open == "2" && (
                <div className="text-end">
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    onClick={() => navigate(`${location.pathname}/addrule`)}
                  >
                    Add Rule
                  </button>
                </div>
              )}
            </div>
          }
          key="2"
        >
          <Table
            columns={rulesColumns({
              control,
              param,
              onDelete,
              id,
              navigate,
              onStatusChange,
            })}
            dataSource={records.rules}
            className="custom-table-1"
            // size="middle"
          />
        </Panel>
      </Collapse>

      {/* <Temp /> */}
    </div>
  );
};

export default TrustGroupTable;
