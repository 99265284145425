import { useState, useEffect, useRef, useLayoutEffect } from "react";

function useElementWidth() {
  const [elementWidth, setElementWidth] = useState(0);
  const [tempRef, setTempRef] = useState(0);

  const elementRef = useRef(null);
  const updateWidth = () => {
    if (elementRef.current) {
      setElementWidth(elementRef.current.offsetWidth);
    }
  };

  useLayoutEffect(() => {
    const updateWidth = () => {
      if (elementRef.current) {
        setElementWidth(elementRef.current.offsetWidth);
      }
    };

    // Check if elementRef.current exists before measuring
    if (elementRef.current) {
      updateWidth();
    }

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useEffect(() => {
    const updateWidth = () => {
      if (elementRef.current) {
        setElementWidth(elementRef.current.offsetWidth);
      }
    };

    // Check if elementRef.current exists before measuring
    if (elementRef.current) {
      updateWidth();
    }

    // Add resize event listener
    window.addEventListener("resize", updateWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [tempRef]);

  return [elementWidth, elementRef, updateWidth];
}

export default useElementWidth;
