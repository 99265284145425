import { Button, Modal } from "antd";
import React, { useState } from "react";

import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import CustomInput from "../../common/CustomInput";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ManagementTable from "./ManagementTable";
import DonutChart from "../../common/UI/DonutChart";
import ChartComponent from "../../common/UI/ChartComponent";
const tempDevice = [
  { id: 1, name: "Custom Threat Database - 2" },
  { id: 2, name: "Custom Threat Database - 4" },
  { id: 3, name: "Custom Threat Database - 51" },
];

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});
const Management = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div>
      <div className="body-r-container">
        <div className="page-wrap">
          <div className="device-wrap">
            <div className="d-c-head d-flex align-items-center justify-content-between">
              <h3 className="font-size18 text-white Inter-Bold">
                <IoArrowBack
                  className="mr-r10 cursor"
                  onClick={() => navigate(-1)}
                />
                Vulnerability Management{" "}
              </h3>
            </div>
            <div className="ds-board">
              <div className="ds-search-col">
                <div className="ds-list">
                  <div className="ds-serach-block">
                    <input type="text" placeholder="Device List" />
                    <button type="button">
                      <svg
                        width={14}
                        height={14}
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.70835 12.2503C9.76893 12.2503 12.25 9.76924 12.25 6.70866C12.25 3.64808 9.76893 1.16699 6.70835 1.16699C3.64778 1.16699 1.16669 3.64808 1.16669 6.70866C1.16669 9.76924 3.64778 12.2503 6.70835 12.2503Z"
                          stroke="white"
                          strokeOpacity="0.4"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12.8334 12.8337L11.6667 11.667"
                          stroke="white"
                          strokeOpacity="0.4"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="list-block">
                    <ul>
                      {tempDevice &&
                        tempDevice.map((record) => (
                          <li key={record.id} className="item-active">
                            {record.name}
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="ds-resuld-col">
                <div className="ds-r-container">
                  <div className="ds-r-block">
                    <div className="over-wrap">
                      <div className="b-card-row cus-scroll">
                        <h4 className="font-size16 text-white Inter-Bold">
                          Last Updated : 19 Aug 2022, 5:00 PM{" "}
                        </h4>
                        <div className="row mt-3">
                          <div className="col-sm-6  ">
                            {" "}
                            <div className="p-3 bg-black">
                              <DonutChart />
                            </div>
                          </div>
                          <div className="col-sm-6  ">
                            {" "}
                            <div className="p-3 bg-black">
                              <ChartComponent />
                            </div>
                          </div>
                        </div>
                        <ManagementTable />
                      </div>
                    </div>
                  </div>
                  {/* <DeviceListModal /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title="Wi-Fi Service"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button className="submit-Button" onClick={handleSubmit(onSubmit)}>
            Save
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-8 mt-3">
              <CustomInput
                label={"Name"}
                name="name"
                varient="outline"
                control={control}
                error={errors?.name}
                fullWidth
              />{" "}
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Management;
