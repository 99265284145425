import { Badge, Dropdown, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineDollarCircle, AiOutlineLogin } from "react-icons/ai";
import { BiColorFill } from "react-icons/bi";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { IoOptionsSharp } from "react-icons/io5";
import {
  MdBrightness4,
  MdOutlineNotificationImportant,
  MdOutlineSettings,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  NotificationCountAction,
  handleTheme,
  signOutAction,
} from "../../redux/action/action";
import blueTheme from "./../../assets/images/blueTheme.png";
import darkTheme from "./../../assets/images/darkTheme.png";
import userImg from "./../../assets/images/user.png";
import whiteTheme from "./../../assets/images/whiteTheme.png";
import { IconButton, Tooltip } from "@mui/material";
const Navbar = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (data) => {
    if (data == "/subscription") navigate(data);
    if (data == "/account") navigate(data);
    if (data == "theme") showModal();
    if (data == "logout") logOut();
    setAnchorEl(null);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value) => {
    dispatch(handleTheme(value));
  };

  const themes = useSelector((state) => state.cylenium.themes);

  const myAccount = useSelector((state) => state.cylenium.myAccount);
  const auth = useSelector((state) => state.cylenium.auth);
  const notifcationCount = useSelector((state) => state.cylenium.note);
  const logOut = () => {
    Swal.fire({
      title: "Confirm logout?",
      text: "Are you sure you want to logout  from this portal?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#D8EAFF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout",
      confirmButtonTextColor: "#0177FB",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        localStorage.clear();
        dispatch(signOutAction());
      }
    });
  };

  useEffect(() => {
    if (!auth?.user_id) {
      navigate("/");
    }
  }, [auth]);
  const items = [
    {
      key: "23",
      label: "My Account",
      icon: <MdOutlineSettings />,
      onClick: () => {
        handleClose("/account");
      },
    },
    {
      key: "1",
      label: "Subscription",
      icon: <AiOutlineDollarCircle fontSize={17} />,
      fontSize: "23",
      onClick: () => {
        handleClose("/subscription");
      },
    },
    // {
    //   key: "2",
    //   label: "Theme",
    //   icon: <BiColorFill />,
    //   onClick: () => {
    //     handleClose("theme");
    //   },
    // },
    {
      key: "3",
      label: "Logout",
      icon: <AiOutlineLogin />,
      onClick: () => handleClose("logout"),
    },
  ];
  const fetchAndUpdateData = async () => {
    dispatch(NotificationCountAction());
  };
  useEffect(() => {
    fetchAndUpdateData();
    const intervalId = setInterval(fetchAndUpdateData, 20000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <header className="top-wrap">
      <div>
        <IoOptionsSharp
          // color="white"
          fontSize={30}
          className="sidebar-icon cursor"
          onClick={props.onSidebar}
        />
      </div>
      <div className="ntification-block d-flex">
        <Badge count={notifcationCount?.count ? notifcationCount?.count : 0}>
          <Tooltip title="Notification">
            <IconButton style={{ cursor: "pointer" }}>
              <MdOutlineNotificationImportant
                color="#fff"
                fontSize={24}
                onClick={() => navigate("/bell_notification")}
              />
            </IconButton>
          </Tooltip>
        </Badge>
        <Tooltip title="Theme">
          <IconButton className="ml-2" onClick={() => handleClose("theme")}>
            <MdBrightness4 />
          </IconButton>
        </Tooltip>
      </div>
      <div className="user-block">
        <div className="dropdown">
          <Dropdown
            style={{ width: 300 }}
            menu={{
              items,
              selectable: false,
              className: "profile-dropdown",
            }}
            arrow={true}
            fontSize={24}
            onOpenChange={(value) => setDropdownOpen(value)}
          >
            <button
              className="btn btn-secondary pro-drop-box"
              onClick={handleClick}
            >
              <div className="head-pro-block">
                <div className="user-pro-img">
                  <img
                    src={myAccount?.profile_url || userImg}
                    alt="username"
                    title="username"
                  />
                </div>
                <div className="user-pro-info">
                  <h4> {myAccount?.full_name}</h4>
                  <a>{myAccount?.email_address}</a>
                </div>
                {!dropdownOpen ? (
                  <FaCaretDown className="mx-2 text-white" />
                ) : (
                  <FaCaretUp className="mx-2 text-primary" />
                )}
              </div>
            </button>
          </Dropdown>
        </div>
      </div>
      <Modal
        title="Change Theme"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        className={
          themes == 1
            ? "dark-theme"
            : themes == 2
            ? "blue-theme"
            : "white-theme"
        }
        onCancel={handleCancel}
        footer={false}
      >
        <div className="row justify-content-center">
          <div
            className={`theme-box  ${themes == 1 && "activeBorder"}`}
            onClick={() => handleChange(1)}
          >
            <img loading="lazy" src={darkTheme} />
            <div className="fs-12 text-center pt-2 ">Dark (default)</div>
          </div>
          <div className={`theme-box ${themes == 2 && "activeBorder"}`}>
            <img
              loading="lazy"
              src={blueTheme}
              onClick={() => handleChange(2)}
            />
            <div className="fs-12 text-center pt-2">Blue</div>
          </div>
          <div className={`theme-box ${themes == 3 && "activeBorder"}`}>
            <img
              loading="lazy"
              src={whiteTheme}
              onClick={() => handleChange(3)}
            />
            <div className="fs-12 text-center pt-2">White</div>
          </div>
        </div>
      </Modal>
    </header>
  );
};

export default Navbar;
