import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import React from 'react';
import { Controller } from 'react-hook-form';
dayjs.extend(utc);
dayjs.extend(timezone);

const CustomDateTimePicker = (props) => {
  return (
    <div>
      <Controller
        // defaultValue={dayjs.utc()}
        {...props}
        fullWidth
        render={({ field: { onChange, ...restField } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DateTimePicker
              timezone="UTC"
              format="LLL"
            
              onChange={(event) => {
                onChange(event);
              }}
              {...restField}
              {...props}
              slotProps={{
                textField: {
                  readOnly: true,
                  error: props?.error && props?.error,
                  helperText: props?.error?.message && props?.error?.message,
                },
              }}
            />
          </LocalizationProvider>
        )}
      />
    </div>
  );
};
export default CustomDateTimePicker;
