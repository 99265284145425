import React from "react";
import Chart from "react-apexcharts";

const DonutChart = () => {
  const chartData = {
    series: [44, 55, 13, 43, 22],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Label 1", "Label 2", "Label 3", "Label 4", "Label 5"],
    },
    dataLabels: {
      style: {
        colors: ["#F44336", "#E91E63", "#9C27B0"],
      },
    },
  };

  return (
    <Chart
      options={chartData.options}
      series={chartData.series}
      type="donut"
      width="380"
    />
  );
};

export default DonutChart;
