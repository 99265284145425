import * as React from "react";
import { IconButton, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { MdMenuOpen } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAiChatListAction } from "../../../redux/action/action";
import { newChatApi } from "../../../services/api";
import DropdownDelete from "./DropdownDelete";
import ChatRenameModal from "./ChatRenameModal";
import { useState } from "react";

export default function Sidebar({ selectedId, setSelectedId }) {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chatName, setChatName] = useState("");

  const chatList = useSelector((state) => state.cylenium.chatList);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const idParam = params.get("id");
  const handleRename = (id, name) => {
    setIsModalOpen(id);
    setChatName(name);
    setOpenSidebar(false);
  };

  React.useEffect(() => {
    dispatch(getAiChatListAction());
  }, []);

  const list = (anchor) => (
    <Box sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}>
      <Divider />

      <div className=" mt-3">
        <div className="text-center">
          <h6 className="mt-2">Chat History</h6>
        </div>
      </div>
      <div className="chat-height">
        {chatList?.map((record) => (
          <div key={record.date} className="p-2">
            <Divider>
              <div className="day-title">
                {moment(record.date).startOf("day").fromNow()}
              </div>
            </Divider>
            <div>
              {record?.chatList?.map((element) => (
                <div className="d-flex" key={element?.title}>
                  <Tooltip title={element?.title}>
                    <a
                      className={
                        element.id == idParam
                          ? "old-chat overflow-textchat selected-chat"
                          : "old-chat overflow-textchat"
                      }
                      onClick={() => selectedChat(element.id)}
                    >
                      {" "}
                      {element?.name}
                    </a>
                  </Tooltip>
                  <DropdownDelete
                    id={element.id}
                    isSelectedChat={element.id == idParam ? true : false}
                    setSelectedId={setSelectedId}
                    name={element?.name}
                    handleRename={handleRename}
                  />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Divider />
      <div className="d-flex m-3">
        <a className="new-chat-btn" onClick={() => newChat()}>
          <FaPlus fontSize={20} className="p-1 mb-1" />
          New Chat
        </a>
      </div>
      <div className="d-flex m-3">
        <a
          className="new-chat-btn background-none"
          onClick={() => setOpenSidebar(false)}
        >
          {" "}
          <IoIosClose fontSize={26} />
          Close
        </a>
      </div>
    </Box>
  );

  const navigate = useNavigate();
  const selectedChat = (id) => {
    navigate(`/chat?id=${id}`);
    setSelectedId(id);
  };
  const newChat = async () => {
    newChatApi()
      .then((res) => selectedChat(res.data.id))
      .catch((err) => toast.error(err.message));
  };
  return (
    <div className="text-end" style={{ height: 10 }}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton onClick={() => setOpenSidebar(true)}>
            <MdMenuOpen />
          </IconButton>
          <Drawer anchor={anchor} open={openSidebar}>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}

      <ChatRenameModal
        id={isModalOpen} //containing id instead of true false
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        name={chatName}
        setOpenSidebar={setOpenSidebar}
      />
    </div>
  );
}
