import { IconButton, Tooltip } from "@mui/material";
import { BsEye } from "react-icons/bs";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";
import { Popconfirm } from "antd";
import moment from "moment";

export const editDomainColumn = (props) => [
  {
    title: "Device  Name",
    dataIndex: "device_name",
    key: "device_name",
  },
];

export const deviceDomaincolumns = (props) => [
  {
    title: "Divice ID",
    dataIndex: "device_id",
    key: "device_id",
    render: (data) => {
      return <div className="text-center1">{data === "" ? "-" : data} </div>;
    },
  },
  {
    title: "Device Name",
    dataIndex: "device_name",
  },
  {
    title: "Device Type",
    dataIndex: "device_type",
  },

  {
    title: "Connection",
    dataIndex: "status",
    key: "status",
    render: (data) => {
      return (
        <div className="text-center1">
          {data ? (
            <li>
              <span className="dot bg-green"></span>
              <span className="text-success">Active</span>
            </li>
          ) : (
            <li className="">
              <span className="dot bg-red"></span>
              <span className="text-danger">Inactive</span>
            </li>
          )}
        </div>
      );
    },
  },
  {
    title: "Action",
    dataIndex: "status",

    render: (data, record) => {
      return (
        <div className="d-flex">
          <Tooltip title="View">
            <IconButton>
              <BsEye
                className="icon me-1"
                onClick={() =>
                  props?.navigate(`/domain/endpoint/${record?.device_id}`)
                }
              />
            </IconButton>
          </Tooltip>
          <Popconfirm
            title="Confirm Delete"
            description="Are you sure you want to delete this domain?"
            onConfirm={() => props.onDelete(record?.id)}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title="Delete">
              <IconButton>
                <AiOutlineDelete className="icon" />
              </IconButton>
            </Tooltip>
          </Popconfirm>
        </div>
      );
    },
  },
];
export const endpointDomaincolumns = (props) => [
  {
    title: "Endpoint Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "IP Address",
    dataIndex: "ip_address",
  },
  {
    title: "Mac Address",
    dataIndex: "mac_address",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "Device Type",
    dataIndex: "device_type",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "OS",
    dataIndex: "os",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "Location",
    dataIndex: "location",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "Manufacturer",
    dataIndex: "manufacturer",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "model",
    dataIndex: "model",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },

  {
    title: "Status",
    dataIndex: "isConnected",
    key: "isConnected",
    render: (data) => {
      return Boolean(data) ? "Connected" : "Disconnected";
    },
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (data, record) => {
      return (
        <>
          <Tooltip title="Edit">
            <IconButton onClick={() => props.editRecord(record)}>
              {" "}
              <AiFillEdit className="icon" />
            </IconButton>
          </Tooltip>
        </>
      );
    },
  },
];
export const unauthorizedEndpointDomaincolumns = (props) => [
  {
    title: "Endpoint Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "IP Address",
    dataIndex: "ip_address",
  },
  {
    title: "Mac Address",
    dataIndex: "mac_address",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "Device Type",
    dataIndex: "device_type",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "OS",
    dataIndex: "os",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },

  {
    title: "Status",
    dataIndex: "isConnected",
    key: "isConnected",
    render: (data) => {
      return <div className="text-danger">Unauthorized</div>;
    },
  },
];
export const AiBlockedListColumn = (props) => [
  {
    title: "Host Name",
    dataIndex: "hostName",
    key: "name",
  },
  {
    title: "Source IP Address",
    dataIndex: "srcIp",
  },
  {
    title: "Destination IP Address",
    dataIndex: "dstIp",
  },
  {
    title: "Source Port",
    dataIndex: "ip_address",
    render: (data) => {
      return 3000;
    },
  },
  {
    title: "Destination Port",
    dataIndex: "ip_address",
    render: (data) => {
      return 30;
    },
  },
  {
    title: "Date-time",
    dataIndex: "timestamp",
    render: (data) => {
      return moment(data).format("MM-DD-YYYY HH:mm");
    },
  },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (data) => {
      return <div className="text-danger">{data}</div>;
    },
  },
];
