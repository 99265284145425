import { Spin } from "antd";
import React, { useState } from "react";

const Index = () => {
  const [isLoading, setIsLoading] = useState(true);
  const handleLoad = () => {
    setIsLoading(false); // Set isLoading to false when iframe content is loaded
  };
  // const getRecords = () => {
  //   dispatch(getOTTokenAction());
  // };
  // // Custom hook which handle all dependency on Table data
  // const { records, dispatch, param, setParam } = ApiHelper({
  //   getRecords, //this function is calling the api
  //   getData: (state) => state.cylenium.otToken, //this is getting data from redux  store  and return into records
  // });
  return (
    <div style={{ background: "#212528" }}>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            background: "#212528",
            color: "#fff",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spin size="large" spinning={isLoading}></Spin>
        </div>
      )}
      <iframe
        src="https://ot.cylenium.com/v1/advanceAnalytics/digital-twins/twin-dashboard"
        style={{
          display: isLoading ? "none" : "block",
          height: "100vh",
          background: "#212528",
          border: "0px",
        }}
        width={"100%"}
        height={"100vh"}
        onLoad={handleLoad}
      />{" "}
    </div>
  );
};

export default Index;
