import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { getRiskAssessmentAction } from "../../../redux/action/action";
import LineCharts from "../../../common/UI/Charts/LineCharts";
import StackedBarGraphSeverity from "../../../common/UI/Charts/StackedBarGraphSeverity";
import ThreatTypeTable from "./ThreatTypeTable";
import VulnerabilityTable from "./VulnerabilityTable";
import DateFilter from "../../../common/UI/DateFilter";
import { Button, Checkbox, Modal } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import QueryString from "qs";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Index = () => {
  const [value, setValue] = useState(0);
  const [dateFilterValue, setDateFilter] = useState(null);
  const [selectedGraph, setSelectedGraph] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const themes = useSelector((state) => state.cylenium.themes);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const onCheckBoxChange = (event) => {
    // console.log(event);
    setSelectedGraph((prevRec) =>
      prevRec.map((current) =>
        current.id === event.target.id
          ? { ...current, isSelected: event.target.checked }
          : current
      )
    );
  };
  const selectedAllGraphs = (checked) => {
    setSelectedGraph((prevRec) =>
      prevRec.map((current) =>
        true ? { ...current, isSelected: checked } : current
      )
    );
  };
  const handleApply = () => {
    setIsModalOpen(false);
    const dashboardID = [];
    selectedGraph?.forEach((data) => {
      if (data?.isSelected) {
        dashboardID.push(data.id);
      }
    });

    navigate(
      `/reports/riskDownload?${
        dashboardID ? "dashboardID=" + dashboardID : ""
      }${
        param?.start_date && param?.end_date
          ? "&start_date=" + param?.start_date
          : ""
      }
      ${
        param?.end_date && param?.start_date
          ? "&end_date=" + param?.end_date
          : ""
      }`
    );
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getRecords = () => {
    const payload = {
      start_date: param?.start_date
        ? dayjs(param.start_date).unix()
          ? dayjs(param.start_date).format("YYYY-MM-DD")
          : undefined
        : undefined,
      end_date: param?.end_date
        ? dayjs(param?.end_date).unix()
          ? dayjs(param?.end_date).format("YYYY-MM-DD")
          : undefined
        : undefined,
    };
    dispatch(getRiskAssessmentAction(QueryString.stringify(payload)));
  };
  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.riskAssessment, //this is getting data from redux  store  and return into records
  });
  useEffect(() => {
    if (records?.threatTypeGraph?.length) {
      setSelectedGraph(
        records?.threatTypeGraph ? records?.threatTypeGraph : []
      );
    }
  }, [records]);

  return (
    <div>
      <div className="page-head d-flex align-items-center justify-content-between mb-3">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Risk Assessment</h3>
        </div>{" "}
        <div className="d-flex">
          <DateFilter param={param} setParam={setParam} />
          <button
            type="button"
            className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
            // onClick={() => navigate("/reports/riskDownload")}
            onClick={() => setIsModalOpen(true)}
          >
            Report
          </button>
        </div>
      </div>
      <div className="row">
        {records?.threatTypeGraph?.map((record) => (
          <div className={false ? "r-col-12" : "r-col-4"} key={record.id}>
            <div className="dashboard-graph">
              <div className="graph-title mb-3 d-flex justify-content-between">
                <div>{record.dashboardGraphName} </div>
              </div>
              {record?.graphType == "bar" ? (
                <StackedBarGraphSeverity
                  result={record.result}
                  subTitle={record.subTitle}
                />
              ) : (
                <LineCharts
                  series={
                    record.result[0]?.series ? record.result[0]?.series : []
                  }
                  subTitle={record.subTitle}
                  categories={
                    record.result[0]?.categories
                      ? record.result[0]?.categories
                      : []
                  }
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="add-user-wrap mt-3">
        <div className="dark-head-card">
          <div className="d-c-body">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChange}>
                  <Tab label="Threats" {...a11yProps(0)} />
                  <Tab label="Vulnerability" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <ThreatTypeTable />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <VulnerabilityTable />
              </CustomTabPanel>
            </Box>
          </div>
        </div>
      </div>
      <Modal
        title="Graphs"
        open={isModalOpen}
        className={
          themes == 1
            ? "dark-theme"
            : themes == 2
            ? "blue-theme"
            : "white-theme"
        }
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>,
          <Button className="submit-Button" onClick={handleApply}>
            Apply
          </Button>,
        ]}
      >
        <div className="h-80">
          <div>
            <Checkbox
              checked={selectedGraph.every((record) => record.isSelected)}
              // id={record.id}
              onChange={(event) => selectedAllGraphs(event.target.checked)}
            >
              <div className="item-active">{"All Graphs"}</div>
            </Checkbox>
          </div>
          {selectedGraph &&
            selectedGraph.map((record) => (
              <div key={record.id} className="mt-3">
                <Checkbox
                  checked={record?.isSelected}
                  id={record.id}
                  onChange={onCheckBoxChange}
                >
                  <div className="item-active">{record.dashboardGraphName}</div>
                </Checkbox>
              </div>
            ))}
        </div>
      </Modal>
    </div>
  );
};

export default Index;
