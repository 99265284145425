import React, { useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBackOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomInput from "../../../common/CustomInput";
import EditFormIcon from "../../../common/UI/EditFormIcon";
import TrustGroupTable from "../../../common/UI/TrustGroupTable";
import {
  editTrustGroupAction,
  getTrustGroupByIdAction,
} from "../../../redux/action/action";
import {
  addTrustedGroup,
  editTrustedGroup,
} from "../../../utils/validations/trustGroupSchema";
const EditTrustedGroup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [param, setParam] = useState({
    disable: false,
  });
  const cotRecord = useSelector((state) => state.cylenium.cotById);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm({ resolver: yupResolver(editTrustedGroup) });

  const onSubmit = (data) => {
    console.log("data", data);
    dispatch(editTrustGroupAction({ ...data, id: parseInt(id) }));
  };
  const { id } = useParams();
  useEffect(() => {
    dispatch(getTrustGroupByIdAction(id));
  }, []);
  useEffect(() => {
    if (cotRecord) {
      reset({ name: cotRecord.name, ...cotRecord.certificateValidity });
    }
  }, [cotRecord, id]);
  console.log("errors", errors);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <div className="page-title d-flex">
              <IoArrowBackOutline
                color="white"
                style={{ fontSize: 20 }}
                onClick={() => navigate(-1)}
              />
              <h3 className="font-size18 text-white Inter-Bold mx-2">
                Edit Circle of Trust Group
              </h3>
            </div>
            <EditFormIcon param={param} setParam={setParam} />
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Group Name"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="name"
                      error={errors?.name}
                      disabled={true}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 label-white mb-3">
                  Certificate Validity Timeout
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Days"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="days"
                      error={errors?.days}
                      fullWidth
                      disabled={!param.disable}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Hours"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="hours"
                      error={errors?.hours}
                      fullWidth
                      disabled={!param.disable}
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Minutes"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="minutes"
                      error={errors?.minutes}
                      fullWidth
                      disabled={!param.disable}
                    />{" "}
                  </div>
                </div>
                <TrustGroupTable
                  records={cotRecord}
                  control={control}
                  watch={watch}
                  param={param}
                />
              </div>
            </div>
            {param?.disable ? (
              <div className="col-sm-12 mt-2">
                <div className="f-btm-btn text-end">
                  <button
                    type="button"
                    className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                  >
                    Submit
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditTrustedGroup;
