import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustomDropdown from "../../common/CustomDropdown";
import CustomInput from "../../common/CustomInput";
import CustomInputPassword from "../../common/CustomInputPassword";
import CustomMultiSelect from "../../common/CustomMultiSelect";
import CustomMultiSelectDevice from "../../common/CustomMultiSelectDevice";
import EditFormIcon from "../../common/UI/EditFormIcon";
import SaveAndCancelButton from "../../common/UI/SaveAndCancelButton";
import {
  editUserAction,
  empPermissionModuleAction,
  getDeviceListAction,
  getUserByIdAction,
} from "../../redux/action/action";
import { editSchema } from "../../utils/validations/userValidation";

const EditUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.cylenium.user);
  const empModule = useSelector((state) => state.cylenium.empModule);
  const deviceList = useSelector((state) => state.cylenium.deviceList);

  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
    records: [],
    disable: false,
  });
  const { id } = useParams();

  const options = [
    { value: "1", label: "Guest" },
    { value: "2", label: "Security Analyst" },
    { value: "3", label: "Customer Admin" },
    { value: "4", label: "IT Administrator" },
    { value: "5", label: "System Operator" },
  ];

  const job_title_options = [
    { value: "Contractor", label: "Contractor" },
    { value: "Employee", label: "Employee" },
  ];
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(editSchema),
    defaultValues: { devices: [], modules: [], company: "Cylenium" },
  });
  const role_id = watch("role_id");
  const onSubmit = (data) => {
    const payload = {
      ...data,
      flag: "user",
    };
    dispatch(editUserAction(payload));
  };

  useEffect(() => {
    dispatch(getUserByIdAction(id));
    dispatch(empPermissionModuleAction());
  }, []);

  useEffect(() => {
    reset(user);
  }, [user]);
  useEffect(() => {
    dispatch(getDeviceListAction(role_id == 1 ? role_id : 2));
    if (user?.role_id != watch("role_id") && user?.role_id) {
      console.log(user?.role_id != watch("role_id") && user?.role_id);
      setValue("devices", []);
    }
    if (user?.role_id == watch("role_id") && user?.role_id) {
      console.log(user?.role_id != watch("role_id") && user?.role_id);
      setValue("devices", user.devices ? user.devices : []);
    }
  }, [role_id]);

  console.log("role_id", role_id, ["1", "3", 1, 3].includes(role_id));
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <h3 className="font-size18 text-white">
              <IoArrowBack
                className="mr-r10 cursor"
                onClick={() => navigate("/organization")}
              />
              Edit User
            </h3>

            <EditFormIcon param={param} setParam={setParam} />
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomDropdown
                      label={"Role*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="role_id"
                      error={errors?.role_id}
                      options={options}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"First Name*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="first_name"
                      error={errors?.first_name}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Last Name"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="last_name"
                      error={errors?.last_name}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Company"}
                      varient="outline"
                      disabled={true}
                      control={control}
                      defaultValue=""
                      name="company"
                      error={errors?.company}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Email Address*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="email_address"
                      error={errors?.email_address}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInputPassword
                      label={"Password"}
                      name="password"
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      error={errors?.password}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInputPassword
                      label={"Confirm Password"}
                      name="confirm_password"
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      error={errors?.confirm_password}
                      fullWidth
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Office phone"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="office_phone"
                      error={errors?.office_phone}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Phone Number*"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="phone_number"
                      error={errors?.phone_number}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-4">
                  <div
                    className={
                      (`form-floating cus-floating-inputs mr-b30`,
                      !param.disable ? "disabled" : null)
                    }
                  >
                    <CustomDropdown
                      label={"Job Title"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="job_title"
                      error={errors?.job_title}
                      options={job_title_options}
                      fullWidth
                    />
                  </div>
                </div>
                {/* <div className="col-sm-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <MultipleDropdownSelection
                      label={"Devices"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="devices"
                      error={errors?.role}
                      options={deviceList}
                      multiple={true}
                      fullWidth
                    />
                  </div>
                </div> */}
                <div className="col-sm-12">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Office Location"}
                      varient="outline"
                      disabled={!param.disable}
                      control={control}
                      defaultValue=""
                      name="office_location"
                      error={errors?.office_location}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Work Location"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="work_location"
                      error={errors?.work_location}
                      fullWidth
                      disabled={!param.disable}
                    />{" "}
                  </div>
                  {!["1", "3", 1, 3].includes(role_id) ? (
                    <div className="col-sm-12">
                      <div className="form-floating cus-floating-inputs mr-b30">
                        <CustomMultiSelect
                          label={"Access Permissions"}
                          options={empModule === undefined ? [] : empModule}
                          name="modules"
                          multiple={true}
                          setValue={setValue}
                          watch={watch}
                          // disabled={true}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-sm-12">
                  {/* <MultiSelectDevices
                    label={"Device"}
                    varient="outline"
                    control={control}
                    placeholder="Device"
                    name="devices"
                    error={errors?.devices}
                    options={deviceList}
                    mode={"multiple"}
                    fullWidth
                  /> */}
                  <CustomMultiSelectDevice
                    label={"Device"}
                    varient="outline"
                    control={control}
                    placeholder="Device"
                    name="devices"
                    options={deviceList}
                    multiple={true}
                    setValue={setValue}
                    disabled={!param.disable}
                    watch={watch}
                  />
                </div>
              </div>
              <SaveAndCancelButton
                param={param}
                cancelAction={() => navigate("/organization")}
              />{" "}
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default EditUser;
