import { Card, Table } from "antd";
import { Handle, Position } from "reactflow";
import { authColumn } from "../../../common/Tables/trustedGroupColumn";
import moment from "moment";

function TextUpdaterNodeTable({ data, isConnectable }) {
  const records = [];
  for (let index = 0; index <= 1000; index++) {
    records?.push({
      time: moment().format("MM-DD-YYYY HH:mm"),
      user: "Test" + index,
      login_from: "India",
      login_ip: "192.168.1" + index,
      result: "login failed",
      client_app: "SMTP",
      reason: "-",
    });
  }
  return (
    <>
      <Handle
        type="target"
        position={Position.top}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        id={data.id}
      >
        {" "}
      </Handle>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        id={data.id}
      >
        {" "}
      </Handle>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        id={data.id}
      >
        {" "}
      </Handle>
      <Handle
        type="target"
        position={Position.Right}
        style={{ background: "#555" }}
        isConnectable={isConnectable}
        id={data.id}
      >
        {" "}
      </Handle>
      <Card
        title={data?.title}
        // extra={<a href="#">More</a>}
        style={{ width: 500 }} // background: state ? "gray" : "transparent" }}
        hoverable={true}
        // color={state && CARD_COLOR_ACTIVE}
      >
        <Table
          dataSource={records}
          columns={authColumn({})}
          pagination={true}
        />
      </Card>
    </>
  );
}

export default TextUpdaterNodeTable;
