import React, { useLayoutEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
const StackedBarGraphSeverity = (props) => {
  const themes = useSelector((state) => state.cylenium.themes);

  const chartData = {
    // series: [
    //   {
    //     name: "Low",
    //     data: [44, 55, 41, 67, 22],
    //   },
    //   {
    //     name: "Medium",
    //     data: [13, 23, 20, 8, 13],
    //   },

    //   {
    //     name: "High",
    //     data: [12, 5, 24, 12, 22],
    //   },
    // ],
    series: props?.result[0]?.series ? props?.result[0]?.series : [],
    options: {
      chart: {
        type: "bar",
        height: 280,
        stacked: true,
        width: "100%",
      },

      xaxis: {
        // categories: ["laptop", "My Pc", "macbook", "Windows", "chromebook"],
        categories: props.result[0]?.categories
          ? props.result[0]?.categories
          : [],
        labels: {
          style: {
            colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      yaxis: {
        title: {
          text: props?.subTitle ? props?.subTitle : undefined,
          style: {
            color: 1 != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
        labels: {
          style: {
            colors: themes != 3 ? "#ffffff" : "", // Set the label color to white
          },
        },
      },
      legend: {
        position: "bottom",
        // offsetY: 40,
      },

      dataLabels: {
        enabled: false, // Set enabled to false to remove the numbers from the bars
      },
    },
  };
  useLayoutEffect(() => {
    window.dispatchEvent(new Event("resize"));
  }, []);
  console.log("record", props.result);
  return (
    <div className="row">
      <div className="">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={"280px"}
        />
      </div>
    </div>
  );
};

export default StackedBarGraphSeverity;
