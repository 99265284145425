import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ApiHelper from "../../../../common/Hooks/ApiHelper";
import { endpointDomaincolumns } from "../../../../common/Tables/domainTableColumn";
import { CustomSearch } from "../../../../common/UI/CustomSearch";
import CustomTable from "../../../../common/UI/CustomTable";
import {
  editDeviceProfileAction,
  getDomainEndpointByIdAction,
} from "../../../../redux/action/action";
import EditDeviceProfile from "./EditDeviceProfile";
import { IoArrowBack } from "react-icons/io5";

const DomainProfile = () => {
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const { id } = useParams();

  //getting data from api and store
  const getRecords = () => {
    const payload = {
      id: id,
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getDomainEndpointByIdAction(payload));
  };
  // Custom hook which handle all dependency on Table data
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.domainEndpoint, //this is getting data from redux  store  and return into records
  });

  const editRecord = (data) => {
    setSelectedRecord(data);
    setOpen(true);
  };
  const onSubmit = (data) => {
    const payload = {
      id: id,
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(
      editDeviceProfileAction(
        {
          id: data.id,
          manufacturer: data.manufacturer,
          model: data.model,
          location: data.location,
        },
        payload
      )
    );
    setOpen(false);
  };
  return (
    <div className="body-r-container">
      <div className="page-wrap">
        <div className="edit-user-wrap">
          <div className="dark-head-card">
            <div className="d-c-head d-flex align-items-center justify-content-between">
              <h3 className="font-size18 text-white">
                <IoArrowBack
                  className="mr-r10 cursor"
                  onClick={() => navigate("/device")}
                />
                Profile{" "}
              </h3>
            </div>
            <div className="m-3">
              <div className="table-head d-flex align-items-center justify-content-between mr-t20">
                <CustomSearch param={param} setParam={setParam} />
                {/* <FilterBlock /> */}
              </div>
              <div className="">
                <CustomTable
                  className="custom-table-1"
                  records={records}
                  columns={endpointDomaincolumns({ editRecord })} //we  are passing this to column to antd column which in separate file
                  param={param}
                  setParam={setParam}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditDeviceProfile
        open={open}
        setOpen={setOpen}
        selectedRecord={selectedRecord}
        onSubmit={onSubmit}
      />{" "}
    </div>
  );
};

export default DomainProfile;
