import React from "react";

const questionList = [
  "List the Active SG-X devices and their endpoints in the Organization?",
  "List the Vulnerabilities found today?",
  "Which endpoints have the highest number of threats detected in the past week?",
  "How many Vulnerabilities have been closed in the last month?",
];
const QuestionBox = ({setQuestion}) => {
  return (
    <div className="row justify-content-center mt-3">
      {questionList.map((data, index) => (
        <div
          className="col-md-5 col-sm-6 mt-2"
          key={index}
          onClick={() => setQuestion(data)}
          style={{ cursor: "pointer" }}
        >
          <div className="chat-card height-100">
            <div className="card__content">{data}</div>
          </div>{" "}
        </div>
      ))}
    </div>
  );
};

export default QuestionBox;
