import moment from "moment";
import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import ApiHelper from "../../common/Hooks/ApiHelper";
import { getSubscriptionInvoiceHistoryByIdAction } from "../../redux/action/action";
import cervais_logo from "./../../assets/images/cervais.png";

const Invoice = () => {
  const { toPDF, targetRef } = usePDF({ filename: "invoice.pdf" });
  const { id } = useParams();
  const getRecords = () => {
    dispatch(getSubscriptionInvoiceHistoryByIdAction(id));
  };

  const { records, dispatch, navigate } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.invoiceDetails, //this is getting data from redux  store  and return into records
  });
  return (
    <div>
      <div
        className="dark-head-card"
        style={{
          overflow: "auto",
        }}
      >
        <div className="d-c-head d-flex align-items-center justify-content-between">
          <h3 className="font-size18 text-white">
            <IoArrowBack
              className="mr-r10 cursor"
              onClick={() => navigate(-1)}
            />
            Invoice{" "}
          </h3>
          <div className="r-btn-block">
            <button
              type="button"
              className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
              onClick={() => toPDF()}
            >
              Download
            </button>
          </div>
        </div>

        <div className="invoice-body" ref={targetRef}>
          <div className="d-flex  justify-content-between">
            <div className="invoice-img">
              <img src={cervais_logo} className="" alt="" />{" "}
            </div>
            <div>
              <h4 className="">INVOICE</h4>
              <p className="mt-2">
                <b>Cervais, Inc.</b> <br />
                20706 Pomeroy Ct. Ashburn,
                <br />
                VA 20147
              </p>
            </div>
          </div>
          <div className="invoice-content">
            <div className="d-flex m-3 justify-content-between">
              <div>
                <p className="mt-2">
                  <b>{records?.companyName}</b> <br />
                  {records?.address}
                  <br />
                  {/* Nashik, Maharashtra 422007 */}
                </p>
              </div>
              <div>
                <p className="mt-2">
                  <b>Invoice Number :</b> {records?.id} <br />
                  <b>Invoice Date :</b>{" "}
                  {moment(records?.invoiceDate).format("MMM DD YYYY")} <br />
                </p>
              </div>
            </div>
          </div>
          <div className="invoice-content">
            <div className="row m-3 border-bottom-solid ">
              <div className="col-3">
                <p className="mt-2">
                  <b>Plan Name</b> <br />
                </p>
              </div>
              <div className="col-3">
                <p className="mt-2">
                  <b>Quantity</b> <br />
                </p>
              </div>
              <div className="col-3">
                <p className="mt-2">
                  <b>Price</b> <br />
                </p>
              </div>
              <div className="col-3">
                <p className="mt-2">
                  <b>Total</b> <br />
                </p>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row m-3 border-bottom-solid ">
              <div className="col-3">
                <p className="mt-2">{records?.name}</p>
              </div>
              <div className="col-3">
                <p className="mt-2">1</p>
              </div>
              <div className="col-3">
                <p className="mt-2">${records?.price}</p>
              </div>
              <div className="col-3">
                <p className="mt-2">${records?.price}</p>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row m-3 justify-content-end">
              <div className="col-3">
                <p className="mt-2">
                  <b>Total : ${records?.price}</b> <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
