import React, { useEffect, useState } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import QueryString from "qs";
import { IoArrowBackOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import CustomInput from "../../../common/CustomInput";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import {
  addTrustGroupAction,
  createGroupDevicesListAction,
} from "../../../redux/action/action";
import { addTrustedGroup } from "../../../utils/validations/trustGroupSchema";
import AddNodeTable from "./AddNodeTable";

const AddTrustedGroup = () => {
  const [list, setList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addTrustedGroup),
    defaultValues: {
      devices: [],
      hours: 0,
      minutes: 0,
      days: "1",
    },
  });

  const onSubmit = (data) => {
    const deviceIds = [];
    for (let device of list) {
      // if (device.isChecked) {
      for (let enpoint of device?.connectedDevices) {
        if (enpoint.isChecked) {
          deviceIds.push(enpoint.id);
        }
      }
      // }
    }
    if (!deviceIds?.length) toast.error("Please select device");
    else dispatch(addTrustGroupAction({ ...data, devices: deviceIds }));
  };

  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      order_by: param.sorter?.order === "ascend" ? "ASC" : "DESC",
      sort_by: "name",
      endpointFilter: param?.filters?.name?.length
        ? param?.filters?.name[0]
        : "all",
      classificationFilter: param?.filters?.classification?.length
        ? param?.filters?.classification[0]
        : undefined,
    };
    dispatch(createGroupDevicesListAction(QueryString.stringify(payload)));
  };
  const {
    records: deviceList,
    dispatch,
    navigate,
    param,
    setParam,
  } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.deviceList, //this is getting data from redux  store  and return into records
  });

  useEffect(() => {
    const temp = deviceList?.records?.devices?.map((rec) => ({
      ...rec,
      device_name: rec.name,
    }));
    setList(temp);
  }, [deviceList]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="add-user-wrap">
        <div className="dark-head-card">
          <div className="d-c-head d-flex align-items-center justify-content-between">
            <div className="page-title">
              <h3 className="font-size18 text-white Inter-Bold d-flex">
                <IoArrowBackOutline
                  // color="white"
                  style={{ fontSize: 20 }}
                  onClick={() => navigate(-1)}
                  className="mx-2 pt-1"
                />
                Create Circle of Trust Group
              </h3>
            </div>
          </div>
          <div className="d-c-body">
            <div className="add-u-form-card">
              <div className="row">
                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Group Name"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="groupName"
                      error={errors?.groupName}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 label-white my-3">
                  Certificate Validity Timeout
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Days"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="days"
                      error={errors?.days}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Hours"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="hours"
                      error={errors?.hours}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12 col-md-4">
                  <div className="form-floating cus-floating-inputs mr-b30">
                    <CustomInput
                      label={"Minutes"}
                      varient="outline"
                      control={control}
                      defaultValue=""
                      name="minutes"
                      error={errors?.minutes}
                      fullWidth
                    />{" "}
                  </div>
                </div>
                <div className="col-sm-12">
                  {" "}
                  <AddNodeTable
                    list={list}
                    setList={setList}
                    param={param}
                    setParam={setParam}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    totalRecord={deviceList?.total_records}
                  />
                </div>

                <div className="col-sm-12 mt-2">
                  <div className="f-btm-btn text-end">
                    <button
                      type="button"
                      className="white-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium"
                      onClick={() => navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mr-l10"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddTrustedGroup;
