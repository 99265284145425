import { IconButton } from "@mui/material";
import { Table } from "antd";
import Search from "antd/es/transfer/search";
import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const ViewThreatSharing = () => {
  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
  });
  const navigate = useNavigate();
  const users = useSelector((state) => state.cylenium.userList);
  const result = [
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
    {
      organization_id: "1",
      organization_name: "Cervais",
      email_address: "cervais@yopmail.com",
      phone_number: "+1 987-123-2143",
      organization_address: "NJ, USA",
      device: 50,
      users: 50,
    },
  ];
  const columns = [
    {
      title: "Organization ID	",
      dataIndex: "organization_id",
    },
    {
      title: "Organization Name	",
      dataIndex: "organization_name",
      render: (data) => {
        return data === "" ? "-" : data;
      },
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
    },
    {
      title: "Phone Number",
      dataIndex: "phone_number",
    },
    {
      title: "Organization Address	",
      dataIndex: "organization_address",
    },
    {
      title: "No of Devices	",
      dataIndex: "device",
    },
    {
      title: "No of Users",
      dataIndex: "users",
    },
  ];
  // const onDelete = (user_id) => {
  //   Swal.fire({
  //     title: "Confirm Delete",
  //     text: "Are you sure you want to delete this device?",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#D8EAFF",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Delete",
  //     confirmButtonTextColor: "#0177FB",
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const payload = {
  //         page: param?.page,
  //         page_size: param?.page_size,
  //         search: param?.search?.length ? param?.search : undefined,
  //       };
  //       // dispatch(deleteUserAction(QueryString.stringify(payload), user_id));
  //     }
  //   });
  // };

  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    // dispatch(getUserList(QueryString.stringify(payload)));
  };

  useEffect(() => {
    getRecords();
  }, [param]);

  return (
    <div className="user-wrap px-3 pb-3">
      <div className="dark-table-wrap bg-black">
        <div className="table-head d-flex align-items-center justify-content-between ">
          <div className="t-filter-search">
            <Search
              onChange={(event) =>
                setParam({ ...param, search: event.target.value })
              }
              className="search"
            />
          </div>
          <div className="t-filter-block">
            <button type="button">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 13.375H9C8.83424 13.375 8.67527 13.4408 8.55806 13.5581C8.44085 13.6753 8.375 13.8342 8.375 14C8.375 14.1658 8.44085 14.3247 8.55806 14.4419C8.67527 14.5592 8.83424 14.625 9 14.625H19C19.1658 14.625 19.3247 14.5592 19.4419 14.4419C19.5592 14.3247 19.625 14.1658 19.625 14C19.625 13.8342 19.5592 13.6753 19.4419 13.5581C19.3247 13.4408 19.1658 13.375 19 13.375Z"
                  fill="white"
                />
                <path
                  d="M22.125 9.625H5.875C5.70924 9.625 5.55027 9.69085 5.43306 9.80806C5.31585 9.92527 5.25 10.0842 5.25 10.25C5.25 10.4158 5.31585 10.5747 5.43306 10.6919C5.55027 10.8092 5.70924 10.875 5.875 10.875H22.125C22.2908 10.875 22.4497 10.8092 22.5669 10.6919C22.6842 10.5747 22.75 10.4158 22.75 10.25C22.75 10.0842 22.6842 9.92527 22.5669 9.80806C22.4497 9.69085 22.2908 9.625 22.125 9.625Z"
                  fill="white"
                />
                <path
                  d="M15.875 17.125H12.125C11.9592 17.125 11.8003 17.1908 11.6831 17.3081C11.5658 17.4253 11.5 17.5842 11.5 17.75C11.5 17.9158 11.5658 18.0747 11.6831 18.1919C11.8003 18.3092 11.9592 18.375 12.125 18.375H15.875C16.0408 18.375 16.1997 18.3092 16.3169 18.1919C16.4342 18.0747 16.5 17.9158 16.5 17.75C16.5 17.5842 16.4342 17.4253 16.3169 17.3081C16.1997 17.1908 16.0408 17.125 15.875 17.125Z"
                  fill="white"
                />
              </svg>
            </button>
            <button type="button">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.19194 17.3081C8.07473 17.1908 7.91576 17.125 7.75 17.125C7.58424 17.125 7.42527 17.1908 7.30806 17.3081C7.19085 17.4253 7.125 17.5842 7.125 17.75C7.125 17.9158 7.19085 18.0747 7.30806 18.1919L9.80806 20.6919C9.92527 20.8092 10.0842 20.875 10.25 20.875C10.4158 20.875 10.5747 20.8092 10.6919 20.6919L13.1917 18.1922L13.1919 18.1919C13.3092 18.0747 13.375 17.9158 13.375 17.75C13.375 17.5842 13.3092 17.4253 13.1919 17.3081C13.0747 17.1908 12.9158 17.125 12.75 17.125C12.5842 17.125 12.4253 17.1908 12.3081 17.3081L12.3078 17.3083L10.25 19.3661L8.19194 17.3081Z"
                  fill="white"
                />
                <path
                  d="M9.625 7.75V20.25C9.625 20.5952 9.90482 20.875 10.25 20.875C10.5952 20.875 10.875 20.5952 10.875 20.25V7.75C10.875 7.40482 10.5952 7.125 10.25 7.125C9.90482 7.125 9.625 7.40482 9.625 7.75Z"
                  fill="white"
                />
                <path
                  d="M19.8077 10.6916L19.8081 10.6919C19.9253 10.8092 20.0842 10.875 20.25 10.875C20.4158 10.875 20.5747 10.8092 20.6919 10.6919C20.8092 10.5747 20.875 10.4158 20.875 10.25C20.875 10.0842 20.8092 9.92527 20.6919 9.80806L18.1919 7.30806C18.0747 7.19085 17.9158 7.125 17.75 7.125C17.5842 7.125 17.4253 7.19085 17.3081 7.30806L14.8081 9.80806C14.6908 9.92527 14.625 10.0842 14.625 10.25C14.625 10.26 14.6252 10.27 14.6257 10.28C14.6332 10.4352 14.6982 10.5821 14.8081 10.6919C14.9253 10.8092 15.0842 10.875 15.25 10.875C15.4158 10.875 15.5747 10.8092 15.6919 10.6919L15.6922 10.6917L17.75 8.63388L19.8077 10.6916Z"
                  fill="white"
                />
                <path
                  d="M18.375 20.25V7.75C18.375 7.40482 18.0952 7.125 17.75 7.125C17.4048 7.125 17.125 7.40482 17.125 7.75V20.25C17.125 20.5952 17.4048 20.875 17.75 20.875C18.0952 20.875 18.375 20.5952 18.375 20.25Z"
                  fill="white"
                />
              </svg>
            </button>
            <button type="button">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 8.75C5.69036 8.75 6.25 9.30964 6.25 10C6.25 10.6904 5.69036 11.25 5 11.25C4.30964 11.25 3.75 10.6904 3.75 10C3.75 9.30964 4.30964 8.75 5 8.75Z"
                  fill="white"
                />
                <path
                  d="M10 8.75C10.6904 8.75 11.25 9.30964 11.25 10C11.25 10.6904 10.6904 11.25 10 11.25C9.30964 11.25 8.75 10.6904 8.75 10C8.75 9.30964 9.30964 8.75 10 8.75Z"
                  fill="white"
                />
                <path
                  d="M16.25 10C16.25 9.30964 15.6904 8.75 15 8.75C14.3096 8.75 13.75 9.30964 13.75 10C13.75 10.6904 14.3096 11.25 15 11.25C15.6904 11.25 16.25 10.6904 16.25 10Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="">
          <Table 

          className="custom-table-1"
            dataSource={result}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              current: param.page,
              pageSize: param.page_size,
              total: users?.total_records,
              onChange: (page, page_size) => {
                setParam({ ...param, page: page, page_size: page_size });
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewThreatSharing;
