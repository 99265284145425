import { IconButton, Tooltip } from "@mui/material";
import { Switch } from "antd";
import { BsEye } from "react-icons/bs";

export const organizationMainColumn = (props) => [
  {
    title: "Organization ID	",
    dataIndex: "id",
  },
  {
    title: "Organization Name	",
    dataIndex: "name",
    render: (data) => {
      return data == "" ? "-" : data;
    },
  },
  {
    title: "Email Address",
    dataIndex: "email_address",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
  },
  {
    title: "Organization Address	",
    dataIndex: "address",
    render: (data) => {
      return !Boolean(data) ? "-" : data;
    },
  },
  {
    title: "No of Devices	",
    dataIndex: "device",
  },
  {
    title: "No of Users",
    dataIndex: "users",
  },

  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <Tooltip title="View">
          <IconButton>
            <BsEye
              className="icon me-3"
              onClick={() => props.navigate(`/organization/edit/${record.id}`)}
            />
          </IconButton>
        </Tooltip>
      );
    },
  },
];
export const userCompanyListColumn = (props) => [
  {
    title: "User ID",
    dataIndex: "user_id",
  },
  {
    title: "User Name",
    dataIndex: "last_name",
    render: (data, record) => {
      return `${record.first_name}  ${record.last_name}`;
    },
  },
  {
    title: "Email Address",
    dataIndex: "email_address",
  },
  {
    title: "Phone Number",
    dataIndex: "phone_number",
  },
  {
    title: "Job Title",
    dataIndex: "job_title",
    render: (data) => {
      return data == "" ? "-" : data;
    },
  },
  {
    title: "Account Status",
    dataIndex: "devices",
    render: (is_active) => {
      return (
        <Tooltip title={Boolean(is_active) ? "Active" : "Deactive"}>
          <Switch checked={Boolean(is_active)} size="small" />
        </Tooltip>
      );
    },
  },
  {
    title: "Role",
    dataIndex: "role",
  },

  // {
  //   title: "Action",
  //   dataIndex: "status",
  //   render: (data, record) => {
  //     return (
  //       <div>
  //         <Tooltip title="View">
  //           <IconButton>
  //             <BsEye
  //               className="icon me-3"
  //               onClick={() => props.navigate(`/users/edit/${record?.user_id}`)}
  //             />
  //           </IconButton>
  //         </Tooltip>

  //         <Tooltip title="Delete">
  //           <IconButton
  //             onClick={() => props.onDelete(record?.user_id)}
  //             className=" mx-2"
  //           >
  //             <AiOutlineDelete className="icon" />
  //           </IconButton>
  //         </Tooltip>
  //       </div>
  //     );
  //   },
  // },
];

export const deviceCompanyListColumn = (props) => [
  {
    title: "Device ID",
    dataIndex: "device_id",
  },
  {
    title: "Device Name",
    dataIndex: "device_name",
  },

  {
    title: "Status",
    dataIndex: "status",
    render: (is_active) => {
      return (
        <Switch checked={Boolean(is_active)} size="small" />
   
      );
    },
  },
];

export const endpointDomainOrgnazationcolumns = (props) => [
  {
    title: "Endpoint Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "IP Address",
    dataIndex: "ip_address",
  },
  {
    title: "Mac Address",
    dataIndex: "mac_address",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "Device Type",
    dataIndex: "device_type",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },
  {
    title: "OS",
    dataIndex: "os",
    render: (data) => {
      return Boolean(data) ? data : "-";
    },
  },

  {
    title: "Status",
    dataIndex: "isConnected",
    key: "isConnected",
    render: (data) => {
      return (
          <Switch checked={data} size="small"/>
      );
      // return (
      //   <Tooltip title={'data ? "Connected" : "Disconnected"'}>
      //     <Switch checked={data} size="small"></Switch>{" "}
      //   </Tooltip>
      // );
    },
  },
];
