import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          marginTop: "-2px",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          // Change the size of the radio button
          width: 15,
          height: 15,
          padding: 3,
          marginRight: 5,
          marginLeft: 5,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "45px", // Change the height to your desired value
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "45px", // Change the height here
          "&:before": {
            borderBottom: "none", // Remove underline when not focused
          },
          "&:after": {
            borderBottom: "none", // Remove underline when focused
          },
          // fontSize:12
        },
        icon: {
          color: "gray", // Change the icon color here
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontFamily: "Inter-Regular",
        },
      },
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        "& legend": {
          color: "black", // Set the text color to black
        },
      },
    },
  },
  palette: {
    mode: "dark",
  },
});

export const blueTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#FFFFFF",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          marginTop: "-2px",
        },
      },
    },
    
    MuiRadio: {
      styleOverrides: {
        root: {
          // Change the size of the radio button
          width: 15,
          height: 15,
          padding: 3,
          marginRight: 5,
          marginLeft: 5,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#2d2f42", // Change the height to your desired value
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "45px", // Change the height to your desired value
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "45px", // Change the height here
          "&:before": {
            borderBottom: "none", // Remove underline when not focused
          },
          "&:after": {
            borderBottom: "none", // Remove underline when focused
          },
          // fontSize:12
        },
        icon: {
          color: "gray", // Change the icon color here
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontFamily: "Inter-Regular",
        },
      },
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        "& legend": {
          color: "black", // Set the text color to black
        },
      },
    },
  },
  palette: {
    mode: "dark",
  },
});

export const whiteTheme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#1F1F1F66",
          fontFamily: "Inter-Regular",
          fontSize: 14,
          marginTop: "-2px",
        },
      },
    },
    
    MuiRadio: {
      styleOverrides: {
        root: {
          // Change the size of the radio button
          width: 15,
          height: 15,
          padding: 3,
          marginRight: 5,
          marginLeft: 5,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "45px", // Change the height to your desired value
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "45px", // Change the height here
          "&:before": {
            borderBottom: "none", // Remove underline when not focused
          },
          "&:after": {
            borderBottom: "none", // Remove underline when focused
          },
          // fontSize:12
        },
        icon: {
          color: "gray", // Change the icon color here
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontFamily: "Inter-Regular",
        },
      },
    },
  },
  palette: {
    mode: "light",
  },
});
