import React, { useState } from "react";
import { TextField } from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Controller } from "react-hook-form";

const CustomInputPassword = (props) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <Controller
        {...props}
        defaultValue={""}
        render={({ field }) => (
          <TextField
            type={show ? "text" : "password"}
            variant="outlined"
            className="text-white"
            size="medium"
            // autoComplete={"false"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {!show ? (
                    <IconButton
                      onClick={() => {
                        setShow(true);
                      }}
                    >
                      <MdVisibility color="white" />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={() => {
                        setShow(false);
                      }}
                    >
                      <MdVisibilityOff color="white" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
            {...field}
            {...props}
            error={props?.error && props?.error}
            helperText={props?.error?.message && props?.error?.message}
          />
        )}
      />
    </div>
  );
};

export default CustomInputPassword;
