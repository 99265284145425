import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Modal } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import CustomInput from "../../common/CustomInput";
import CustomInputPassword from "../../common/CustomInputPassword";

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),

  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});
const GenerateApiKeyModal = ({ isModalOpen, setIsModalOpen }) => {
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="info-black-card mr-b20 mt-2">
      <Modal
        title="Generate API Key"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Create Access Key
          </Button>,
          <Button className="submit-Button" onClick={handleSubmit(onSubmit)}>
            Send to Email
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-8 mt-3">
              <CustomInput
                label={"Organization Name"}
                name="organization_mame"
                varient="outline"
                control={control}
                error={errors?.organization_mame}
                fullWidth
              />{" "}
            </div>
            <div className="col-8 mt-3">
              <CustomInput
                label={"Email"}
                name="email"
                varient="outline"
                control={control}
                error={errors?.email}
                fullWidth
              />{" "}
            </div>
            <div className="col-8 mt-3">
              <CustomInputPassword
                label={"Access Key"}
                name="access_key"
                varient="outline"
                control={control}
                error={errors?.access_key}
                fullWidth
              />{" "}
            </div>
            <div className="col-8 mt-3">
              <CustomInputPassword
                label={"Secret"}
                name="secreat"
                varient="outline"
                control={control}
                error={errors?.secreat}
                fullWidth
              />
            </div>
          </div>
          <div className="mt-2">
            <span className="note fw-800">Note : </span>
            <span className="note">
              Please save the secret or send to an Email for future reference,
              once the window is closed you won't be able to get the same secret
              again.
            </span>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default GenerateApiKeyModal;
