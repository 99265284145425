import { Table } from "antd";
import Search from "antd/es/transfer/search";
import QueryString from "qs";
import React, { useState } from "react";
import ApiHelper from "../../../common/Hooks/ApiHelper";
import { organizationMainColumn } from "../../../common/Tables/organazationTableColumns";
import { getOrganaztionCompanyListAction } from "../../../redux/action/action";
import { expandedIcon } from "../../../utils/expanded";
import NestUserTable from "./NestedTable/NestUserTable";
const OrganizationTable = () => {
  const [rowExpand, setRowExpand] = useState([1]);
  const getRecords = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
    };
    dispatch(getOrganaztionCompanyListAction(QueryString.stringify(payload)));
  };
  const { records, dispatch, navigate, param, setParam } = ApiHelper({
    getRecords, //this function is calling the api
    getData: (state) => state.cylenium.organazation, //this is getting data from redux  store  and return into records
  });
  const onExpand = (expanded, record) => {
    // Step 5: Handle row expand/collapse changes
    setRowExpand(expanded ? [record.id] : []);
    console.log(expanded, record);
  };
  return (
    <div className="user-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Organization</h3>
        </div>
      </div>

      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <div className="t-filter-search">
            <Search
              onChange={(event) =>
                setParam({ ...param, search: event.target.value })
              }
              className="search"
            />
          </div>
          <div></div>
          {/* <FilterBlock /> */}
        </div>
        <div className="mt-3">
          <Table
            dataSource={records}
            className="custom-table-1"
            columns={organizationMainColumn({ navigate })}
            expandable={{
              expandedRowRender: () => <NestUserTable />,
              expandIcon: expandedIcon,
              expandedRowKeys: rowExpand,
              onExpand: onExpand,
            }}
            scroll={{ x: 600 }}
            pagination={false}
            rowKey={"id"}
          />
        </div>
      </div>
    </div>
  );
};

export default OrganizationTable;
