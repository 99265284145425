import React from "react";
import CustomRadioGroup from "../../../../common/UI/CustomRadioGroup";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Modal } from "antd";
import { getIotDevicesAction } from "../../../../redux/action/action";
import { useEffect } from "react";
import { useState } from "react";
const limit_access_options = [
  { value: "0", label: "Block websites and embedded keywords" },
  { value: "1", label: "Block all internet access" },
];
const LimitAccessDevice = ({
  control,
  device_id,
  selectedIotDevices,
  setSelectedIotDevices,
}) => {
  const records = useSelector((state) => state.cylenium.accessRules);
  const iotDevices = useSelector((state) => state.cylenium.iotDevices);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedIotDeviceList, setSelectedIotDeviceList] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setSelectedIotDevices(selectedIotDeviceList);
    setIsModalOpen(false);
  };

  const dispatch = useDispatch();

  const getDeviceInfoById = () => {
    const payload = {
      device_id: device_id ? device_id : undefined,
    };
    dispatch(getIotDevicesAction(payload));
  };

  const handleCheckbox = (checked, record) => {
    if (checked)
      setSelectedIotDeviceList([...selectedIotDeviceList, { ...record }]);
    else {
      setSelectedIotDeviceList((prevState) =>
        prevState.filter((data) => data.name != record.name)
      );
    }
  };

  useEffect(() => {
    getDeviceInfoById();
  }, [device_id]);
  return (
    <div className="info-gray-card mr-b20">
      <h4 className="font-size12 text-white Inter-Regular mr-b15">
        Select the Device{" "}
      </h4>
      <div className="row row-cols-12">
        {selectedIotDevices?.length === 0 &&
          records[0]?.devices?.map((data) => (
            <div className="col-6">
              <div className="static-info">
                <label className="font-size12 text-white opacity04 Inter-Regular mr-b8">
                  {data.name}
                </label>
              </div>
            </div>
          ))}
        {selectedIotDevices?.map((data) => (
          <div className="col-6">
            <div className="static-info">
              <label className="font-size12 text-white opacity04 Inter-Regular mr-b8">
                {data.name}
              </label>
            </div>
          </div>
        ))}
        <div className="mt-3">
          <a className="fs-12 fw-500" onClick={showModal}>
            Add/Remove device
          </a>
        </div>
      </div>
      <Modal
        title="IoT Devices"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={() => handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>,
          <Button className="submit-Button" onClick={handleCancel}>
            Apply
          </Button>,
        ]}
      >
        {iotDevices &&
          iotDevices?.devices?.map((record) => (
            <div key={record.id} className="mt-3">
              <Checkbox
                onClick={(event) =>
                  handleCheckbox(event.target.checked, record)
                }
              >
                <div className="item-active">{record.name}</div>
              </Checkbox>
            </div>
          ))}
      </Modal>
    </div>
  );
};

export default LimitAccessDevice;
