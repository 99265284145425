const messages = {
  sourceIp: `The IP address or range of addresses of the packet's source. It can be a single IP address, a CIDR block, or a variable defined in the Snort configuration file.`,
  sourcePort: `The port number or range of port numbers of the packet's source. any can be used to specify any port.`,
  destinationIp: `The IP address or range of addresses of the packet's destination. Like the source IP, it can be a single IP address, a CIDR block, or a variable.`,
  destinationPort: `The port number or range of port numbers of the packet's destination. any can be used to specify any port.`,
  sid: (
    <div>
      <div>
        The sid keyword uniquely identifies a given Snort rule. This rule option
        takes in a single argument that is a numeric value that must be unique
        to the rule.
      </div>
    </div>
  ),
  rev: `The rev keyword uniquely identifies the revision number of a given Snort rule. This option should be used along with the sid keyword and should be incremented by one each time a change is made to a rule.`,
  ruleMessage: ``,
  classType:
    "The classtype assigns a classification to the rule to indicate the type of attack associated with an event. Snort provides a list of default classifications that rule-writers can use to better organize rule event data.",
  priority:
    "The priority option assigns a severity level to a given rule to enable appropriate event prioritizing. Although the builtin classifications set with classtype come with their own priority levels, rule writers can override those by using the priority option.",
  gid: `The gid keyword stands for "generator id" and it identifies the specific part of Snort that generated a given event. The different parts include, but are not limited to, standard text rules, shared object rules, and builtin rules, and each have part their own generator ID. Standard text rules, for example, are identified with as GID 1, shared object rules are identified with GID 3, and builtin rules are identified with gids over 100.`,
};

export default messages;
