import { Checkbox } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import GenerateApiKeyModal from "./GenerateApiKeyModal";
import ListTable from "./ListTable";

const ListThreatSharing = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="dd-wrap">
      <div className="page-head d-flex align-items-center justify-content-between">
        <div className="page-title">
          <h3 className="font-size18 text-white Inter-Bold">Threat Sharing</h3>
        </div>
        <div className="mt-2">
          {" "}
          <Checkbox />
          <span className="heading-label mx-2 mt-2">
            Allow Other Organizations to Import your threat data
          </span>
        </div>
      </div>
      <div className="gray-box pb-3 ">
        <div className="d-flex justify-content-between mt-3">
          <div className="d-flex">
            <div
              className="tab-label  m-2"
              onClick={() => navigate("/threat_sharing")}
            >
              View
            </div>
            <div
              className="tab-label m-2 tab-active"
              // onClick={() => navigate("/threat_sharing/list")}
            >
              List
            </div>
          </div>
          <div className="mt-3">
            <button
              type="button"
              className="blue-btn h32 pd-l10 pd-r10 font-size14 Inter-Medium mx-2"
              onClick={() => setIsModalOpen(true)}
            >
              Export Data
            </button>
          </div>
        </div>
        <ListTable />
      </div>
      <GenerateApiKeyModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </div>
  );
};
export default ListThreatSharing;
