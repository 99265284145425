import React from "react";
import ReactApexChart from "react-apexcharts";
import useElementWidth from "../../../common/Hooks/useElementWidth";
import { useState } from "react";
import { useEffect } from "react";
import { Empty } from "antd";
import { chartColor } from "../../../utils/colors";
import { useSelector } from "react-redux";

const CustomPieChart = ({ graphData }) => {
  const themes = useSelector((state) => state.cylenium.themes);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "pie",
    },
    legend: {
      position: "bottom", // Set the legend position to 'bottom'
    },
    labels: ["Low", "Medium", "High"],
    colors: chartColor,
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
  });
  const [divWidth, divRef] = useElementWidth();

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      labels: graphData?.labels,
    });
  }, [graphData]);
  return (
    <div style={{ height: "300px" }} className="row" ref={divRef}>
      {graphData?.series?.length ? (
        <div className="d-flex justify-content-center">
          <ReactApexChart
            options={chartOptions}
            series={graphData?.series}
            type="donut"
            width={divWidth}
            height={"310px"}
          />
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-item-center"
          style={{ height: 280 }}
        >
          <div
            className="d-flex  align-item-center"
            style={{ height: 280, alignItems: "center" }}
          >
            <Empty description={"No Data Available"} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomPieChart;
