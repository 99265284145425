import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { AiOutlineDownload, AiOutlineEye } from "react-icons/ai";

export const invoiceHistoryColumns = (props) => [
  {
    title: "Invoice ID",
    dataIndex: "id",
  },
  {
    title: "Plan Name",
    dataIndex: "name",
  },

  {
    title: "Invoice Date",
    dataIndex: "invoiceDate",
    render: (invoiceDate) => moment(invoiceDate).format("MMM DD YYYY"),
  },
  {
    title: "Date Paid",
    dataIndex: "invoiceDate",
    render: (invoiceDate) => moment(invoiceDate).format("MMM DD YYYY"),
  },
  {
    title: "Plan Start Date",
    dataIndex: "startDate",
    render: (invoiceDate) => moment(invoiceDate).format("MMM DD YYYY"),
  },
  {
    title: "Plan End Date",
    dataIndex: "endDate",
    render: (invoiceDate) => moment(invoiceDate).format("MMM DD YYYY"),
  },

  {
    title: "Price",
    dataIndex: "price",
  },
  {
    title: "Payment Gateway",
    dataIndex: "paymentGateway",
  },
  {
    title: "Transaction Hash",
    dataIndex: "transactionHash",
  },

  {
    title: "Action",
    dataIndex: "status",
    render: (data, record) => {
      return (
        <div>
          <Tooltip title="View">
            <IconButton
              onClick={() =>
                props.navigate(
                  `/subscription/invoice_history/details/${record.id}`
                )
              }
            >
              <AiOutlineEye className="icon" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Download">
            <IconButton
              onClick={() =>
                props.navigate(`/subscription/invoice/${record.id}`)
              }
            >
              <AiOutlineDownload className="icon" />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  },
];
