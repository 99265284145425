import Swal from "sweetalert2";

export const sessionExpired = (response) => {
  console.log("status", response.status);
  if (response?.status == "401") {
    Swal.fire({
      title: "Session expired",
      text: "Please login again to this portal?",
      icon: "warning",
      // showCancelButton: true,
      confirmButtonColor: "#D8EAFF",
      cancelButtonColor: "#d33",
      confirmButtonText: "Logout",
      confirmButtonTextColor: "#0177FB",
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        window.location.replace("/");
      }
    });
  }
};
