import { Autocomplete, Checkbox, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function CustomMultiSelectUsers({
  options,
  label,
  name,
  multiple,
  watch,
  disabled,
  setValue,
  ...rest
}) {
  const [selectedValue, setSelectedValue] = useState([]);

  const onChange = (obj) => {
    setValue(name, multiple ? obj.map(({ user_id }) => user_id) : obj.user_id);
  };
  useEffect(() => {
    const values = watch(name);
    if (values) {
      if (multiple) {
        const tempArray = options?.filter((obj) =>
          values?.includes(obj.user_id)
        );
        setSelectedValue(tempArray);
      }
    }
  }, [watch(name), options]);
  return (
    <Autocomplete
      sx={{ m: 0, width: "100%" }}
      limitTags={1}
      multiple={multiple ? multiple : false}
      disabled={disabled ? disabled : false}
      options={options}
      defaultValue={selectedValue[0]}
      // value={multiple ? selectedValue : {}}
      getOptionLabel={(option) => option.first_name}
      onChange={(event, newValue) => onChange(newValue)}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label={label ? label : ""} />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.user_id}
          value={option.user_id}
          sx={{ justifyContent: "space-between" }}
        >
          <Checkbox checked={selected} />
          {option.first_name}
        </MenuItem>
      )}
    />
  );
}
