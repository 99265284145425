import { IconButton } from "@mui/material";
import React from "react";
import { RiDeleteBinLine } from "react-icons/ri";

const AddKeywords = ({ keywords, setKeywords }) => {
  const addkeywords = () => {
    setKeywords((prevState) => [
      ...prevState,
      {
        id: keywords?.length ? keywords[keywords.length - 1].id + 1 : 1,
        value: "",
      },
    ]);
  };
  const removeKeyword = (id) => {
    setKeywords((prevState) => prevState.filter((record) => record.id !== id));
  };

  const handleInputChange = (event, id) => {
    console.log("id", event.target.value);
    setKeywords((prevState) => {
      const newArray = prevState.map((item) => {
        if (item.id === id) return { ...item, value: event.target.value };
        else {
          return item; // Return the original item for non-matching IDs
        }
      });
      return newArray;
    });
  };
  return (
    <div className="info-gray-card">
      <h4 className="font-size12 text-white Inter-Regular mr-b20">
        Add keyword or website to block the access
      </h4>
      <div className="row">
        {keywords &&
          keywords.map((record, index) => (
            <div className="col-sm-4 mt-2" key={record.id}>
              <div className="keyword-input-row d-flex align-items-center">
                <div className="form-floating cus-floating-inputs flex-grow-1">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Keyword"
                    // value={record.value}
                    // id={record.id}
                    value={record?.value}
                    onChange={(event) => handleInputChange(event, record.id)}
                  />
                  <label htmlFor="floatingInput">Keyword {record.id}</label>
                </div>
                <IconButton onClick={() => removeKeyword(record.id)}>
                  <RiDeleteBinLine style={{ fontSize: 24, color: "white" }} />
                </IconButton>
              </div>
            </div>
          ))}

        <div className="col-sm-12 mr-t20">
          <button
            type="button"
            className="trans-btn color-blue font-size14 Inter-Medium"
            onClick={addkeywords}
          >
            Add more +
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddKeywords;
