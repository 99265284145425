import Search from "antd/es/transfer/search";
import React from "react";

export const CustomSearch = ({ param, setParam }) => {
  return (
    <div className="t-filter-search">
      <Search
        onChange={(event) => setParam({ ...param, search: event.target.value })}
        placeholder="Search"
        value={param.search ? param.search : null}
      />
    </div>
  );
};
