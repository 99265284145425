import { Tooltip } from "@mui/material";
import React from "react";
import { AiOutlineEnvironment, AiOutlineMobile } from "react-icons/ai";
import { BsCameraReels } from "react-icons/bs";
import {
  GiDeliveryDrone,
  GiMonoWheelRobot,
  GiVendingMachine,
} from "react-icons/gi";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { IoSpeedometerSharp } from "react-icons/io5";
import { LuNetwork } from "react-icons/lu";
import {
  MdElectricCar,
  MdLaptopChromebook,
  MdOutlineDevicesOther,
  MdOutlineQuestionMark,
  MdOutlineSecurity,
} from "react-icons/md";
import { RiBattery2ChargeLine } from "react-icons/ri";
import { TbPhotoSensor3 } from "react-icons/tb";
import { Link } from "react-router-dom";

const DeviceTypeCard = ({ record }) => {
  const tempIcons = [
    {
      deviceType: "Sensors",
      component: <TbPhotoSensor3 className="card-icon" deviceType="Sensors" />,
    },
    {
      deviceType: "Computers",
      component: <MdLaptopChromebook  className="card-icon" deviceType="Sensors" />,
    },
    {
      deviceType: "Meters",
      component: <IoSpeedometerSharp className="card-icon" />,
    },
    {
      deviceType: "Controllers",
      component: <AiOutlineMobile className="card-icon" />,
    },
    {
      deviceType: "Communication Devices",
      component: <LuNetwork className="card-icon" />,
    },

    {
      deviceType: "Actuators",
      component: <GiVendingMachine className="card-icon" />,
    },
    {
      deviceType: "Cameras",
      component: <BsCameraReels className="card-icon" />,
    },
    {
      deviceType: "Mobile Devices",
      component: <HiOutlineOfficeBuilding className="card-icon" />,
    },
    {
      deviceType: "Drones and UAVs",
      component: <GiDeliveryDrone className="card-icon" />,
    },
    {
      deviceType: "Robotics",
      component: <GiMonoWheelRobot className="card-icon" />,
    },
    {
      deviceType: "Cars",
      component: <MdElectricCar className="card-icon" />,
    },
    {
      deviceType: "Security Devices",
      component: <MdOutlineSecurity className="card-icon" />,
    },
    {
      deviceType: "Environmental Devices",
      component: <AiOutlineEnvironment className="card-icon" />,
    },
    {
      deviceType: "Others",
      component: <MdOutlineDevicesOther className="card-icon" />,
    },

    {
      deviceType: "Ev Chargers",
      component: <RiBattery2ChargeLine className="card-icon" />,
    },
  ];

  return (
    <div className="row">
      <div className="card-text-white d-flex">
        <h5 className="pb-0 mx-2">
          {" "}
          {record?.length ? record?.length : 0} Device Types |
        </h5>
        <a className="me-2">
          {record.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.deviceTypeCount;
          }, 0)}{" "}
          Device(s)
        </a>
      </div>
      {record?.map((element, index) => (
        <div className="resposive-card  mb-2" key={element?.id}>
          <div className="dashboard-graph d-flex" style={{ height: 70 }}>
            <div className="">
              {" "}
              {tempIcons.filter(
                (record) => record?.deviceType == element?.deviceType
              )?.length ? (
                tempIcons
                  .filter((record) => record?.deviceType == element?.deviceType)
                  ?.at(0)?.component
              ) : (
                <MdOutlineQuestionMark className="card-icon" />
              )}
            </div>
            <div className="card-text-white ">
              <Tooltip title={element?.deviceType}>
                <h5 className="overflow-text1">{element?.deviceType}</h5>
              </Tooltip>
              <Link
                to={
                  element?.deviceTypeCount
                    ? `/dashboard/details/${element.deviceType}`
                    : null
                }
                className={element?.deviceTypeCount ? "" : "isDisabled"}
              >
                {element?.deviceTypeCount
                  ? element?.deviceTypeCount + " Device"
                  : element?.deviceTypeCount + " Devices"}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DeviceTypeCard;
