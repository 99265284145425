import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Modal } from "antd";
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import CustomDropdown from "../../common/CustomDropdown";

const validationSchema = Yup.object({
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be less than 20 characters")
    .required("Password is required"),

  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Password is required"),
});
const options = [
  { value: "1", label: "Organizations 1" },
  { value: "2", label: "Organizations 2" },
  { value: "3", label: "Organizations 3" },
];
const OrganizationsModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });
  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <div className="info-black-card mr-b20 mt-2">
      <Modal
        title="Organizations"
        open={isModalOpen}
        onOk={handleOk}
        okText="Apply"
        cancelText="Cancel"
        onCancel={handleCancel}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={handleCancel}
          >
            Cancel{" "}
          </Button>,
          <Button className="submit-Button" onClick={handleSubmit(onSubmit)}>
            Request Access & Import{" "}
          </Button>,
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-8 mt-3">
              <CustomDropdown
                label={"Organization"}
                name="organization_mame"
                varient="outline"
                control={control}
                error={errors?.organization_mame}
                options={options}
                fullWidth
              />{" "}
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default OrganizationsModal;
