import { IconButton, Tooltip } from "@mui/material";
import { Image } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef, useState } from "react";
import { BiSend } from "react-icons/bi";
import { FcDocument } from "react-icons/fc";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { MdClear, MdOutlineAttachFile } from "react-icons/md";
import { useReactMediaRecorder } from "react-media-recorder";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { newChatApi, sendMessageApi } from "../../../services/api";
import VoiceToText from "./VoiceToText";

const ChatBox = ({
  chatHistory,
  selectedId,
  setSelectedId,
  setLoadingText,
  scrollToBottom,
  idx,
  setEdit,
  preText,
  responseURL,
  fileContent,
  question,
  setQuestion,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [text, setText] = useState("");
  const [files, setFiles] = useState("");
  const [filesUrl, setFilesUrl] = useState("");

  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ audio: true });

  useEffect(() => {
    if (status === "recording" && recordingTime < 10) {
      const id = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
      setIntervalId(id);
    } else if (recordingTime >= 10) {
      stopRecording();
    }

    return () => clearInterval(intervalId);
  }, [status, recordingTime]);

  const removeClip = () => {
    clearBlobUrl();
  };
  const navigate = useNavigate();
  const selectedChat = (id) => {
    navigate(`/chat?id=${id}`);
    setSelectedId(id); //changing state
    sendMesssage(id); //calling function
  };
  const newChat = async () => {
    newChatApi()
      .then((res) => selectedChat(res.data.id))
      .catch((err) => toast.error(err.message));
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };
  const sendMesssage = async (chatId) => {
    let tempFile = undefined;
    if (selectedId > 1 || chatId > 1) {
      if (files) {
        tempFile = await convertFileToBase64(files);
      }
      if (true) {
        setLoadingText(text);
        scrollToBottom();
        sendMessageApi({
          messageText: text ? text : question,
          chatId: chatId > 1 ? chatId : selectedId,
          id: idx ? idx : undefined,
          file: tempFile ? tempFile : filesUrl,
        })
          .then(() => {})
          .catch((err) => toast.error(err.message))
          .finally(() => {
            chatHistory(chatId > 1 ? chatId : selectedId);
            setText("");
            setLoadingText("");
            tempFile = "";
            setFiles(null);
            setEdit(false);
            setQuestion(null);
            if (idx > 1) {
              setEdit(null);
            }
          });
      }
    } else newChat();
  };

  const fileInputRef = useRef(null);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const validateFile = (file) => {
    const allowedExtensions = /(\.pdf|\.doc|\.docx|\.txt)$/i;
    const maxSize = 4 * 1024 * 1024; // 4 MB

    if (!allowedExtensions.exec(file.name)) {
      return "Invalid file type. Only PDF, DOC, DOCX, and TXT files are allowed.";
    }

    if (file.size > maxSize) {
      return "File size exceeds the 4 MB limit.";
    }

    return "";
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const errorMessage = validateFile(file);

      if (errorMessage) {
        setFiles(null);
        toast.error(errorMessage);
      } else {
        setFiles(file);
      }
    }
  };

  const onRemove = () => {
    setFiles(false);
  };

  useEffect(() => {
    if (preText?.length) {
      setText(preText);
    }
    if (responseURL) setFilesUrl(responseURL);
  }, [preText]);
  useEffect(() => {
    if (question) {
      sendMesssage();
    }
  }, [question]);
  return (
    <>
      {files?.name ? (
        <div className="upload-file d-flex">
          <div style={{ width: 100, height: 120 }}>
            {files?.name?.split(".").reverse().at(0) == "jpg" ||
            files?.name?.split(".").reverse().at(0) == "png" ? (
              <div>
                <Image src={URL.createObjectURL(files)} />
              </div>
            ) : (
              <FcDocument fontSize={62} />
            )}
          </div>
          <div className="text-white ">
            <div className="fs-18 mt-1 overflow-text "> {files?.name} </div>{" "}
            <div>
              {" "}
              {files?.name?.split(".").reverse().at(0)
                ? files?.name?.split(".").reverse().at(0)
                : "Unknown"}{" "}
            </div>{" "}
          </div>
          <div className="close-btn">
            <Tooltip title="Remove">
              <IconButton style={{ height: 35 }} onClick={onRemove}>
                {" "}
                <IoMdCloseCircleOutline />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="text-white">
        {filesUrl && fileContent && !files ? (
          <div className="upload-file d-flex ">
            <div
              style={{ width: 100, height: 120 }}
              onClick={() => window.open(responseURL)}
            >
              <FcDocument fontSize={62} />
            </div>
            <div
              className="text-white "
              onClick={() => window.open(responseURL)}
            >
              <div className="fs-18 mt-1 overflow-text ">
                {" "}
                {fileContent?.originalFileName}{" "}
              </div>{" "}
              <div> {fileContent?.mimetype} </div>{" "}
            </div>
            <div className="close-btn">
              <Tooltip title="Remove">
                <IconButton
                  style={{ height: 35 }}
                  onClick={() => setFilesUrl(null)}
                >
                  {" "}
                  <IoMdCloseCircleOutline />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ) : null}
      </div>
      <div className={`chatbox ${isFocused ? "focused" : ""}`}>
        <div className="d-flex">
          {/* <HandleSpeak /> */}
          <div className="w-96">
            {mediaBlobUrl ? (
              <div className="d-flex ">
                <audio src={mediaBlobUrl} controls />
                <IconButton className="gray-100  m-3">
                  <MdClear
                    fontSize={20}
                    style={{ marginTop: 10 }}
                    onClick={removeClip}
                  />
                </IconButton>
              </div>
            ) : (
              <TextArea
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                autoSize={{ minRows: 1, maxRows: 6 }}
                maxLength={180}
                placeholder="Chat with AI "
                value={text}
                onChange={(event) => setText(event.target.value)}
                onPressEnter={() => sendMesssage()}
              />
            )}
          </div>
          <div className="p-1">
            <Tooltip title="Send">
              <IconButton className="gray-100" onClick={sendMesssage}>
                <BiSend />
              </IconButton>
            </Tooltip>
          </div>
          {/* <div className="mb-1">
            {status === "recording" ? (
              <IconButton className="gray-100 mx-1 pt-1">
                <CiMicrophoneOff className="mt-2" onClick={stop} />
              </IconButton>
            ) : (
              <IconButton className="gray-100 mx-1 pt-1">
                <FaMicrophone className="mt-2" onClick={start} />
              </IconButton>
            )}
          </div> */}
          <div className="mb-1">
            <Tooltip title="Voice">
              <VoiceToText text={text} setText={setText}  className="chat-icon"
              />
            </Tooltip>
          </div>
          <div className="mt-1 mb-1">
            <IconButton className="gray-100" onClick={handleIconClick}>
              <MdOutlineAttachFile ref={fileInputRef} fontSize={24} />
            </IconButton>
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
          {idx ? (
            <div className="p-1 mt-1">
              <IconButton onClick={() => setEdit(null)} className="gray-100">
                <IoMdCloseCircleOutline />{" "}
              </IconButton>{" "}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ChatBox;
