/* eslint-disable jsx-a11y/anchor-is-valid */
import QueryString from "qs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alertsTable } from "../../../common/Tables/threatPolices";
import { CustomSearch } from "../../../common/UI/CustomSearch";
import CustomTable from "../../../common/UI/CustomTable";
import { getThreatAlertListActions } from "../../../redux/action/action";
import { useLocation } from "react-router-dom";

const Alerts = () => {
  const records = useSelector((state) => state.cylenium.threatAlerts);
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);

  const [param, setParam] = useState({
    search: "",
    page: 1,
    page_size: 10,
    filter: [],
    status: undefined,
    selectedDevice: "",
  });

  const getDeviceInfoById = () => {
    const payload = {
      page: param?.page,
      page_size: param?.page_size,
      search: param?.search?.length ? param?.search : undefined,
      priority: param?.filters?.Priority?.length
        ? param?.filters?.Priority[0]
        : undefined,
      device_id: queryParams.get("device_id")
        ? queryParams.get("device_id")
        : undefined,
    };
    dispatch(getThreatAlertListActions(QueryString.stringify(payload)));
  };
  useEffect(() => {
    getDeviceInfoById();
  }, [queryParams.get("device_id"), param]);
  return (
    <div className="tab-pane fade show active policies-block">
      <div className="dark-table-wrap">
        <div className="table-head d-flex align-items-center justify-content-between mr-t20">
          <CustomSearch param={param} setParam={setParam} />
          {/* <FilterBlock /> */}
        </div>
        <div className="mt-3">
          <div className="">
            <CustomTable
              className="custom-table-1"
              records={records}
              columns={alertsTable({})} //we  are passing this to column to antd column which in separate file
              param={param}
              setParam={setParam}
              style={{ width: 1400 }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alerts;
