import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  confirmDeleteAction,
  confirmDeleteModalAction,
} from "../../redux/action/action";

function ConfirmDeleteModal() {
  const info = useSelector((state) => state.cylenium.deleteModal);
  const dispatch = useDispatch();
  const setDeleteData = (open = false, message = "") => {
    dispatch(confirmDeleteModalAction({ open: open, message: message }));
  };
  const confirmDelete = () => {
    dispatch(confirmDeleteAction(info.module, info.value));
    setDeleteData();
  };
  return (
    <div>
      {" "}
      <Modal
        title="Confirm Delete"
        open={info.open}
        onCancel={() => setDeleteData()}
        footer={[
          <Button
            key="cancel-button"
            className="custom-cancel-button"
            onClick={() => setDeleteData()}
          >
            Cancel
          </Button>,
          <Button
            className="custom-confirm-button"
            onClick={() => confirmDelete()}
          >
            Delete
          </Button>,
        ]}
      >
        <p className="delete-message  my-3 mb-1">{info?.message}</p>
        <p className="delete-message text-red">
          Note: You can always add it back later from the dashboard settings?{" "}
        </p>
      </Modal>
    </div>
  );
}

export default ConfirmDeleteModal;
