export const downloadCSV = (data,name) => {
  let csvContent = "data:text/csv;charset=utf-8,";
  // Adding header row (customize as per your data structure)
  csvContent += "Date,Time,Device,Event Count\n";

  // Loop through each series and each data point in the series
  data.forEach((series) => {
    series.data.forEach((point) => {
      let date = new Date(point[0]).toLocaleString(); // Converting timestamp to readable date
      let value = point[1];
      let details =
        point[2]
          ?.map((detail) => `${detail.name}:${detail.value}`)
          .join(" | ") || "No details";
      csvContent += `${date},${series.name},${value},${details}\n`;
    });
  });

  // Encoding URI and triggering download
  var encodedUri = encodeURI(csvContent);
  var link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${name}.csv`);
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file named "security_events_data.csv".
  document.body.removeChild(link); // Clean up
};
